// @ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
import { StyledProps } from "@material-ui/core/styles";
import { RouteProps } from "react-router";
// import {
//     DirectionsService,
//   } from "@react-google-maps/api";
// Customizable Area End

export const configJSON = require("./config");

export type Props = StyledProps & RouteProps & {
    id: string;
    openDialog: any;
    showNotification: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    id: string;
    authToken: string;
    lat: number | null;
    lng: number | null;
    showMapFilter: boolean;
    selectedMerchant: null;
    map: any;
    merchantLocation: any;
    loading: boolean;
    dataSource: [];
    attachmentIds: Array<string>;
    radiusKm: string;
    radius: String,
    showPayButton: boolean;
    allShopsModal: boolean;
    directions: any;
    markerLabelLat: any;
    markerLabelLng: any;
    markerLabelEmail: any;
    markerLabelName: any;
    paperType: any;
    pageType: any;
    printColor: any;
    modalOpen: boolean;
    basePrices: any;
    baseMerchantPrices: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class NearByShopsController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiGetNearByServiceProviderCallId: String = "";
    apiPlaceOrderCallId: String = "";
    apiMerchantDetailsApiCallID: String = "";
    apiGetBasePriceApiCallID: String = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            showMapFilter: false,
            selectedMerchant: null,
            id: '',
            authToken: '',
            lat: null,
            lng: null,
            loading: false,
            dataSource: [],
            attachmentIds: [],
            radiusKm: '5',
            radius: '5',
            map: null,
            merchantLocation: null,
            showPayButton: true,
            allShopsModal: false,
            directions: null,
            markerLabelLat: '',
            markerLabelLng: '',
            markerLabelEmail: '',
            markerLabelName: '',
            paperType: [],
            pageType: [],
            printColor: [],
            modalOpen: false,
            basePrices: [],
            baseMerchantPrices: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiGetNearByServiceProviderCallId) {
                this.setState({ loading: false })
                if (responseJson && responseJson.data) {
                    runEngine.debugLog("apiGetNearByServiceProviderCallId:---->RESPONSE", responseJson.data.length);
                    //TODO: Need to uncomment this to go with the login flow
                    // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                    this.props.hideLoader();
                    this.setState({ dataSource: responseJson.data });
                } else if (responseJson && responseJson.message) {
                    //TODO: Need to uncomment this to go with the login flow
                    // runEngine.unSubscribeFromMessages(this, this.subScribedMessages); 
                    this.setState({ dataSource: [] });
                    this.props.hideLoader();
                    this.props.showNotification(responseJson.message, 'error');
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
                    const accountBlockValidate = responseJson.errors[0].message;
                    if (validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration") {
                        // this.props.showNotification("Account not found", "error");
                        setTimeout(() => {
                            this.props.history.push('/PhoneLoginWeb');
                        }, 1000);
                    }
                }
            } else if (apiRequestCallId === this.apiPlaceOrderCallId) {
                this.setState({ loading: false })
                if (responseJson && responseJson.data && responseJson.data.id) {
                    let data: any = {}
                    data.orderId = responseJson?.data?.id;
                    data.goBack = false;
                    await StorageProvider.remove('orderId');
                    await StorageProvider.remove('orderIdDetails')
                    await StorageProvider.set('orderIdDetails', JSON.stringify(responseJson.data))
                    await StorageProvider.set('orderId', JSON.stringify(data));
                    this.props.hideLoader();
                    this.props.showNotification(responseJson?.meta?.message, 'success');
                    this.props.history.push('/OrderDetailsQR');
                } else if (responseJson && responseJson.message) {
                    //TODO: Need to uncomment this to go with the login flow
                    // runEngine.unSubscribeFromMessages(this, this.subScribedMessages); 
                    this.props.hideLoader();
                    this.props.showNotification(responseJson.message, 'error');
                } else {
                    //Check Error Response
                    if (responseJson && responseJson.errors && responseJson.errors.order) {
                        const error = Array.isArray(responseJson.errors.order) && responseJson.errors.order.join(" ")
                        this.props.showNotification(error, 'error');
                    }

                    // this.parseApiErrorResponse(responseJson);
                }

            }

            else if (apiRequestCallId === this.apiGetBasePriceApiCallID) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.data) {
                    await this.setState({
                        basePrices: responseJson?.data,
                    });
                }
                else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                }
            }

            else if (apiRequestCallId === this.apiMerchantDetailsApiCallID) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.data) {
                    await this.setState({
                        paperType: responseJson?.data[0]?.attributes?.paper_types,
                        pageType: responseJson?.data[0]?.attributes?.page_types,
                        printColor: responseJson?.data[0]?.attributes?.print_colours?.map((x: any) => {
                            return x.toLowerCase()
                        }),
                        baseMerchantPrices: responseJson?.data[0]?.attributes?.print_prices.data,
                        modalOpen: true
                    });
                }
                else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                }
            }
        }

        // Customizable Area End
    }

    navigateToMerchantLocation(userLocation: any, merchantLocation: any) {
        const DirectionsService = new google.maps.DirectionsService();
        DirectionsService.route(
            {
                origin: new window.google.maps.LatLng(parseFloat(userLocation.lat), parseFloat(userLocation.lng)),
                destination: new window.google.maps.LatLng(parseFloat(merchantLocation.lat), parseFloat(merchantLocation.lng)),
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result: any, status: any) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }

    async getNearByServiceProvidrs() {
        const header = {
            "Content-Type": configJSON.contentType,
            "token": this.state.authToken,
            uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

        };

        this.setState({ loading: true });
        this.props.showLoader();
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetNearByServiceProviderCallId = requestMessage.messageId;
        let finalAPIEndPoint = ('' + configJSON.getNearByServiceProviderAPiEndPoint).split('currLat').join('' + this.state.lat);
        finalAPIEndPoint = finalAPIEndPoint.split('currLng').join('' + this.state.lng);
        finalAPIEndPoint = finalAPIEndPoint.split('currRadius').join('' + this.state.radiusKm);

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            finalAPIEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    async placeOrderApiCall() {

        const header = {
            "Content-Type": configJSON.contentType,
            "token": this.state.authToken,
            uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

        };

        const data = {
            account_id: Number(this.state.id),
            status: 'pending',
            total_docs: Number((this.state.attachmentIds).length),
            attachment_ids: this.state.attachmentIds
        };
        const httpBody = {
            data: data
        };

        this.setState({ loading: true });

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiPlaceOrderCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.placeOrderAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    async getBasePrices(): boolean {

        const header = {
            "Content-Type": "application/json",
            "token": this.state.authToken,
            uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

        };

        this.setState({ loading: true });
        this.props.showLoader();


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetBasePriceApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getBasePrintPrices}`)


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    async getMerchantDetails(id: any) {

        const header = {
            "Content-Type": configJSON.contentType,
            "token": this.state.authToken,
            uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

        };

        this.setState({ loading: true });
        this.props.showLoader();


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiMerchantDetailsApiCallID = requestMessage.messageId;


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getMerchantPrintPrice}?id=${id}`)


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    // Customizable Area End
}
