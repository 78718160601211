// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import '../AddPayoutDetails.css'
import StorageProvider from "../../../framework/src/StorageProvider";
import AddPrintPricesController, {
  Props,
  configJSON,
} from "./AddPrintPricesController.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import {
  info,
  arrowRightGreenIcon,
  arrowDownBlackIcon,
  arrowRightIcon,
  arrowRightBlackIcon,
  A1,
  A2,
  A3,
  A4,
  Letter,
  cameraIcon,
  profileIcon,
  deleteIcon,
  mapsIcons,
  userLogo,
} from "./assets";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// Customizable Area Start
import { ButtonProps } from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";

const GreenBotton = styled((props: ButtonProps) => (
  <Button focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}> 
    <Typography variant="h5">SAVE CHANGES</Typography>
  </Button>
))(({}) => ({
  cursor: "pointer", 
  width: "100%", 
  border: `2px solid ${APP_COLORS.app_theme_green_color}`, 
  textAlign: "center", 
  backgroundColor: APP_COLORS.app_theme_green_color, 
  color: "white",
  padding: "10px", 
  marginTop: "15px", 
  borderRadius: "7px",
  '&:hover': {
    backgroundColor: APP_COLORS.app_theme_green_color,
    borderColor:  `2px solid ${APP_COLORS.app_theme_green_color}`,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: APP_COLORS.app_theme_green_color,
    borderColor:  `2px solid ${APP_COLORS.app_theme_green_color}`,
  },
  '&:disabled': {
    backgroundColor: '#eeeff1',
    // borderColor:  '2px solid #eeeff1',
    border: 'none'
  }
}));
// Customizable Area End
class AddPrintPrices extends AddPrintPricesController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: -1
    }
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        id: loginData.id,
        authToken: loginData.token,
        is_setting_locked: loginData.is_setting_locked,
      });
    }
    let businessDetailsData = await StorageProvider.get("businessDetailsData");
    if (businessDetailsData) {
      businessDetailsData = JSON.parse(businessDetailsData);
      this.setState({
        is_setting_locked: businessDetailsData.is_setting_locked,
      });
    }
    this.getMerchantPrices();
    this.getBasePrices();
    // Customizable Area End
  }

  // Customizable Area Start
  handleSetPrice = (pageType) => {
    this.setState({
      selectedPageType: pageType,
      modalOpen: true,
      setPriceClr: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored")[0]?.attributes.base_price ? false : true : false,
      setPriceGry: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0]?.attributes.base_price ? false : true : false,
      valueClr: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored").base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.colored : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.paper_type == pageType)[0]?.single_side?.colored,
      valueGry: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale").base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.colored : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.paper_type == pageType)[0]?.single_side?.greyscale
    });
  }

  handleSelectedPaperTypeChange = (pageType) => {

    this.setState({
      selectedPageType: pageType,
      setPriceClr: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored")[0]?.attributes.base_price ? false : true : false,
      setPriceGry: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0]?.attributes.base_price ? false : true : false,
      valueClr: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored").base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.colored : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "colored")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.paper_type == pageType)[0]?.single_side?.colored,
      valueGry: this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale").base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.colored : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.attributes.paper_type == pageType).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageTypes) => pageTypes.paper_type == pageType)[0]?.single_side?.greyscale
    })
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    })
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { mode } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Grid item xs={12} sm={6}>
          <div className={classes.tabContentLeft} style={{ paddingTop: 30 }}>
            <Typography className={classes.screenHeading}>
              {configJSON.labelAddPrintPrices}
            </Typography>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <div className={classes.tabContentLeft}>
              <Typography className={classes.basicInfo} variant="body1">Select Page Size</Typography>
              {
                this.state.pages?.map((item, key) => {
                  return this.state.selectedPage === item ?
                    <Grid key={key} item xs={10} className={classes.editProfileColorButton} onClick={() => { this.setState({ selectedPage: item }) }}>
                      <div className={classes.imageContainer}>
                        <img
                          src={item == 'A1' ? A1 : item == 'A2' ? A2 : item == 'A3' ? A3 : item == "A4" ? A4 : Letter}
                          className={`${classes.sofiaFont}  ${classes.editProfileButtonIcon}`}
                        />
                      </div>
                      <span
                        className={`${classes.sofiaFont}  ${classes.editProfileColorButtonTxt}`}
                      >
                        {item + ' Size Page'}
                      </span>
                      <img
                        src={arrowRightIcon}
                        className={classes.editProfileArrowIcon}
                      /></Grid> :
                    <Grid key={key} item xs={10} className={classes.editProfileButton} onClick={() => { this.setState({ selectedPage: item }) }}>
                      <div className={classes.imageContainer}>
                        <img
                          src={item == 'A1' ? A1 : item == 'A2' ? A2 : item == 'A3' ? A3 : item == "A4" ? A4 : Letter}
                          className={`${classes.sofiaFont}  ${classes.editProfileButtonIcon}`}
                        />
                      </div>
                      <span
                        className={`${classes.sofiaFont}  ${classes.editProfileButtonTxt}`}
                      >
                        {item + ' Size Page'}
                      </span>
                      <img
                        src={arrowRightBlackIcon}
                        className={classes.editProfileArrowIcon}
                      /></Grid>
                })
              }
            </div>
          </Grid>
          {
            this.state.selectedPage !== '' ?
              <Grid item xs={12} sm={7}>
                <div className={`${classes.tabContentLeft}`}>
                  {
                    this.state.selectedPage != undefined ?
                      this.state.paper_types?.map((item, key) => {
                        return (
                          <Grid key={key} item xs={12} className={classes.editPrintPrice}>
                            <div className={classes.editPrintPriceContainer}>
                              <Typography className={classes.editPrintPricePageSizeTxt} variant="h5">{this.state.selectedPage} Size Paper</Typography>
                              <Typography className={classes.editPrintPricePaperTypeTxt} variant="h5">{item}</Typography>
                            </div>
                            <div className={classes.editPrintPriceContainer} style={{ marginTop: "10px" }}>
                              <div>
                                <span
                                  className={`${classes.sofiaFont}  ${classes.editPrintPriceSmTxt}`}
                                >
                                  Color Page Price
                                </span>
                                <Typography className={classes.editPrintPricePaperTypeTxt} variant="h5">Rs. {this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageType) => pageType.attributes.paper_type == item).filter((pageClr) => pageClr.attributes.colors == "colored").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageType) => pageType.attributes.paper_type == item).filter((pageClr) => pageClr.attributes.colors == "colored")[0].attributes?.base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.colored : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageType) => pageType.attributes.paper_type == item).filter((pageClr) => pageClr.attributes.colors == "colored")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.colored}/-</Typography>
                              </div>
                              <div>
                                <span
                                  className={`${classes.sofiaFont}  ${classes.editPrintPriceSmTxt}`}
                                >
                                  Grayscale Page Price
                                </span>
                                <Typography className={classes.editPrintPricePaperTypeTxt} variant="h5">Rs. {this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageType) => pageType.attributes.paper_type == item).filter((pageClr) => pageClr.attributes.colors == "greyscale").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageType) => pageType.attributes.paper_type == item).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0].attributes?.base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.greyscale : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == this.state.selectedPage).filter((pageType) => pageType.attributes.paper_type == item).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == item)[0]?.single_side?.greyscale}/-</Typography>
                              </div>
                            </div>
                            <Grid style={{ width: "100%" }}>
                              <div
                                onClick={() => this.handleSetPrice(item)}

                                className={classes.saveChangesBtn}
                                style={{ border: `2px solid ${APP_COLORS.app_theme_green_color}`, cursor: "pointer", textAlign: 'center', padding: '16px' }}
                              >
                                <Typography style={{ color: APP_COLORS.app_theme_green_color, textAlign: "center", verticalAlign: "center" }} variant="h5">SET YOUR PRICE</Typography>
                                <img
                                  src={arrowRightGreenIcon}
                                  className={classes.editProfileArrowIcon}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        )
                      }) : null
                  }
                </div>
              </Grid> : null
          }
        </Grid>
        <Dialog
          fullWidth={true}
          open={this.state.modalOpen ? this.state.modalOpen : false}
          onClose={() => this.setState({ modalOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title" >
            <span style={{ fontFamily: APP_FONTS.SofiaProRegular }}>{this.state.title}</span>
          </DialogTitle> */}
          <DialogActions>
            <Button
              style={{ fontFamily: APP_FONTS.SofiaProRegular, color: '#32795f' }}
              onClick={() => this.setState({ modalOpen: false })}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
          <DialogContent>
            <div id="alert-dialog-description" style={{ fontFamily: APP_FONTS.SofiaProRegular }}>
              <Typography className={classes.editPrintPricePaperTypeTxt} variant="h5">Set Price of {this.state.selectedPage} Size Page</Typography>
              <div style={{ marginTop: "20px" }}>
                <div
                  style={{ color: "black" }}
                  className={`${classes.sofiaFont}  ${classes.editPrintPriceSmTxt}`}
                >
                  PAGE TYPE
                </div>
                <Select
                  defaultValue=""
                  displayEmpty
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                      root: classes.root
                    },
                  }}
                  className={classes.select}
                  renderValue={() => {
                    return (
                      <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className={classes.editPrintPricePaperTypeTxt} component="span" variant="h5">{this.state.selectedPageType}</Typography>
                        <img
                          src={arrowDownBlackIcon}
                          className={classes.editProfileArrowIcon}
                        />
                      </div>
                    );
                  }}
                  //value={this.state.selectedPageType}
                  //label="Page Type"
                  style={{ width: "100%", border: "2px solid #bdbdbd", marginTop: "10px", padding: "5px 10px", borderRadius: "5px", paddingLeft: "24px" }}
                //onChange={handleSelectedPaperTypeChange}
                >
                  {
                    this.state.paper_types?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item} onClick={() => this.handleSelectedPaperTypeChange(item)}>{item}</MenuItem>
                      )
                    })
                  }
                </Select>
              </div>
              {this.state.print_colours?.includes("colored") ? <div>
                <Divider style={{ marginTop: "15px", marginBottom: "15px" }}></Divider>
                <div
                  style={{ color: "black" }}
                  className={`${classes.sofiaFont}  ${classes.editPrintPriceSmTxt}`}
                >
                  PRICE OF COLORED PAGE
                </div>
                <div style={{ width: "100%", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                  <div
                    onClick={() => this.setState({ setPriceClr: false })}
                    style={{ width: "50%" }}
                    className={`${classes.printPriceSelectBtn} ${this.state.setPriceClr ? classes.setPriceSwitch : classes.setPriceSwitchColor}`}
                  >
                    <Typography variant="h5">Default</Typography>
                  </div>
                  <div
                    onClick={() => this.setState({ setPriceClr: true })}
                    style={{ width: "50%" }}
                    className={`${classes.printPriceSelectBtn} ${this.state.setPriceClr ? classes.setPriceSwitchColor : classes.setPriceSwitch}`}
                  >
                    <Typography variant="h5">Set Yours</Typography>
                  </div>
                </div>
                <div className={this.state.setPriceClr ? classes.priceInputBox : classes.priceShowBox}>
                  <Typography variant="h4"><span style={{ color: "#91909e" }}>Inr |  </span>{this.state.setPriceClr ? <input onKeyPress={e => /[^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$]$/.test(e.key) && e.preventDefault()} min={1} onChange={(e) => this.setState({ valueClr: e.target.value })} className={`${classes.defaultInputStyle}`} type="number" placeholder={this.state.valueClr} value={this.state.valueClr} /> : this.state.basePrices?.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == this.state.selectedPageType)[0]?.single_side?.colored}</Typography>
                </div>
                <div
                  className={`${classes.sofiaFont}  ${classes.editPrintPriceSmTxt}`}
                >
                  Price of one side of page
                </div>
              </div> : null}
              {this.state.print_colours?.includes("greyscale") ? <div>
                <Divider style={{ marginTop: "15px", marginBottom: "15px" }}></Divider>
                <div
                  style={{ color: "black" }}
                  className={`${classes.sofiaFont}  ${classes.editPrintPriceSmTxt}`}
                >
                  PRICE OF GREYSCALE PAGE
                </div>
                <div style={{ width: "100%", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                  <div
                    onClick={() => this.setState({ setPriceGry: false })}
                    style={{ width: "50%" }}
                    className={`${classes.printPriceSelectBtn} ${this.state.setPriceGry ? classes.setPriceSwitch : classes.setPriceSwitchColor}`}
                  >
                    <Typography variant="h5">Default</Typography>
                  </div>
                  <div
                    onClick={() => this.setState({ setPriceGry: true })}
                    style={{ width: "50%" }}
                    className={`${classes.printPriceSelectBtn} ${this.state.setPriceGry ? classes.setPriceSwitchColor : classes.setPriceSwitch}`}
                  >
                    <Typography variant="h5">Set Yours</Typography>
                  </div>
                </div>
                <div className={this.state.setPriceGry ? classes.priceInputBox : classes.priceShowBox}>
                  <Typography variant="h4"><span style={{ color: "#91909e" }}>Inr |  </span>{this.state.setPriceGry ?
                    <input
                      min={1}
                      onKeyPress={e => /[^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$]$/.test(e.key) && e.preventDefault()}
                      onChange={(e) => this.setState({ valueGry: e.target.value })}
                      className={`${classes.defaultInputStyle}`}
                      type={"number"}
                      placeholder={this.state.valueGry}
                      value={this.state.valueGry}
                    />
                    :
                    this.state.basePrices?.filter((paperSize) => paperSize.page_size == this.state.selectedPage).filter((pageType) => pageType.paper_type == this.state.selectedPageType)[0]?.single_side?.greyscale}</Typography>
                </div>
                <div
                  className={`${classes.sofiaFont}  ${classes.editPrintPriceSmTxt}`}
                >
                  Price of one side of page
                </div>
              </div> : null}
              <div style={{ margin: "15px 0px" }}>
                <div
                  style={{ backgroundColor: "#eeeff1", padding: "16px", borderRadius: "7px", color: "black", display: "flex", gap: "20px", alignItems: "center", justifyContent: "space-between" }}
                >
                  <div>
                    <img
                      src={info}
                      className={classes.editProfileArrowIcon}
                    />
                  </div>
                  <div>
                    <Typography variant="h5">{`PrintSec takes INR ${this.state.admin_commission_percentage_for_greyscale ? `${this.state.admin_commission_percentage_for_greyscale}%` : `Rs. ${this.state.admin_commission_for_greysacle}/`} Page for Mono or GrayScale and INR ${this.state.admin_commission_percentage_for_coloured ? `${this.state.admin_commission_percentage_for_coloured}%` : `Rs. ${this.state.admin_commission_for_coloured}/`} Page for Colored Irrepective of page size as platform fee excluding GST.`}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
             <GreenBotton
                disabled={this.state.is_setting_locked}
                onClick={() => this.postMerchantPrice()}
              />
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    tabContentLeft: {
      width: "85%",
      margin: "auto",
    },

    //Text Styling
    screenHeading: {
      fontSize: 22,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    },
    basicInfo: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginTop: 27,
    },
    inputLabel: {
      fontSize: 14,
      marginTop: 15,
      letterSpacing: 1,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },

    //Input Styling
    inputTextBox: {
      padding: "5px 10px",
      width: "100%",
      marginTop: 7,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
   saveChangesBtn: {
      textTransform: "upper-case",
      borderRadius: 12,
      width: "85%",
      maxWidth: 400,
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",      // margin: "25px",
      padding: "4px 10px",
      margin: "auto",
      marginTop: "25px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    printPriceSelectBtn: {
      textTransform: "upper-case",
      borderRadius: 12,
      width: "50%",
      maxWidth: 400,
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",      // margin: "25px",
      padding: "4px 10px",
      margin: "auto",
      marginTop: "10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    editBtn: {
      color: "#1e1f20",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "90%",
      height: 50,
      margin: "10px auto 0",
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    imageContainer: {
      boxSizing: "border-box",
      padding: '10px 7px',
      borderRadius: '10px',
      backgroundColor: "#ebebeb"
    },
    editProfileColorButton: {
      width: "90%",
      margin: "3% auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      padding: "17px ",
      borderRadius: "12px",
      backgroundColor: "#32795f",
    },
    editPrintPrice: {
      width: "100%",
      margin: "3% auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'space-between',
      padding: "17px",
      borderRadius: "12px",
      backgroundColor: "#fff",
      boxShadow: '0px 0px 3px 0px'
    },
    editProfileButton: {
      width: "100%",
      margin: "3% auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      cursor: "pointer",
      padding: "17px",
      borderRadius: "12px",
      backgroundColor: "#fff",
      boxShadow: '0px 0px 3px 0px'
    },
    editProfileButtonIcon: {
      width: "30px",
      position: 'relative'
    },
    editProfileColorButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width: "70%",
      marginLeft: "15px",
      textAlign: "left",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#ffffff",
      textAlign: "left",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editPrintPriceSmTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width: "70%",
      textAlign: "left",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#8f92a1",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width: "70%",
      marginLeft: "15px",
      textAlign: "left",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#8f92a1",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileArrowIcon: {
      width: "18px",
      margin: '0.8rem',
      height: "18px",
    },
    rightBox: {
      border: "2px solid red",
    },
    editPrintPriceContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between"
    },
    editPrintPricePageSizeTxt: {
      backgroundColor: "#ebebeb",
      padding: "5px 10px",
      borderRadius: "6px",
      color: "black"
    },
    editPrintPricePaperTypeTxt: {
      fontSize: "18px",
      color: "black"
    },
    setPriceModal: {
      backgroundColor: "#f7f7f7",
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "15px 25px",
      borderRadius: "15px",
      width: "35%"
    },
    select: {
      '&:before': {
        border: '0px',
      },
      '&:after': {
        border: '0px',
      },
      '&:not(.Mui-disabled):hover::before': {
        border: '0px',
      },
    },
    icon: {
      display: "none",
    },
    root: {
      color: 'black',
      padding: "0px"
    },
    setPriceSwitchColor: {
      color: APP_COLORS.app_theme_green_color,
      border: `2px solid ${APP_COLORS.app_theme_green_color}`,
      cursor: "pointer",
      textAlign: 'center',
      padding: '10px'
    },
    setPriceSwitch: {
      color: "#91909e",
      border: `2px solid #eeeff1`,
      cursor: "pointer",
      backgroundColor: "#eeeff1",
      textAlign: 'center',
      padding: '10px'
    },
    priceInputBox: {
      width: "100%",
      color: APP_COLORS.app_theme_green_color,
      backgroundColor: '#edf1f0',
      padding: "10px",
      borderRadius: '7px',
      marginTop: '10px',
      marginBottom: '10px',
      border: "2px solid #edf1f0",
      textTransform: "uppercase"
    },
    priceShowBox: {
      width: "100%",
      color: APP_COLORS.app_theme_green_color,
      padding: "10px",
      borderRadius: '7px',
      marginTop: '10px',
      marginBottom: '10px',
      border: "2px solid #edf1f0",
      textTransform: "uppercase"
    },
    defaultInputStyle: {
      border: "0px",
      backgroundColor: "transparent",
      padding: "0px",
      cursor: 'pointer',
      outline: 'none',
      boxShadow: 'none',
    }
  });
// Customizable Area End

const AddPrintPricesWithConfirmBox = withConfirmBox(AddPrintPrices);
const AddPrintPricesWithLoader = withLoader(AddPrintPricesWithConfirmBox);
const AddPrintPricesWithToast = withToast(AddPrintPricesWithLoader);
//@ts-ignore
export default withRouter(
  withStyles(styles, { withTheme: true })(AddPrintPricesWithToast)
);
