export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const facebookIcon = require("../assets/facebook.svg");
export const githubIcon = require("../assets/git-hub-copy.svg");
export const linkedinIcon = require("../assets/linkedin.svg");
export const twitterIcon = require("../assets/twitter.svg");
export const instgramIcon = require("../assets/instagram.svg");
export const macbookImg = require("../assets/macbook-scene.png");
export const macbookImg2 = require("../assets/macbook-scene@2x.png");
export const macbookImg3 = require("../assets/macbook-scene@3x.png");
export const addUser = require("../assets/add-user.svg");
export const group7 = require("../assets/group-7.svg");
export const group12 = require("../assets/group-12.svg");
export const group3 = require("../assets/group-3.svg");
export const prevIcon = require("../assets/prevIcon.svg");
export const nextIcon = require("../assets/nextIcon.svg");
export const storyIcon = require("../assets/stories@3x.png");
export const banner = require("../assets/Mask_Group_9.png");
export const logo = require("../assets/logo_transparent_new.png");
export const bannerResize = require("../assets/Mask_Group_9.png");
export const AppStore = require("../assets/aivalable-on-the-app-store-2.svg");
export const PlayStore = require("../assets/google-play-badge@3x.jpg");
export const QRcode = require("../assets/qr-code.svg");
export const dataPrivacy=require("../assets/icons-data-privacy.svg");
export const dataEncryption=require("../assets/icons-data-encryption.svg");
export const multiFactor=require("../assets/icons-multi-factor-authentication.svg");
export const documentSecurity= require("../assets/icons-document-security.svg");
export const secureDownload=require("../assets/icons-secure-download-hassle-free.svg");
export const documentDelivery=require("../assets/icons-document-delivery-to-desired.svg");
export const homeicon=require("../assets/homeicon.svg")
export const abouticon=require("../assets/abouticon.svg")
export const servicesicon=require("../assets/coloricon.svg")
export const contactusicon=require("../assets/phoneicon.svg")
export const usericon=require("../assets/user.svg")
export const arrowdown=require("../assets/arrow-down.svg")
export const closeicon=require("../assets/close.svg")
export const menuicon=require("../assets/menuIcon.svg")
export const landingPageVideo=require("../assets/printsec_1-2-2021.mp4")
export const leftHandImg=require("../assets/hand.png");
export const appleStore=require("../assets/btn1.png");
export const googlePlayStore=require("../assets/btn2.png");
export const webStore=require("../assets/btn3.png");
export const googleQrCode=require("../assets/google.png");
export const appQrCode=require("../assets/app.png");
export const backDots=require("../assets/dots.png");
export const check1=require("../assets/check1.png");
export const check2=require("../assets/check2.png");
export const thumbImg=require("../assets/thumb.png");
export const testimonialImg=require("../assets/Mask_Group_3.png");
export const workImg=require("../assets/lyr.png");
export const footerImg=require("../assets/EmbeddedImage.png");
export const gtxImg=require("../assets/apz.png");
export const glxImg=require("../assets/glp.png");
export const wlxImg=require("../assets/wp.png");
export const socialMediaImg=require("../assets/soi.png");
export const envlpImg=require("../assets/envlp.svg");
export const twitterImg=require("../assets/twitter.svg");
export const gtImg=require("../assets/gt.png");
export const pImg=require("../assets/p.svg");
export const publicQueueImg=require("../assets/public_queue.png");
export const publicQueueSeletedImg=require("../assets/public_queue_selected.png");
export const privateQueueImg=require("../assets/private_queue.png");
export const privateQueueSelectedImg=require("../assets/private_queue_selected.png");
export const hostedQueueImg=require("../assets/hosted_queue.png");
export const hostedQueueSelectedImg=require("../assets/hosted_queue_selected.png");
export const secureImg=require("../assets/ic1.png");
export const controlImg=require("../assets/1c2.png");
export const printImg=require("../assets/1c3.png");
export const printCopyImg=require("../assets/prnt.png");
export const printerImg=require("../assets/prrt.png");
export const serviceProviderJourney = require("../assets/led.png");
export const digitalMapImg=require("../assets/mapx.png");
export const clpMapImg=require("../assets/clp.png");
export const clp1MapImg=require("../assets/clp1.png");
export const ctxMapImg=require("../assets/ctx.png");
export const serviceProviderImg=require("../assets/tab2.png");
export const serviceProvideSelectedImg=require("../assets/service_provider_selected.png");
export const userUnSelectedImg=require("../assets/user_unseleted.png");
export const ideaToBuisnessImg=require("../assets/ideato_buisness.png");
export const ideaToBuisnessSelectedImg=require("../assets/ideato_buisness_selected.png");
export const ideaTochallengedImg=require("../assets/ideato_challenge.png");
export const ideaTochallengeSelecteddImg=require("../assets/ideato_challenge_selected.png");
export const customSolutionImg=require("../assets/custom_solution.png");
export const customSolutionSelectedImg=require("../assets/custom_solution_selected.png");
export const testimonialImg1=require("../assets/testi1.jpg");
export const testimonialImg2=require("../assets/testi2.jpg");
export const combinedApplink=require("../assets/New_combined_App_link.svg");