// @ts-nocheck
import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from '../../../components/src/AppColorConstants';
// Customizable Area End

import HeaderController, {
  Props,
  configJSON
} from "./HeaderController.Web";
import './Header.css'
import {
  logo, usericon, arrowdown, closeicon, menuicon
} from './assets'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClientCaptcha from "react-client-captcha";
import TextField from '@material-ui/core/TextField';

class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  changeMenu = () => {
    this.setState({ mobilemenu: !this.state.mobilemenu })
    const mobileMenu = document.getElementById("navMobileDropdown")
    mobileMenu.classList.toggle("navMobileHeight")
  }

  async componentDidMount() {
    this.getHeaderContent()
    await this.requestLocationPermission();
  }

  showSecurePrintingMenu = () => {
    this.setState({ securePrintMenu: !this.state.securePrintMenu })
  }

  showDigitalMenu = () => {
    this.setState({ digitalMenu: !this.state.digitalMenu })
  }

  handleMouseOver = (itemNumber) => {
    document.getElementsByClassName('Header_sub_menu')[itemNumber].style.display = "flex"
  }

  handleMouseOut = (itemNumber) => {
    document.getElementsByClassName('Header_sub_menu')[itemNumber].style.display = "none"
  }

  handleCaptcha = () => {
    if (this.state.captchaCode !== this.state.setCaptchaCode) {
      this.setState({ captchaError: true })
    } else {
      this.setState({
        captchaError: false,
        modalOpen: false
      })
      this.handleGuestLogin()
    }

  }

  getOneTimeLocation = () => {
    this.setState({ loading: true });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLongitude = JSON.stringify(position.coords.longitude);
        const currentLatitude = JSON.stringify(position.coords.latitude);
        this.setState(
          {
            lat: currentLatitude,
            lng: currentLongitude,
          },
          () => {
            !(this.state.address) && this.getAddress(currentLatitude, currentLongitude);
          }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        );
      },
      (error) => {
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  };

  getAddress = (lat: any, lng: any) => {
    let request = new XMLHttpRequest();
    let method = "GET";
    let url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&sensor=true&key=AIzaSyBI5xkmKUlPoVouVxtAY2prU4ghXF5V0kc";
    let async = true;
    request.open(method, url, async);
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        let data = JSON.parse(request.responseText);
        let state = data.results[0].address_components[5].long_name || ""
        let address = data.results[0].formatted_address || "";
        this.setState({ address: address, state: state });
      }
    };
    request.send();
  };

  // this is to get current geolocation and address from geolocation
  requestLocationPermission = async () => {
    this.getOneTimeLocation();
  };


  render() {
    const { classes } = this.props;
    const getPathName = this.props.location.pathname;
    const { } = this.props;

    return (
      //Merge Engine DefaultContainer
      <div
        className={"navBar navMobile top-nav-align"}
      >
        <div className="navBarContent">
          <div className={"navLeft"}>
            <img onClick={() => this.props.history.push("/")} src={logo} alt="" className={classes.logoImg} />
          </div>
          <div className={"navMiddle nav-top-middle"}>
            <div
              className={"Header_Dropdown_Cont"}
              onMouseOver={(e) => {
                this.handleMouseOver(0);
              }}
              onMouseOut={(e) => {
                this.handleMouseOut(0)
              }}
            >
              <span className={`${classes.navMiddleMenuItem} mega-menu-align`}>
                Secure Printing Solution
                <span><i className="fa fa-angle-down" aria-hidden="true"></i></span>
              </span>

              <div className="Header_sub_menu header-sb">

                <span className={`${classes.navMiddleMenuItem} sub-menu-alignx`}
                >
                  <p className="menu-title">Secure Printing services</p>

                  <p className="menu-ico"></p>
                  <h3>Public Queue</h3>
                  <p className="header-line-qte">This service is available through Web/Mobile App (Android and iOS) and can be used by end-users.</p>
                  <p className="read"
                    onClick={() => {
                      if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                        this.props.history.push({
                          pathname: '/',
                          state: { publicQueueState: "header-puclicQueue" }
                        })
                      } else {
                        this.props.navigationMenuHandler(0)
                      }
                      this.handleMouseOut(0)
                    }
                    }
                  >
                    Read More<span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
                  <img src={arrowdown} alt="" className={classes.navDownArrowImg} />
                </span>
                <span className={`${classes.navMiddleMenuItem} align-2`}
                >


                  <p className="menu-ico1"></p>
                  <h3>Private Queue</h3>
                  <p className="header-line-qte">This service is a custom dedicated private queue in a SaaS model available through Web/Mobile App (Android and iOS). Services are meant for Small/Medium/Large enterprises who want to avail secure printing services and lower their printing/consumable TCO at the same time.</p>
                  <p className="read"
                    onClick={() => {
                      if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                        this.props.history.push({
                          pathname: '/',
                          state: { privateQueueState: "header-privateQueue" }
                        })
                      } else {
                        this.props.navigationMenuHandler(1)
                      }
                      this.handleMouseOut(0)
                    }
                    }
                  >Read More <span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
                  <img src={arrowdown} alt="" className={classes.navDownArrowImg} />
                </span>
                <span className={`${classes.navMiddleMenuItem} third-menu `}
                >
                  <p className="menu-ico2"></p>
                  <h3>Hosted Queue</h3>
                  <p className="header-line-qte">This service is a custom dedicated hosted private queue in a COLO or client’s DC available through Web/Mobile App (Android and iOS). Services are meant for public and BFSI enterprises where compliance is strict and have less control on data being fired for printing. Secure printing services resolve all these issues while lowering their TCO.</p>
                  <p className="read"
                    onClick={() => {
                      if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                        this.props.history.push({
                          pathname: '/',
                          state: { hostedQueueState: "header-hostedQueue" }
                        })
                      } else {
                        this.props.navigationMenuHandler(2)
                      }
                      this.handleMouseOut(0);
                    }
                    }
                  >Read More <span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span> </p>
                  <img src={arrowdown} alt="" className={classes.navDownArrowImg} />
                </span>
              </div>

            </div>
            <div className="nv-top-cover">
              <span className={classes.navMiddleItem} onClick={() => this.props.history.push("/About-us")} >
                About Us
              </span>
            </div>
            <div className="nv-top-cover">
              <span className={classes.navMiddleItem} onClick={() => this.props.history.push("/Services")}>
                Services
              </span>
            </div>
          </div>
          <div className={"navRight header-nav-right"}>
            <Button style={{ width: '135px' }} className={`${classes.navLoginBtn} navButton`} onClick={() => {
              this.setState({
                modalOpen: true
              })
            }}>
              Guest Login
            </Button>
            <Button className={`${classes.navLoginBtn} navButton`} onClick={() => this.props.history.push("/PhoneLoginWeb")}>
              Login
            </Button>
            <Button className={`${classes.navLoginBtn} navButton signup-btn-align`} onClick={() => this.props.history.push("/SignUp")}>
              Sign up
            </Button>
            <Button className={`${classes.navSignupBtn} navButton download-app-align`}
              onClick={() => {
                if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                  this.props.history.push({
                    pathname: '/',
                    state: { downloadState: "download-state" }
                  })
                } else {
                  this.props.navigationToDownload()
                }
              }
              }
              style={{ backgroundColor: APP_COLORS.app_theme_green_color }}>
              Download The App
            </Button>
          </div>
          <div className={"navRight header-nav-right"}>
            <img src={this.state.mobilemenu ? closeicon : menuicon} alt="" className={"nav_close_img"} onClick={this.changeMenu} />
          </div>
        </div>
        <div className="navMobileContent navMobileHeight" id={"navMobileDropdown"}>
          <Collapse in={this.state.mobilemenu} style={{ width: "100%" }}>

            <span className={`${classes.navMiddleItem} navMobileMenuItem arowx`} onClick={this.showSecurePrintingMenu}>
              Secure Printing Solution
              <img src={arrowdown} alt="" className={classes.navDownArrowImg} />
            </span>
            <div className={`${this.state.securePrintMenu ? 'main' : 'mainDisplayNone'} services_mobile_submenu drop-menu`}>
              <div className=" drop-menux">
                <p className="menu-ico"></p>
                <h3>Public Queue</h3>
                <p className="read"
                  onClick={() => {
                    if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                      this.props.history.push({
                        pathname: '/',
                        state: { publicQueueState: "header-puclicQueue" }
                      })
                    } else {
                      this.props.navigationMenuHandler(0)
                    }
                  }
                  }
                >Read More<span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
              </div>

              <div className=" drop-menux">
                <p className="menu-ico1"></p>
                <h3>Private Queue</h3>
                <p className="read"
                  onClick={() => {
                    if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                      this.props.history.push({
                        pathname: '/',
                        state: { privateQueueState: "header-privateQueue" }
                      })
                    } else {
                      this.props.navigationMenuHandler(1)
                    }
                  }
                  }
                >Read More<span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
              </div>

              <div className=" drop-menux">
                <p className="menu-ico2"></p>
                <h3>Hosted Queue</h3>
                <p className="read"
                  onClick={() => {
                    if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                      this.props.history.push({
                        pathname: '/',
                        state: { hostedQueueState: "header-hostedQueue" }
                      })
                    } else {
                      this.props.navigationMenuHandler(2)
                    }
                  }
                  }
                >Read More<span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
              </div>

            </div>
            <span className={`${classes.navMiddleItem} navMobileMenuItem arowx1`} onClick={this.showDigitalMenu}>
              Digital Consulting
              <img src={arrowdown} alt="" className={classes.navDownArrowImg} />
            </span>
            <div className={`${this.state.digitalMenu ? 'main' : 'mainDisplayNone'} services_mobile_submenu drop-menu`}>
              <div className=" drop-menux digital-txt">
                <p className="menu-ico3"></p>
                <h3>Idea to Business (I2B)</h3>
                <p className="read"
                  onClick={() => {
                    if (getPathName === "/Services" || getPathName === "/" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                      this.props.history.push({
                        pathname: '/Service-details',
                        state: { ideaToBuisnessState: "header-ideaToBuisness" }
                      })
                    } else {
                      this.props.serviceNavigationMenuHandler(0)
                    }
                  }
                  }
                >Read More<span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
              </div>
              <div className=" drop-menux digital-txt">
                <p className="menu-ico4"></p>
                <h3>Idea to Challenge (I2C)</h3>
                <p className="read"
                  onClick={() => {
                    if (getPathName === "/Services" || getPathName === "/" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                      this.props.history.push({
                        pathname: '/Service-details',
                        state: { ideaToChallengeState: "header-ideaToChallenge" }
                      })
                    } else {
                      this.props.serviceNavigationMenuHandler(1)
                    }
                  }
                  }
                >Read More<span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
              </div>
              <div className=" drop-menux digital-txt">
                <p className="menu-ico5"></p>
                <h3>Custom Solution</h3>
                <p className="read"
                  onClick={() => {
                    if (getPathName === "/Services" || getPathName === "/" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                      this.props.history.push({
                        pathname: '/Service-details',
                        state: { customSolutionState: "header-customSolution" }
                      })
                    } else {
                      this.props.serviceNavigationMenuHandler(2)
                    }
                  }
                  }
                >Read More<span className="arrow-menu"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
              </div>
            </div>
            <span className={`${classes.navMiddleItem} navMobileMenuItem`} onClick={() => this.props.history.push("/About-us")}>
              About Us
            </span>
            <span className={`${classes.navMiddleItem} navMobileMenuItem`} onClick={() => this.props.history.push("/Services")}>
              Services
            </span>
            <span className={`${classes.navMiddleItem} navMobileMenuItem`} onClick={() => this.setState({
              modalOpen: true
            })}>
              {configJSON.guestLogin}
            </span>
            <div className={"navMobileButton top-navi-btn"}>
              <Button className={`${classes.navLoginBtn} navButton2`} onClick={() => this.props.history.push("/PhoneLoginWeb")}>
                <img src={usericon} alt="" className={classes.loginUser} />
                {configJSON.login}
              </Button>
              <Button className={`${classes.navSignupBtn} navButton2 sign-top`}
                onClick={() => this.props.history.push("/SignUp")}
                style={{ backgroundColor: APP_COLORS.app_theme_green_color }}>
                {configJSON.signUp}
              </Button>
              <Button className={`${classes.navSignupBtn} navButton2 download-top`}
                onClick={() => {
                  if (getPathName === "/Services" || getPathName === "/Service-details" || getPathName === "/About-us" || getPathName === "/Contact-us" || getPathName === "/Privacy-policy" || getPathName === "/Terms-and-conditions") {
                    this.props.history.push({
                      pathname: '/',
                      state: { downloadState: "download-state" }
                    })
                  } else {
                    this.props.navigationToDownload()
                  }
                }
                }
                style={{ backgroundColor: APP_COLORS.app_theme_green_color }}>
                Download The App
              </Button>
            </div>
          </Collapse>
          <Dialog
            maxWidth='xs'
            open={this.state.modalOpen}
            onClose={() => this.setState({ modalOpen: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ backgroundColor: '#34795D', color: 'white', paddingBottom: '10px' }}>
              <span style={{ fontFamily: APP_FONTS.SofiaProRegular }}>Security Check</span>
            </DialogTitle>
            <DialogContent>
              <div className={classes.captchaContent}>
                <ClientCaptcha retryIconSize={22} backgroundColor='#F7F7F7' fontSize={25} captchaClassName={classes.clientCaptcha} fontStyle={APP_FONTS.SofiaProRegular} charsCount={4} captchaCode={code => this.setState({ captchaCode: code })} />
              </div>
              <br />
              <DialogContentText id="alert-dialog-description" style={{ fontFamily: APP_FONTS.SofiaProRegular, fontSize: '16px', fontWeight: 'bold' }}>
                Enter above captcha:
              </DialogContentText>
              <TextField
                style={{ width: '100%' }}
                type="text"
                variant="outlined"
                className={`number-field ${classes.sofiaFont}`}
                required
                onChange={(e) => {
                  this.setState({
                    captchaError: false,
                    setCaptchaCode: e.target.value
                  })
                }}
              />
              {
                this.state.captchaError &&
                <p className={`error-label ${classes.sofiaFont}`}>Invalid Captcha</p>
              }

              <DialogContentText id="alert-dialog-description" style={{ fontFamily: APP_FONTS.SofiaProRegular, fontSize: '14px', textAlign: 'justify' }}>
                <br />
                You are accessing PrintSEC application in guest mode. We encourage you to not to close your browser without completing the printing process. You will not be able to access invoice, QR Code etc., if session is closed. Please call +91-9319480924 for any support you may need.
              </DialogContentText>
            </DialogContent>

            <DialogActions style={{ justifyContent: 'start' }}>
              <Button
                style={{
                  fontFamily: APP_FONTS.SofiaProRegular, color: '#00000', backgroundColor: '#FDD001',
                  width: '100px',
                  height: '37px',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
                onClick={() => this.handleCaptcha()}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont: {
    width: "100%",
  },
  navBar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    boxShadow: "0 2px 7px 0 rgba(147, 147, 147, 0.5)"
  },
  navMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  navLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  navRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  footerCont: {
    backgroundColor: APP_COLORS.app_theme_green_color,
    borderRadius: 32,
    boxShadow: "0 -1px 6px 0 rgba(0, 0, 0, 0.5)",
  },
  logoImg: {
    width: 90,
    height: 90,
    margin: "5px auto",
    objectFit: "contain",
    cursor: 'pointer'
  },
  navMiddleImg: {
    width: 45,
    height: 45,
    marginRight: "10px",
    objectFit: "contain",
  },
  navDownArrowImg: {
    width: 20,
    height: 20,
    marginLeft: "7px",
    float: "right",
    objectFit: "contain",
  },
  navMiddleItem: {
    fontSize: 17,
    fontFamily: 'Segoe UI',
    color: '#1e1f20',
    display: "flex",
    alignItems: 'center',
    cursor: 'pointer'
  },
  navMiddleMenuItem: {
    fontSize: 15,
    fontFamily: 'Segoe UI',
    color: '#1e1f20',
    margin: "10px auto",
    // padding:"0 20px",
    width: "100%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  navLoginBtn: {
    borderRadius: 12,
    height: 45,
    width: 130,
    textTransform: "none",
    fontSize: 15,
    fontFamily: 'Segoe UI'
  },
  navSignupBtn: {
    borderRadius: 12,
    height: 45,
    color: "white",
    width: 130,
    textTransform: "none",
    fontSize: 15,
    fontFamily: 'Segoe UI'
  },
  captchaContent: {
    background: '#F7F7F7',
    height: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clientCaptcha: {
    borderRadius: 0,
  },
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(Header));