export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const navBarImg = require('../assets/user.png');
export const backIcon = require('../assets/backIcon.png');
export const detailsIcon = require('../assets/details.svg');
export const mapsIcon = require('../assets/map.svg');
export const markerIcon = require('../assets/favicon.png');
export const userIcon = require('../assets/UserPin.svg');
export const arrowDown = require('../assets/arrow-down.png');
export const arrowUp = require('../assets/arrow-up.png');
export const A1 = require('../assets/A1.png');
export const A2 = require('../assets/A2.png');
export const A3 = require('../assets/A3.png');
export const A4 = require('../assets/A4.png');
export const Letter = require('../assets/Letter.png');
export const colorPallet = require('../assets/colorPallet.png');
export const greyscalePallet = require('../assets/greyscalePallet.png');
export const info = require('../assets/info.png');
