export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const docExcel = require('../assets/docexcel.png');
export const docWord = require('../assets/docword.png');
export const docPowerPoint = require('../assets/slide@3x.png');
export const navBarImg = require('../assets/user.png');
export const backIcon = require('../assets/backIcon.png');
export const detailsIcon = require('../assets/details.svg');
export const mapsIcon = require('../assets/map.svg');
export const markerIcon = require('../assets/pin.svg');
export const attachIcon = require('../assets/attach.svg');
export const colorIcon = require('../assets/color.svg');
export const checkIcon = require('../assets/check.svg');
export const historyIcon = require('../assets/history.svg');
export const checkbox_empty = require('../assets/checkbox_empty.png');
export const checkbox_marked = require('../assets/checkbox_marked.png');
export const activeDownload = require('../assets/icon1.svg');
export const passiveDownload = require('../assets/icon.svg');
export const textFileIcon = require("../assets/textfile.png");
export const downloadInvoice = require("../assets/download_invoice.png");
export const pendingPayment = require("../assets/pending_payment.png");

