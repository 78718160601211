// @ts-nocheck
import React,{ createRef } from "react";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
// Customizable Area End
import Header from "./Header.Web";
import LandingPageController, {
  Props
} from "./LandingPageController.Web";
import './Header.css';
import './LandingPage.css';
import {
  check1,
  footerImg,
  gtxImg,
  glxImg,
  envlpImg,
  twitterImg,
  gtImg,
  pImg,
  secureImg,
  controlImg,
  printImg,
  printCopyImg,
  thumbImg,
  printerImg,
  serviceProviderJourney,
  testimonialImg1,
  testimonialImg2
} from './assets'

class Services extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollToGetInTouchDiv = createRef();
    this.scrollToHowItWorkDiv = createRef();
    // Customizable Area End
  }

   // Customizable Area Start
  // Customizable Area End
   async componentDidMount() {
     window.scrollTo(0, 0);
   }

   navigateTo = (pathName) => {
       this.props.history.push(pathName);
   }
  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid
        container
        direction="row"
        justify="center"
        className="service-page"
      >
       <Header />
        <Grid item xs={12} className={"navBarTopMargin"}/>
        <Grid item xs={12}>
        <div className="jumbotron text-center service">
          <div className="col-lg-6">
          <div className="Group_12438">
              <div className="Get_Your_Documents_printed_Sec">
                <span>Our </span><span className="header-line" styles="color:rgba(255,255,255,1);">Services</span>
              </div>
              {/* <div className="A_SaaS_based_encrypted_printin">
                <span className="alx"><i className="fa fa-home" aria-hidden="true"></i> / Our Services</span>
              </div> */}
            </div>
        </div>
        </div>
        </Grid>
        <div className="container vid-section">
        <div className="row">
          <div className="col-lg-6">
            <div className="video-left service-vid">
              <h2>Control & Print</h2>
              <h3>Your Documents</h3>
              <p>Securely share confidential documents via PrintSEC.</p>
              <ul className="list-service">
                <li><span><img src={secureImg} /></span>100% Secure</li>
                <li><span><img src={controlImg} /></span>Control your print</li>
                <li><span><img src={printImg} /></span>Print anywhere</li>
              </ul>
              <p className="get-btn vid-btn hideShadow">
            <a 
              onClick={()=>
              this.props.history.push({
                pathname: '/',
                state: { serviceState: "service-page" }
              })
              }
            >Download The App</a>
          </p>
          <span className="lnk" onClick={() => {
              this.scrollToHowItWorkDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>How It Works?</span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="video">
              <img src={printCopyImg} />
            </div>
          </div>
        </div>
      </div>
    <div className="container">
    <div className="row">
      <div className="print-section" ref={this.scrollToHowItWorkDiv}>
        <h2>Print In <font>3 Easy Steps</font></h2>
        <ul className="print-list">
          <li>
           <img src={check1} /><span className="prt1">Journey as a User</span></li>
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 1: Upload Your Documents</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 2: Make Payment</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 3: Get Your Documents Printed</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="img-cntr">
              <img src={thumbImg} />
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                <li>Visit <a href="https://www.printsec.app"><font className="grn-txt" >www.printsec.app</font></a> or download our app store (iOS/Android)</li>
                <li>Sign in/log in with your phone number and PIN instead of username</li>
                <li>Upload the documents and images to print OR scan them using our scanner!</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                  <li>Complete your payment with any of the available payment options.</li>
                  <li>Get an encryption key that will be scanned at the printer shop.</li>
                  <li>Your document is completely private to you.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                 <li>Select your nearby printer from the list or visit the shop of your choice.</li>
                  <li>Follow the app and it will guide you the way to your printer’s shop.</li>
                  <li>Scan the encryption key at the printer’s shop.</li>
                  <li>Take your printout, check quality!</li>
                  <li>Acknowledge the job completion in application.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
           <div className="print-section">
            <ul className="print-list">
            <li>
           <img src={printerImg} className="print-mg" /><span className="prt1">Journey as a Service Provider</span></li>
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 1: Onboard To Printsec Platform</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 2: Print The Document</h2>
              </div>
            </div>
             <div className="col-lg-4">
              <div className="txt">
                <h2>STEP 3: Get Payment</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="img-cntr">
              <img src={serviceProviderJourney} />
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                <li>Visit <a href="https://www.printsec.app"><font className="grn-txt" >www.printsec.app</font></a> or download our app from App/Play store (iOS/Android)</li>
                <li>Sign in/log in with your phone number and PIN instead of username</li>
                <li>Update your payment profile, i.e., enter the payment methods you’ll accept.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                <li>Scan the encryption key for the authentication of the customer’s documents.</li>
                <li>Select the printer on which print job has to be sent.</li>
                <li>Handover the printout to the customer.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thumb-txt">
                <ul className="list-thumb">
                <li>Receive the payment to the registered AC/UPI updated in your payment profile.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container service-prt">
      <div className="row">
        <div className="get">
          <div className="col-lg-8">
            <div className="gt-txt">
              <h2>Are You An Enterprise? Get Started.</h2>
              <p>PrintSEC application is available for enterprises under our offering private queue and hosted queue.</p>
            </div>
          </div>
          <div className="col-lg-4 orx">
            <div className="gtm-txt">
              <a
              onClick={()=>
                this.props.history.push({
                  pathname: '/',
                  state: { serviceKnowMoreState: "service-know-more" }
                })
                }
              >Know More</a>
              <span className="orz">Or</span>
              <a onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>
  </div>
    <div className="container-fluid bx service-seq" styles="display: inline-block;">
    <div className="container">
    <div className="row">
      <div className="printing-services">
        <h2>Secure <font>Printing Services</font></h2>
        <p>SaaS based Printing solution</p>
        <p>The application PrintSEC (Android/iOS/Web) is a SaaS based service that allows end users to print documents stored on device or on cloud to any printer listed in PrintSEC app printer listing based on geographical location of the user. Printing any document from mobile devices and desktop/laptops is designed to be completely secured by encrypting data in transit and at rest, The application also allows users to download their document from the queue by going through a successful authentication process. The Job once printed can never be recovered or copied to any device which adds another layer of security to user’s data.
          Know more about different types of Print Queue services available.</p>
          <div className="gtm1-txt">
            <a href="#">Know More</a> 
          </div>
       </div>
      </div>
    </div>
  </div>
  <br/>
  {/* <div class="container digital">
    <div className="row">
      <div className="printing-services">
        <h2>Digital <font>Consulting Services</font></h2>

        <p>Printsec Digital Consulting services (PDCS) is a business unit of PrintSEC that offers a unique set of services to help an organization or individual transform their idea or challenges into a complete working business solution. Right from listening to your ideas/challenges to putting it into a solution blueprint and converting it to an MVP and/or pilot through our agile solution in and development framework allows us to timely deliver the desired solution in reality. Know more about different types of service offerings available under Digital Consulting Services.</p>
        <div className="gtm1-txt">
          <a className={classes.linkPointer} onClick={()=>this.props.history.push("/Service-details")}>Know More</a>
        </div>
      </div>
    </div>
  </div> */}
    <div className="container-fluid bx1 service-test" style={{marginTop:'10px'}}>
      <h2>Testimonials </h2>
      <p className="tst-qt">See what our clients speak about their experiences!</p>
      {/* <p className="arrow"><span className="arrow-left"></span> 
      <span className="arrow-right"></span></p> */}
      <div className="row">
        <div styles="width:75%;" className="container txts">
        <div className="col-lg-6">
          <div className="cardx">
          <p className="qte"></p>
            <p>Having a business idea I was quite panicky to kick start at the earliest before it comes to any other mind. As a non IT professional I was completely puzzled how and where to start. Someone referred me to these guys, The Solution Architect assigned in my case was very skilled having working experience of more than 18 yrs. After a few meetings I was pretty convinced and decided to open up here with my brain child. Surprisingly the solution architect and his team were excellent in perceiving my all ideas of business and within a week they came up with a complete package of solution with a timeline. It was so professionally designed that I couldn't find any drawbacks to refuse. My overall experience was total peace of mind. One stop single window solutions.</p>
            <p className="user"><span><img src={testimonialImg1} className="testiImg" /></span><span className="ct">Akhtarul Iman.</span><span className="des1">Founder and CEO.</span><span className="des1">KARLOSi TECHNOLOGIES PVT. LTD</span></p>
          </div>
        </div>
      <div className="col-lg-6">
        <div className="cardx">
          <p className="qte"></p>
          <p>
          During my final year of Master in Public Health I was working on some confidential project that I didn’t want to share with anyone considering the fact that my idea and content may be stolen. Through my close friends I discovered this application called PrintSEC, Which is a secure printing application that allows me to print my confidential document from my mobile device without sharing my files with the Shop owner.
          I would recommend this application to all students and professionals who value their documents.
          </p>
          <p className="user"><span><img src={testimonialImg2} className="testiImg" /></span><span className="ct">Aamir Mohsin</span><span className="des1">Healthcare Data Analyst.</span></p></div>
        </div>
      </div>
      </div>
    </div>
    <div className="container tst service-message">
      <div className="row">
        <div className="ftx">
          <h2>Message from <font>CEO</font></h2>
          <p>PrintSEC has a vision to simplify and digitalize everything that has an impact on human life and make technology simple to use for everyone including those who do not understand it.</p>
          <h3>Uzma Nizam</h3>
          <p className="des">CEO & MD</p>
        </div>
      </div>
    </div>
    <Grid>
      <div className="container-fluid ft">
      <div className="row">
        <div className="footer-content">
          <div className="col-lg-4">
            <div className="logo-footer">
              <img src={footerImg} />
              <p>PrintSEC has immense potential to digitalize your business and move it forward through a digital transformation.</p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-menu">
              <h3>QUICK LINKS</h3>
              <ul className="list">
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Services")} >Services</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/About-us")} >About us</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Contact-us")}>Contact us</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2" ref={this.scrollToGetInTouchDiv}>
            <div className="footer-menu">
              <h3>GET IN TOUCH
              </h3>
              <p className="salesContent">Sales Specialists at PrintSEC will help you start your digital journey. Reach out to us through:</p>
              <ul className="list contx">
              <a href="mailto:connect@printsec.app"><li><span><i className="fa fa-envelope" aria-hidden="true"></i></span>connect@printsec.app</li></a>
              <a href="tel:+91-9319480924"><li><span><i className="fa fa-phone" aria-hidden="true"></i></span>+91-9319480924</li></a>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-menu btz">
              <h3>DOWNLOAD THE APP</h3>
              <ul className="list">
              <li><a href="https://apps.apple.com/in/app/id1545304010" target="_blank"><img src={gtxImg} /></a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.SecurePrinting" target="_blank"><img src={glxImg} /></a></li>
                {/* <li><img src={wlxImg} /></li>          */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row social-icon">
        <div className="social">
          <a href="mailto:connect@printsec.app"><img src={envlpImg} /></a>
          <a href="https://twitter.com/PrintsecD" target="_blank"><img src={twitterImg} /></a>
          <a href="https://www.linkedin.com/in/printsec-digital-32b63021b/" target="_blank"><img src={gtImg} /></a>
          <a href="https://www.instagram.com/printsecdigital/" target="_blank"><img src={pImg} /></a>
        </div>
      </div>
    </div>
    <div className="container-fluid bt">
      <div className="row">
        <div className="col-lg-5">
          <div className="bottom-ft">
            <p>© 2021 PrintSEC Digital</p>
          </div>
        </div>
        <div className="col-lg-6">
        <div className="bottom-ft">
        <ul className="lst">
        <li className={classes.linkPointer}  onClick={()=>this.props.history.push("/Enduser-licence")}>End user license agreement</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Privacy-policy")}>Privacy policy</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Terms-and-conditions")}>Term and Conditions</li>
        </ul>
        </div>
        </div>
      </div>
    </div>
    </Grid>
  </Grid>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  linkPointer: {
    cursor: "pointer"
  },
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(Services));