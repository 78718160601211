// @ts-nocheck
import React from "react";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import Header from "./Header.Web";
import Footer from "./Footer.Web";

// Customizable Area End

import LandingPageController, {
  Props
} from "./ContactUsController.Web";
import './Header.css'

 class ContactUs extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  changeMenu=()=>{
    this.setState({mobilemenu:!this.state.mobilemenu})
    const mobileMenu=document.getElementById("navMobileDropdown")
    mobileMenu.classList.toggle("navMobileHeight")
  }


  async componentDidMount() {
    this.getHeaderFooterContent();
    window.scrollTo(0, 0);
   }

   navigateTo = (pathName) => {
       this.props.history.push(pathName);
   }

   _contactUsMail = async () => {
    await this.supportsAPICall();
   }

   handleChangeInput = (event) => {
    this.setState({emailError: false});
    this.setState({messageError: false});
    this.setState({[event.target.name]: event.target.value})
  }

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.parentCont}
      >
        <Header />
        <Grid item xs={12} className={"navBarTopMargin"}/>
         <div className="contact-bx-size">
        <Grid item xs={12} container className={classes.rightContent}>
          <Grid item xs={12} className={classes.inputDiv}>
            <Typography className={classes.selectFilesText}>Contact PrintSEC</Typography>
          </Grid>
          <p>Sales Specialists at PrintSEC will help you start your digital journey. Reach out to us through:</p>
        <ul className="list contx">
        <a href="mailto:connect@printsec.app"><li><span><i className="fa fa-envelope" aria-hidden="true"></i></span>connect@printsec.app</li></a>
        <a href="tel:+91-9319480924"><li><span><i className="fa fa-phone" aria-hidden="true"></i></span>+91-9319480924</li></a>
        <li style={{color:'#359b76'}}><i className='fa fa-home' style={{fontSize:'20px'}}  aria-hidden="true"></i> &nbsp; F 2203, Ajnara Ambrosia, Sector 118, NOIDA, Gautam Buddha Nagar, Uttar Pradesh, 201301</li>

        <a className="contact-social-icon-align" href="https://www.instagram.com/printsecdigital/" target="_blank"><span className="contact-ico-txt"><i class="fa fa-instagram" aria-hidden="true"></i></span></a>
        <a className="contact-social-icon-align" href="https://twitter.com/PrintsecD" target="_blank"><span className="contact-ico-txt"><i class="fa fa-twitter-square" aria-hidden="true"></i></span></a>
        <a className="contact-social-icon-align" href="https://www.linkedin.com/in/printsec-digital-32b63021b/" target="_blank"><span className="contact-ico-txt"><i class="fa fa-linkedin-square" aria-hidden="true"></i></span></a>
        </ul>
        </Grid>
        </div>
        <Footer />
      </Grid>

      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  rightContent: {
    padding:"20px 20px 20px 20px",
  },
  inputDiv:{
    margin:'10px auto'
  }, 
  findPrintersBtn: {
    backgroundColor:'#32795f',
    color:'white',
    borderRadius:12,
    width:'90%',
    margin:'20px auto 30px 0',
    fontFamily:APP_FONTS.SofiaProRegular,
    height:55,
    maxWidth:300
    },
  btnCont:{
    margin:'auto',
    marginTop:'20px',
    borderRadius:12,
  },
  selectFilesText:{
    fontSize:22,
    margin:'10px auto',
    fontFamily:APP_FONTS.SofiaProRegular,
    // textAlign: 'left',
    // fontWeight:'bold',
    color:'#1e1f20'
  },
//labels
    inputLabel:{
    fontSize:14,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    color:'#1e1f20',
    letterSpacing:1,
    textTransform:'uppercase',
    margin:"10px 0"
    },

    //input fields
    emailInput:{
    border:'1px solid rgba(143, 146, 161, 0.4)',
    padding:" 5px",
    borderRadius:4,
    // opacity:"0.4",
    width:'90%',
    color:"#8f92a1",
    maxWidth:450,
    fontFamily:APP_FONTS.SofiaProRegular,
    },
    messageInput:{
    border:'1px solid rgba(143, 146, 161, 0.4)',
    padding:"5px",
    borderRadius:4,
    color:"#8f92a1",
    // opacity:"0.4",
    width:'90%',
    maxWidth:450,
    fontFamily:APP_FONTS.SofiaProRegular,
    }

});
// Customizable Area End

const UserSupportWithConfirmBox = withLoader(ContactUs)
const UserSupportWithLoader = withToast(UserSupportWithConfirmBox)
export default withRouter(withStyles(styles, { withTheme: true })(UserSupportWithLoader));