//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
import { RouterProps } from "react-router";
export const configJSON = require("./config");
import moment from "moment";

export type Props = RouterProps & {
    id: string;
    showNotification : any;
    showLoader : any;
    hideLoader : any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string,
    authToken: string,
    message: string,
    loading: boolean;
    orderId: any;
    id: string;
    qrCodeResponce: any;
    orderNumber: number;
    orderAmount: number;
    totalAmount: number;
    totalDocs: number;
    orderTime : string;
    goBack: false;
    expireTime : string;
    countDownTime : string;
    // Customizable Area End
}

interface SS {
    id: any;
    authToken: string,
}

export default class PaymentSuccessfulController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    apiGenerateQrCodeCallId: String = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            email: "",
            authToken: "",
            message: "",
            loading: false,
            orderId: '',
            id: '',
            qrCodeResponce: '',
            orderNumber: 0,
            orderAmount: 0,
            totalAmount: 0,
            totalDocs: 0,
            orderTime : "",
            goBack:false,
            expireTime : null,
            countDownTime : ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiGenerateQrCodeCallId) {
                this.setState({ loading: false })
                this.props.hideLoader();
                if (responseJson && responseJson.data && responseJson.meta.message) {
                    this.props.showNotification(responseJson.meta.message,'success')
                    const options = { year: 'numeric', month: 'long', day: 'numeric' };
                    let countDownTime = new Date(responseJson?.data?.attributes?.created_at);
                        countDownTime = countDownTime.setHours(countDownTime.getHours() + 4 ) 
                    const createtime = moment(responseJson?.data?.attributes?.created_at).format("DD/MM/yyyy hh:mm A")
                    this.setState({
                        qrCodeResponce: 'data:image/png;base64,'+responseJson.data.attributes.qrcode_b64,
                        orderNumber: responseJson.data.attributes.order_number,
                        orderAmount: responseJson.data.attributes.order_amount,
                        totalAmount: responseJson.data.attributes.total_amount,
                        totalDocs: responseJson.data.attributes.total_docs,
                        orderTime : createtime,
                        expireTime : countDownTime
                    },()=>{
                        this.showTime();  
                    })
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    this.sendAPIFailMessage();
                }

            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    showTime(){
        // setInterval(()=>{
            if(this.state.expireTime){
                var now = new Date().getTime();
                var distance = this.state.expireTime - now;
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                if(hours > 0){
                    hours = hours.toString().length > 1 ? hours : "0" + hours;
                    minutes = minutes.toString().length > 1 ? minutes : "0" + minutes; 
                    const time = hours + ":" + minutes + " hours" 
                    this.setState({countDownTime : time})    
                } else if(hours === 0){
                    const time = hours + ":" + minutes + " minutes"
                    this.setState({countDownTime : time})  
                }
            }
        // },1000)
    }

    genrateQrCodeAPICall() {
        const header = {
            "Content-Type": configJSON.contentType,
            "token": this.state.authToken
        };

        const data = {
            "order_id": Number(this.state.orderId)
        }
        const httpBody = {
            data: data
        }
        this.props.showLoader();
        this.setState({ loading: true, });
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGenerateQrCodeCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.generateQrCodesEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPiEndMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }
    // Customizable Area End
}