import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from '../../../framework/src/StorageProvider';
import {
  ToastAndroid,
  Platform
} from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  submenu: boolean,
  mobilemenu: boolean,
  header: any,
  footer: any,
  clicked: boolean,
  PublicQueueState: any,
  securePrintMenu: boolean,
  digitalMenu: boolean,
  modalOpen: boolean,
  captchaCode: any
  setCaptchaCode: any
  captchaError: boolean
  lat: any
  lng: any
  address: any
  state: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGuestLoginCallId: String = "";
  apiGetHeaderCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      submenu: false,
      mobilemenu: false,
      header: [],
      footer: [],
      clicked: false,
      PublicQueueState: "",
      securePrintMenu: false,
      digitalMenu: false,
      modalOpen: false,
      captchaCode: "",
      setCaptchaCode: "",
      captchaError: false,
      lat: null,
      lng: null,
      address: "",
      state: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      console.log("im here")
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetHeaderCallId) {
        if (responseJson && responseJson.data && responseJson.data.id) {
          //TODO: Need to uncomment this to go with the login flow
          // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.setState({ header: JSON.parse(responseJson.data.attributes.header_content) })

        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }

      }
      if (apiRequestCallId === this.apiGuestLoginCallId) {
        if (responseJson && responseJson?.data) {
          await StorageProvider.remove('login_type');
          await StorageProvider.set('login_type', 'guest_account');
          let data: any = {}
          data.token = responseJson.data.attributes.uuid;
          data.email = responseJson.data.attributes.email;
          data.name = responseJson.data.attributes.name || "Guest";
          data.state = responseJson.data.attributes.state;
          data.id = responseJson.data.id;
          data.address = responseJson.data.attributes.address;
          data.user_type = "Customer";
          // data.user_type = responseJson.data.attributes.account_type;
          await StorageProvider.remove('loginData');
          await StorageProvider.set('loginData', JSON.stringify(data));
          this.notifyMessage("Login Success");
          this.props.history.push("/UserSidebarNavigation")
        }
      }
    }
  }

  // Customizable Area Start

  notifyMessage(msg: string) {
    if (Platform.OS === 'android') {
      ToastAndroid.show(msg, ToastAndroid.SHORT)
    }
  }

  async handleGuestLogin() {
    const formData = new FormData();
    formData.append('data[attributes][latitude]', this.state.lat);
    formData.append('data[attributes][longitude]', this.state.lng);
    formData.append('data[attributes][address]', this.state.address);
    formData.append('data[attributes][state]', this.state.state);
    formData.append('data[type]', 'guest_account');

    const header = {
      "accept": configJSON.contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGuestLoginCallId = requestMessage.messageId;
    const apiEndPoint = configJSON.guestLoginApiEndPoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getHeaderContent() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };
    this.apiGetHeaderCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHeaderFooterEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  // Customizable Area End
}
