export const userLogo = require("../assets/login_logo_1.png");
export const uploadSelected = require("../assets/newPrint-Selected.svg");
export const uploadUnselected = require("../assets/newPrint.svg");
export const currentOrdersSelected = require("../assets/orderHistory-selected.svg");
export const currentOrdersUnselected = require("../assets/orderHistory.svg");
export const orderHistorySelected = require("../assets/order_history_selected.png");
export const orderHistoryUnselected = require("../assets/order_history_unselected.png");
export const getInTouchSelected = require("../assets/get_in_touch_selected.png");
export const getInTouchUnselected = require("../assets/get_in_touch_unselected.png");
export const settingsSelected = require("../assets/settings-selected.svg");
export const settingsUnselected = require("../assets/settings.svg");
export const logoutIcon = require("../assets/logout.png");
export const walletSelected = require("../assets/wallet-icon.png");
export const walletUnSelected = require("../assets/Wallet.png");
export const printIcon = require("../assets/appIcon.png");
export const invoiceSelected = require("../assets/invoice.png");
export const invoiceUnSelected = require("../assets/invoice white.png");




export const colored = require("../assets/colorIcon.png");
export const deleteImg = require("../assets/deleteIcon.png");
export const binIcon = require("../assets/bin.svg");
export const docExcel = require("../assets/docexcel.png");
export const maskIcon = require("../assets/mask.svg");
export const greyScale = require("../assets/greayColorIcon.png");
export const landScape = require("../assets/landscapeIcon.png");
export const portrait = require("../assets/potraitIcon.png");
export const settingsImg = require("../assets/settingIcon.png");
export const docWord = require("../assets/docword.png");
export const docPdf = require("../assets/pdf_placeholder.png");
export const docPowerPoint = require("../assets/docword.png");
export const docImage = require("../assets/image_placeholder.jpg");
export const closeImage = require("../assets/close@3x.png");
export const menuIcon = require("../assets/menuIcon.svg");
export const closeIcon = require("../assets/close.svg");
export const supportIcon = require("../assets/support.png");
export const supportSelectedIcon = require("../assets/support-selected.png");
export const settingsIcon = require("../assets/settings.svg");
export const settingsSelectedIcon = require("../assets/settings-selected.svg");
export const qrIcon = require("../assets/qrCode.svg");
export const qrSelectedIcon = require("../assets/qrCode-Selected.svg");
export const textFileIcon = require("../assets/textfile.png");
export const homeSelectedIcon = require("../assets/homeSelected.svg");
export const homeUnSelectedIcon = require("../assets/homeUnSelected.svg");



