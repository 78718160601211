export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const userLogo = require("../assets/user.png");
export const storeLogo = require("../assets/store.png");
export const colorLogo = require("../assets/color-logo-transparent.png");
export const groupLogo = require("../assets/groupimage.png");
export const group = require("../assets/icons-document-delivery-to-desired.svg");
export const group2 = require("../assets/icons-data-privacy.svg");
export const group3 = require("../assets/icons-document-security.svg");
export const group4 = require("../assets/icons-data-encryption.svg");
export const group5 = require("../assets/icons-secure-download-hassle-free.svg");
export const group6 = require("../assets/icons-multi-factor-authentication.svg");
export const group8 = require("../assets/group-8.png");
export const backArrowIcon = require("../assets/back-arrow-icon.svg");
