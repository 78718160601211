import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    webContent: string;
    loading: boolean;
    authToken: string;
    user_type:string;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class LicenseAgreementContoller extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    apiGetWebDataCallId: String = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ]

        this.state = {
            loading: false,
            webContent: '',
            authToken: '',
            user_type:'customer'
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiGetWebDataCallId) {
                this.setState({ loading: false })
                if (responseJson && responseJson.data && responseJson.data.id) {
                    //TODO: Need to uncomment this to go with the login flow
                    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);

                    this.openWebPage(responseJson.data.attributes.content);

                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    this.sendAPIFailMessage();
                }

            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    openWebPage(webData: string) {
        runEngine.debugLog("webData--->", webData);
        this.setState({ webContent: webData })
    }

    //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
    getPrivacyPolicyApiCall(usertype: any) {

        this.setState({ loading: true })
        const header = {
            "Content-Type": configJSON.loginApiContentType,
            "token": this.state.authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetWebDataCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.licenseAgreementAPIEndPoint+`?data[user_type]=${usertype}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiEndMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    //END --here we are validating the phone number and country code and making API call to get OTP to verify user 

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }
  // Customizable Area End
}
