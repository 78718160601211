// @ts-nocheck
import React from "react"
import { View } from "react-native";
import Select from "react-select";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

const configJSON = require("./config");

interface Props {
  navigation: any;
  style: any;
  id: string;
  disable: boolean;
  allowPropChange: boolean;
  value: string;
  isAllFlagAvailable: boolean
}

interface S {
  dataSource: any[];
  countryCodeSelected: string;
  mobileNo: string;
  token: string;
  placeHolder: string;
  disable: boolean;
  label: any;
}

interface SS { }

export default class CountryCodeSelector extends BlockComponent<Props, S, SS> {
  countryCodeApiCallId: any;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      dataSource: [],
      countryCodeSelected: "",
      mobileNo: "",
      token: "",
      placeHolder: '',
      disable: this.props.disable,
      label: null
    };
  }
  colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', width: "100%", height: 44 }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
      color: state.isSelected ? "black" : "black",
    }),
  };
  render() {
    var index = -1;
    var iIndex = -1;

    this.state.dataSource.forEach(item => {
      iIndex += 1;
      if (item.value === this.props.value) {
        index = iIndex;
        return;
      }
    });

    return (
      <div style={{
        backgroundColor: "white",
        width: 120,
        padding: 0,
        margin: 0,
        height: 50
      }}>
        <Select
          options={this.state.dataSource}
          placeholder={this.state.placeHolder}
          onChange={this.handleChange}
          value={index >= 0 ? this.state.dataSource[index] : null}
          isDisabled={this.state.disable}
          styles={this.colourStyles}
        />
      </div>
    );
  }

  handleChange(item: any) {
    this.setState({ label: item.label });
    const msg = new Message(getName(MessageEnum.CountryCodeMessage));
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), item);
    runEngine.sendMessage(getName(MessageEnum.CountryCodeMessage), msg);
  }

  async componentDidMount() {
    if (this.props.isAllFlagAvailable) {
      this.makeRemoteRequest();
    } else {
      let IndiaFlag = [{
        "id": "IN",
        "type": "country_code_and_flag",
        "attributes": {
          "name": "India",
          "emoji_flag": "🇮🇳",
          "country_code": "91"
        }
      }]
      IndiaFlag = this.countryCodesToDropDown(IndiaFlag);
      this.setState({ dataSource: IndiaFlag })
    }

  }

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: ` ${item.attributes.emoji_flag} +${item.attributes.country_code}`,
      value: item.attributes.country_code,
      countryCode: item.id
    }));
  };

  async receive(from: String, message: Message) {
    runEngine.debugLog("Country Code", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeApiCallId != null &&
      this.countryCodeApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      //
      if (responseJson && !responseJson.errors) {
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson.data)
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
CountryCodeSelector.defaultProps = { isAllFlagAvailable: true }