import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
// Customizable Area Start
// Customizable Area End

export type Props = RouterProps & {
  id: string;
  showNotification : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  authToken: string;
  todaysEarning: number;
  id: string;
  allShopsModal:boolean;
  selectedRangeTag: string;
  startDate: string;
  endDate: string;
  showDatePicker: boolean;
  selectedFrom: boolean;
  dataSource: [];
  open: boolean;
  adminPriceList: [];
  // Customizable Area End
}

interface SS {
    id: any;
}

export default class MerchantWalletController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    apiGetMyEarningCallId: string = "";
    apiGetTotalEarningCallId: string = "";
    apiGetAdminCommisionListCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ]

        this.state = {
            loading: false,
            authToken: '',
            todaysEarning: 0.0,
            id: "",
            allShopsModal:false,
            selectedRangeTag: "",
            startDate: "",
            endDate: "",
            showDatePicker: false,
            selectedFrom: false,
            dataSource: [],
            open: false,
            adminPriceList: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
      // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

          var responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          if (apiRequestCallId === this.apiGetMyEarningCallId) {
              await this.setState({ loading: false });
              if (responseJson && responseJson.data) {
                this.setState({
                  todaysEarning:responseJson.data.todays_earning
                })
              } else if (responseJson && responseJson.message) {
                setTimeout(() => {
                  this.props.showNotification(responseJson.message, "error");
                }, 200);
              }
            } else if (apiRequestCallId === this.apiGetTotalEarningCallId){
              await this.setState({ loading: false });
              if (responseJson && responseJson.data) {
              await this.setState({ dataSource: [] });
              await this.setState({ dataSource: responseJson.data });

            } else {
              //Check Error Response
              this.parseApiErrorResponse(responseJson);
              const validateUserError = JSON.stringify(responseJson?.errors)?.replace(/[\[\]"]+/g,"");
              const accountBlockValidate = responseJson.error && responseJson?.errors[0]?.message;
              if(validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
                setTimeout(() => {
                  this.props.history.push('/PhoneLoginWeb');
                }, 1000);
              }    
              this.sendAPIFailMessage();
            }
          } else if (apiRequestCallId === this.apiGetAdminCommisionListCallId){
            await this.setState({ loading: false });
            if (responseJson && responseJson.data) {
            this.setState({ adminPriceList: responseJson })
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g,"");
            const accountBlockValidate = responseJson.errors[0].message;
            if(validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
              // this.props.showNotification("Account not found", "error");
              setTimeout(() => {
                this.props.history.push('/PhoneLoginWeb');
              }, 1000);
            }    
            this.sendAPIFailMessage();
          }
        }
      }
      // Customizable Area End
    }

  // Customizable Area Start
  // Get today's earning
  async getMyEarnings() {
      const header = {
        "Content-Type": configJSON.contentType,
        token: this.state.authToken,
      };
  
      this.setState({ loading: true });
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiGetMyEarningCallId = requestMessage.messageId;
  
      let getMyEarningsAPIEndPoint = `${configJSON.getTodaysEarnings}`
        .split("merchantId")
        .join("" + this.state.id);
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getMyEarningsAPIEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPiEndMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

   // Get total earning
   async getTotalEarnings() {
     
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTotalEarningCallId = requestMessage.messageId;

    let getMyEarningsAPIEndPoint = `${configJSON.getEarnings}`
      .split("merchantId")
      .join("" + this.state.id);
      
    if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
      getMyEarningsAPIEndPoint =
        `${getMyEarningsAPIEndPoint}` +
        "?filter[time_from]=" +
        this.state.startDate +
        "&filter[time_to]=" +
        this.state.endDate;
    } else {
      getMyEarningsAPIEndPoint =
        `${getMyEarningsAPIEndPoint}`
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getMyEarningsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get admin commision price list
  
  async getAdminCommisionList() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAdminCommisionListCallId = requestMessage.messageId;

    let getAdminCommisionAPIEndPoint = configJSON.adminCommisionPrice
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAdminCommisionAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
    }
  // Customizable Area End       
}
