// @ts-nocheck
import React from "react";
import withRouter from "react-router/withRouter";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  colorLogo,
  group,
  group2,
  group3,
  group4,
  group5,
  group6,
  group8,
  userLogo,
  storeLogo,
} from "./assets";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import ForgotPasscodeControllerweb, {
  Props,
  configJSON
} from "./ForgotPasscodeController.Web";
import { APP_FONTS } from "../../../components/src/AppFonts";
import "./css/style.css";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start

// Customizable Area End
class ForgotPasscode extends ForgotPasscodeControllerweb {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      otp: "",
      currentCountryCode: "91",
      value: "Customer",
    };
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangePasscode = (otp: any) => {
    this.setState({ otp });
  }

  getOtp(otp: string) {
    this.setState({ otp });
  }

  handleAlert = (txt) => {
    this.setState({ alertMessage: txt }, () => {
      this.setState({ alertOpen: true })
    })
  }

  closeAlert = () => {
    this.setState({ alertOpen: false })
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    StorageProvider.set("user_type", newValue)
  };
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("user_type").then((user_type) => {
      if (user_type) {
        this.setState({
          value: user_type
        });
      }
    });
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { navigation, classes } = this.props;
    const { value } = this.state;
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        className="container-box"
      >
        <Grid container className="bg-auth">
          <Grid item xs={12} sm={6} className="left-side">
            <Typography className={`main-head mt-25 mb-70 ${classes.sofiaFont}`}>
              {configJSON.labelForgotPasscode}
            </Typography>
            <Typography className={`label-name ${classes.sofiaFont}`} style={{ marginTop: 20 }}>
              Forgot Passcode As
            </Typography>
            <Button onClick={(event) => {
              this.handleChange(event, "Customer");
            }}
              className={
                value === "Customer"
                  ? `auth-btn light ${classes.sofiaFont}`
                  : `auth-btn  ${classes.sofiaFont}`
              }
              variant="contained"
            >
              {configJSON.labelIamUser}
              <img src={userLogo} />
            </Button>
            <Button onClick={(event) => {
              this.handleChange(event, "Merchant");
            }}
              className={
                value === "Merchant"
                  ? `auth-btn light ${classes.sofiaFont}`
                  : `auth-btn serviceProvider  ${classes.sofiaFont}`
              }
              variant="contained"
            >
              {configJSON.labelIamShoOwner}
              <img src={storeLogo} />
            </Button>
            <Grid>
              <Typography className={`enter-your-phone ${classes.sofiaFont}`}>
                {configJSON.labelForgotPasscodeMsg}
              </Typography>
              <Grid className="phoneNumberCont">
                <Typography className={`label-name ${classes.sofiaFont}`}>
                  {configJSON.labelPhoneNumber}
                </Typography>
                <div className="login_number_cont logn">
                  <CountryCodeSelector
                    allowPropChange={true}
                    isAllFlagAvailable={true}
                    disable={true}
                    className={classes.countryCode}
                    navigation={navigation}    //Merge Engine::From BDS
                    id={"CountryCodeSelector"} //Merge Engine::From BDS
                    placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
                    disable={false}  //Merge Engine::From BDS
                    value={this.state.currentCountryCode} //Merge Engine::From BDS
                  />
                  <div className="log-num">
                    <TextField
                      type="number"
                      variant="outlined"
                      className={`number-field ${classes.sofiaFont}`}
                      maxLength={10}
                      required
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                      }}
                      value={this.state.phoneNumber}
                      onChange={(e) => {
                        this.onChangePhoneNumber(e)
                        this.setState({ phoneNumberError: false })
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <p className={`error-label ${classes.sofiaFont}`}>
                {this.state.phoneNumberError}
                {this.state.errorMessage && this.state.errorMessage.otp}
              </p>
              <Grid className="btn-box-forgotPassCode" onClick={() => this.validatePhoneAndGetOtpCall()}>
                <Button variant="contained" style={{ height: '42px' }} className={`site-lg-btn ${classes.sofiaFont}`}>
                  {configJSON.labelbtnNext}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className="right-side">
            <img src={colorLogo} onClick={() => this.props.history.push("/")} className="site-logo" />
            <Grid className="right-icons mt-70 log-ico">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
                <img src={group3} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
                <img src={group2} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
                <img src={group6} className={"right-alert-img"} />
              </div>
            </Grid>
            <Grid className="right-icons">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
                <img src={group4} className={"right-alert-img "} />
              </div>
              <div className={"icon_cont "}>
                <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
                <img src={group5} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
                <img src={group} className={"right-alert-img"} />
              </div>
            </Grid>
            <img src={group8} className="group-logo bt-80" />
          </Grid>
        </Grid>
        <Dialog
          open={this.state.alertOpen}
          // open={true}
          onClose={this.closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" >
            <span style={{ fontFamily: APP_FONTS.SofiaProRegular }}>Alert!!!</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontFamily: APP_FONTS.SofiaProRegular }}>
              {this.state.alertMessage}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{ fontFamily: APP_FONTS.SofiaProRegular }}
                onClick={this.closeAlert}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Grid>
    )
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  sofiaFont: {
    fontFamily: APP_FONTS.SofiaProRegular
  }
})
// Customizable Area End
const ForgotPasscodeWithLoader = withLoader(ForgotPasscode)
const ForgotPasscodeWithToast = withToast(ForgotPasscodeWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(ForgotPasscodeWithToast));
