//@ts-nocheck
import React from 'react';
import { APP_FONTS } from '../../../components/src/AppFonts';

import ShopDashboardController, {
  Props
} from "./ShopDashboardController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withRouter from "react-router/withRouter";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router/Redirect";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";

import MerchantDashboradHome   from "./MerchantDashboradHome.Web";
import UserSupport from "../../user-profile-basic/src/UserSupport.Web";
import StorageProvider from "../../../framework/src/StorageProvider";
import ScannedOrderDetails from "../../OrderManagement/src/ScannedOrderDetails.Web";
import MerchantWallet from "../../user-profile-basic/src/MerchantWallet.Web";
// Customizable Area Start
import MerchantInvoicesWeb from './MerchantInvoices.web';
import MerchantInvoicesDownloadWeb from './MerchantInvoicesDownload.web';
import SuccessFullPaymentPageWeb from './SuccessFullPaymentPage.Web';
import FailedPaymentPageWeb from './FailedPaymentPage.web';
// Customizable Area End
class ShopDashboard extends ShopDashboardController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          profileImagePath: loginData.profile_image_path,
        });
      }
     });
     await StorageProvider.get("businessDetailsData").then((res) => {
      if (res) {
        let businessDetailsData = JSON.parse(res);
        this.setState({
          username: businessDetailsData.shop_name,
        });
      }
     });
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
       return (
        <Grid container item xs={12} className={classes.rightSideCont}>
          <Grid container className={classes.totalCont}>
            <Grid item xs={12} className={classes.navBar}>
              <Typography 
                style={{ fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "sofiaPro"
                }}> 
                {this.state.username}
              </Typography>
              <span className={classes.navSpan}>
                {this.state.profileImagePath ? (
                  <img
                    src={this.state.profileImagePath}
                    className={classes.navSpanImg}
                    style={{height:'35px !important'}}
                  />
                ) : (
                  <img
                    src={require("../assets/profile_icon.png")}
                    // className={classes.navSpanImg}
                    style={{height:'40px'}}
                  />
                )}
              </span>
            </Grid>
            <Switch>
            <PrivateRoute exact role="Merchant" path={`/MerchantSidebarNavigation/DashBoard/Home`}>
                <MerchantDashboradHome />
            </PrivateRoute>
            <PrivateRoute exact role="Merchant" path={`/MerchantSidebarNavigation/DashBoard/OrderDetail`}>
              <ScannedOrderDetails />
            </PrivateRoute>
            <PrivateRoute  exact role="Merchant" path={`/MerchantSupport/Support/Support`}>
              <UserSupport />
            </PrivateRoute>
            <PrivateRoute  exact role="Merchant" path={`/MerchantWallet/Wallet`}>
              <MerchantWallet />
            </PrivateRoute>
            <PrivateRoute  exact role="Merchant" path={`/MerchantInvoice/Invoice`}>
              <MerchantInvoicesWeb />
            </PrivateRoute>
            <PrivateRoute exact role="Merchant" path={`/MerchantInvoice/Download`}>
              <MerchantInvoicesDownloadWeb />
            </PrivateRoute>
            <PrivateRoute exact role="Merchant" path={`/MerchantInvoice/SuccessPage`}>
              <SuccessFullPaymentPageWeb />
            </PrivateRoute>
            <PrivateRoute exact role="Merchant" path={`/MerchantInvoice/FailedPage`}>
              <FailedPaymentPageWeb />
            </PrivateRoute>
            <Redirect exact from={'/MerchantSupport/Support'} to={'/MerchantSupport/Support/Support'}></Redirect>
            <Redirect exact from={'/MerchantSidebarNavigation/DashBoard'} to={'/MerchantSidebarNavigation/DashBoard/Home'}></Redirect>
            </Switch>
          </Grid>
        </Grid>)
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    rightSideCont: {
      // background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 0px",
    },
    navSpan: {
      height: '45px',
      width: '45px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: '14px',
      marginLeft: '15px',
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
  });
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(ShopDashboard));


