export const APP_COLORS = {
    white: '#fff',
    black: '#000000',
    app_theme_green_color:'#32795f',
    app_theme_yellow_color:'#f8c811',
    disabled_color:'#7ea999',
    light_grey:'#8f92a1',
    label_color:'#1e1f20',
    completed_order:'#77c285',
    cancelled_order:'#FF0000',
  }