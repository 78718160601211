// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  colorLogo,
  group,
  group2,
  group3,
  group4,
  group5,
  group6,
  group8
} from "./assets";
// Customizable Area End
import OTPInputView from "../../../components/src/OTPInputView";
import StorageProvider from "../../../framework/src/StorageProvider";
import VerifyOtpController, {
  Props,
  configJSON
} from "./VerifyOtpController.Web";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import "./css/VerifyOtp.css";
// Customizable Area End
class VerifyOtp extends VerifyOtpController {
  // Customizable Area Start
  timer = null;
  otpReference = React.createRef(null);
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getOtp(OTP: string) {
    this.setState({ verifyOtpError: false });
    this.setState({ OTP });
  }

  async componentWillUnmount() {
    clearInterval(this.state.timer);
  }
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    this.timer = setInterval(this.manageTimer, 1000);

    StorageProvider.get("signUpData").then(res => {
      const verifyToken = JSON.parse(res);
      if (verifyToken) {
        this.setState({
          token: verifyToken.token,
          fullPhoneNumber: verifyToken.phone,
          email: verifyToken.email,
          passcode: verifyToken.passcode,
          customerType: verifyToken.user_type,
          mobileNo: verifyToken.mobile,
          countryCode: verifyToken.countryCode
        });
      }
    });
    // Customizable Area End
  }

  // Customizable Area Start
  manageTimer = () => {
    var states = this.state;
    if (states.counter === 0) {
      clearInterval(this.timer);
    } else {
      this.setState({
        counter: this.state.counter - 1
      });
    }
  };

  setToken(token: string) {
    this.setState({ token: token });
  }

  setFullPhoneNumber(fullPhoneNumber: string) {
    this.setState({ fullPhoneNumber: fullPhoneNumber });
  }
  reDirectToLogin = () => {
    this.props.history.push("PhoneLoginWeb");
  };
  resetAndResendOTP() {
    if (this.otpReference.current) {
      this.otpReference.current.resetOTP();
    }
  }
  reset = () => {
    this.resendOTP();
  };

  handleAlert = txt => {
    this.setState({ alertMessage: txt }, () => {
      this.setState({ alertOpen: true });
    });
  };

  closeAlert = () => {
    this.setState({ alertOpen: false });
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    if (this.state.restartTimer) {
      this.setState({
        counter: 120
      });
      this.timer = setInterval(this.manageTimer, 1000);
      this.setState({
        restartTimer: false
      });
    }
    return (
      <Grid container direction="row" justifyContent="center">
        <Grid className={"background"}>
          <Grid item xs={12} sm={6} className={"leftSide"}>
            <Typography className={"signup"}>
              {configJSON.signUpLabel}
            </Typography>
            <Typography className={"VerifyOTP"}>
              {configJSON.labelVerifyOtp}
            </Typography>
            <Grid>
              <Typography className={"weHaveSentAnOne"}>
                {this.labelVerifyOtpMessage +
                  "+" +
                  this.state.countryCode +
                  " " +
                  this.state.mobileNo}
              </Typography>
              <Typography className={"didntGetTheOTP"}>
                {configJSON.labelDidntGotOtp}
              </Typography>
              {this.state.counter === 0 ? null : (
                <div className={"didntGetTheOTP"}>
                  <Typography
                    className={"didntGetTheOTP"}
                    style={{ textDecoration: "underline" }}
                  >
                    Resend In 0{Math.floor(this.state.counter / 60)}:
                    {(this.state.counter % 60).toString().length > 1
                      ? this.state.counter % 60
                      : "0" + (this.state.counter % 60)}
                  </Typography>
                </div>
              )}
              {this.state.counter === 0 ? (
                <div className={"didntGetTheOTP"}>
                  <Typography
                    testID={"resendButton"}
                    className={"didntGetTheOTP"}
                    onClick={() => this.resendOTP()}
                    style={{ textDecoration: "underline" }}
                  >
                    Resend OTP
                  </Typography>
                </div>
              ) : null}
            </Grid>
            <Typography className={"OTP"}>{configJSON.labelOtp}</Typography>

            <Grid className="otp-input-box">
              <OTPInputView
                className="change-passcode"
                reset={this.reset}
                ref={this.otpReference}
                getOtp={(otp: string) => this.getOtp(otp)}
              />
            </Grid>
            <p className={"errorLabel"}>
              {this.state.verifyOtpError}
              {this.state.validateApiError}
              {this.state.errorMessage && this.state.errorMessage.pin}
              {this.state.errorMessage && this.state.errorMessage.phone}
            </p>
            <Button
              variant="contained"
              className={"signUpButton"}
              onClick={() => this.validateOTPAndVerifyMobileCall()}
            >
              <p style={{ margin: "0px" }} className={"signUpButtonTitle"}>
                {configJSON.btnNextTitle}
              </p>
            </Button>
            <p className={"alreadyHaveAnAcco"}>
              {configJSON.alreadyHaveAccount}
              <Typography
                testID={"signupButton"}
                className={"didntGetTheOTP"}
                onClick={() => this.reDirectToLogin()}
                style={{ textDecoration: "underline" }}
              >
                Log In
              </Typography>
            </p>
          </Grid>
          <Grid item xs={12} sm={6} className="right-side">
            <img
              src={colorLogo}
              onClick={() => this.props.history.push("/")}
              className="site-logo"
            />
            <Grid className="right-icons " style={{ marginTop: "60px" }}>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
                <img src={group3} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
                <img src={group2} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
                <img src={group6} className={"right-alert-img"} />
              </div>
            </Grid>
            <Grid className="right-icons">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
                <img src={group4} className={"right-alert-img "} />
              </div>
              <div className={"icon_cont "}>
                <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
                <img src={group5} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
                <img src={group} className={"right-alert-img"} />
              </div>
            </Grid>
            <img src={group8} className="group-logo bt-80" />
          </Grid>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
const VerifyOtpWithLoader = withLoader(VerifyOtp);
const VerifyOtpWithToast = withToast(VerifyOtpWithLoader);
export { VerifyOtp };
export default withRouter(VerifyOtpWithToast);
