// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import PrivacyPolicyController, {
    Props
} from "./PrivacyPolicyController.Web";
import withRouter from "react-router/withRouter";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// Customizable Area Start
// Customizable Area End
class PrivacyPolicyBlock extends PrivacyPolicyController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    async componentDidMount() {
        // Customizable Area Start
        await StorageProvider.get('loginData').then((res) => {
            const loginData = JSON.parse(res);
            if (loginData) {
                this.setState({
                    authToken: loginData.token
                });
            }
        });
      let { location } = this.props;
      const path = location.pathname.toString();
      if (path.includes("MerchantSettings")) {
        await this.getPrivacyPolicyApiCall("merchant");
      }
      else {
        await this.getPrivacyPolicyApiCall("customer");
      }
      // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const _this = this;
        return (
            //Merge Engine DefaultContainer
          <Grid container style={{width:"100%",fontFamilty:APP_FONTS.SofiaProRegular,}}>
            {/*{renderHTML(this.state.webContent)}*/}
            <div className="privacyHead">
        <div className={classes.parentPrivacy} style={{padding: '10px'}}>
        <h3 className={classes.privacyHeading}>Privacy Policy</h3>
        <p className={classes.dateContent} 
          style={{ marginLeft: '38%',
          width: '100%',
          position: 'absolute',
          marginTop: '-25px'}}
        >
            Effective Date:  28-Aug-2021</p>  
        </div>
        </div>
        <div>
        <div className={`${classes.paraFont} html-content-privacypolicy`} dangerouslySetInnerHTML={{ __html: this.state.webContent }} />
        </div>
          </Grid>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    paraFont:{
      fontSize:16,
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign:"center",
      margin:"20px",
      color:"#1e1f20"
    }
  });
// Customizable Area End
export default withRouter(
  withStyles(styles, { withTheme: true })(withConfirmBox(PrivacyPolicyBlock))
);
