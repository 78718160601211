// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import OTPInputView from '../../../components/src/OtpInput.web';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import StorageProvider from "../../../framework/src/StorageProvider";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import {
  cameraIcon,
  profileIcon,
  deleteIcon,
  userLogo,
  locationIcon
} from "./assets";
import EditMerchantBusinessDetailsController, {
  Props,
  configJSON,
} from "./EditMerchantBusinessDetailsController.Web";
import { colorPrintIcon, greyscaleIcon, bothIcon, fileIcon, closeIcon } from "./assets";
// Customizable Area Start
// Customizable Area End
class EditMerchantBusinessDetails extends EditMerchantBusinessDetailsController {
  // Customizable Area Start
  timer = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  _tagAllTapped = () => {
    this.setState(
      (prevState) => ({
        pageA1Available: false,
        pageA2Available: false,
        pageA3Available: false,
        pageA4Available: false,
        pageLetterAvailable: false,
        pageAllAvailable: true,
      }),
      () => {
        if (this.state.pageAllAvailable) {
          this.setState({ selectedPageSize: ["All"] });
        } else {
          const selectedPageSize = this.state.selectedPageSize.filter(
            (papertype) => {
              return papertype !== "All";
            }
          );
          this.setState({ selectedPageSize: selectedPageSize });
        }
      }
    );
  };

  _saveAndContinueTapped = () => {
    this.updateMerchantBusinsessDetailsCall(this.props.renderComp);
  };

  // this is to get current geolocation and address from geolocation
  requestLocationPermission = async () => {
    this.getOneTimeLocation();
  };

  getOneTimeLocation = () => {
    this.setState({ loading: true });
    this.props.showLoader();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLongitude = JSON.stringify(position.coords.longitude);
        const currentLatitude = JSON.stringify(position.coords.latitude);
        this.setState(
          {
            lat: currentLatitude,
            lng: currentLongitude,
          },
          () => {
            this.getAddress(currentLatitude, currentLongitude);
          }
        );
      },
      (error) => {
        this.setState({ loading: false });
        this.props.hideLoader();
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  };

  getAddress = (lat: any, lng: any) => {
    this.props.showLoader();
    var request = new XMLHttpRequest();
    var method = "GET";
    var url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&sensor=true&key=AIzaSyBI5xkmKUlPoVouVxtAY2prU4ghXF5V0kc";
    var async = true;
    request.open(method, url, async);
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        var data = JSON.parse(request.responseText);
        this.props.hideLoader();
        var address = data.results[0].formatted_address || "";
        this.setState({ shopAddress: address });
      }
    };
    request.send();
  };
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    this.timer = setInterval(this.manageTimer, 1000);
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        id: loginData.id,
        authToken: loginData.token,
        profileImagePath: loginData.profile_image_path,
        email: loginData.email
      });
    }
    this.getPaperTypeApiCall()
    if (this.props.mode === "edit") {
      const businessDetailsData = await StorageProvider.get(
        "businessDetailsData"
      );
      if (businessDetailsData) {
        const businessDetails = JSON.parse(businessDetailsData);
        this.setState(
          {
            shopName: businessDetails.shop_name,
            ownersName: businessDetails.owner_name,
            shopAddress: businessDetails.shop_address,
            gstNumber: businessDetails.gst_number,
            bhimUPI: businessDetails.bhim_upi_number,
            email: businessDetails.email,
            lat: businessDetails.lat,
            lng: businessDetails.lng,
            pagesAvailables: businessDetails.pages,
            printColors: businessDetails.print_colours.map(x => {
              return x.toLowerCase()
            }),
            selectedPaperType: businessDetails.paper_types
          },
          () => {
            this.getSelectedPages();
            this.getSelectedColor();
            if (businessDetails.pages?.length === 5) {
              this.handleLatterType('All')
            }
          }
        );
      }
    } else if (this.props.mode === "create") {
      await this.requestLocationPermission();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEmailVerification = () => {
    this.sendEmailOtp()
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getSelectedPages() {
    this.state.pagesAvailables.forEach((element) => {
      if (element == "A1") {
        this.setState({ pageA1Available: true }, () => {
          this.setSelectedPageSize(this.state.pageA1Available, 'A1')
        });
      } else if (element == "A2") {
        this.setState({ pageA2Available: true }, () => {
          this.setSelectedPageSize(this.state.pageA2Available, 'A2')
        });
      } else if (element == "A3") {
        this.setState({ pageA3Available: true }, () => {
          this.setSelectedPageSize(this.state.pageA3Available, 'A3')
        });
      } else if (element == "A4") {
        this.setState({ pageA4Available: true }, () => {
          this.setSelectedPageSize(this.state.pageA4Available, 'A4')
        });
      } else if (element == "Letter") {
        this.setState({ pageLetterAvailable: true }, () => {
          this.setSelectedPageSize(this.state.pageLetterAvailable, 'Letter')
        });
      } else if (element == "All") {
        this._tagAllTapped();
      }
    });
  }
  setSelectedPageSize(isAvailable, paperSize) {
    if (isAvailable) {
      this.setState((prevState) => ({
        selectedPageSize: [...prevState.selectedPageSize.filter(
          (papertype) => {
            return papertype !== "All";
          }
        ), paperSize],
      }));
    } else {
      this.setState((prevState) => ({
        selectedPageSize: prevState.selectedPageSize.filter(
          (papertype) => {
            return papertype !== paperSize;
          }
        )
      }));
    }
  }
  getSelectedColor() {
    if (this.state.printColors.length === 1) {
      this.state.printColors.forEach((element) => {
        if (element == "colored") {
          this.setState({
            selectedPrintColor: element,
            bothPrintAvailable: false,
          });
        } else if (element == "greyscale") {
          this.setState({
            selectedPrintColor: element,
            bothPrintAvailable: false,
          });
        }
      });
    }
    if (this.state.printColors.length === 2) {
      this.setState({
        selectedPrintColor: null,
        bothPrintAvailable: true,
      });
    }
  }
  handleLatterType = (paperSize) => {
    if (paperSize == "A1") {
      this.setState(
        (prevState) => ({
          pageA1Available: !prevState.pageA1Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA1Available, paperSize)
        }
      );
    } else if (paperSize == "A2") {
      this.setState(
        (prevState) => ({
          pageA2Available: !prevState.pageA2Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA2Available, paperSize)
        }
      );
    } else if (paperSize == "A3") {
      this.setState(
        (prevState) => ({
          pageA3Available: !prevState.pageA3Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA3Available, paperSize)
        }
      );
    } else if (paperSize == "A4") {
      this.setState(
        (prevState) => ({
          pageA4Available: !prevState.pageA4Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA4Available, paperSize)
        }
      );
    } else if (paperSize == "Letter") {
      this.setState(
        (prevState) => ({
          pageLetterAvailable: !prevState.pageLetterAvailable,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageLetterAvailable, paperSize)
        }
      );
    } else if (paperSize == "All") {
      this._tagAllTapped();
    }
  };

  setSelectedPaperType(paperType) {
    if (paperType === 'All') {
      this.setState({ selectedPaperType: ['All'] })
    } else {
      const selectedPageType = this.state.selectedPaperType.filter(
        (papertype) => {
          return papertype !== "All";
        }
      );
      this.setState({ selectedPaperType: selectedPageType });
      if (this.state.selectedPaperType.includes(paperType) === true) {
        let selectedArrayList;
        selectedArrayList = this.state.selectedPaperType.filter((ele) => ele !== paperType);
        this.setState({ selectedPaperType: selectedArrayList })
      } else {

        this.setState((prevState) => ({
          selectedPaperType: [...prevState.selectedPaperType, paperType]
        }))
      }
    }
  }

  handleColorType = (color) => {
    if (color === "Color") {
      this.setState({
        selectedPrintColor: "colored",
        bothPrintAvailable: false,
      });
    } else if (color === "greyscale") {
      this.setState({
        selectedPrintColor: "greyscale",
        bothPrintAvailable: false,
      });
    } else if (color === "Both") {
      this.setState({
        selectedPrintColor: null,
        bothPrintAvailable: true,
      });
    }
  };

  _onProfileImageDelete = () => {
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === 'Yes') {
        this.handleDeleteImage();
      }
    }
    this.props.openDialog('Remove profile picture', 'Are you sure want to delete profile image', onClose);
  };

  validateImageFile = (fileExtension) => {
    var allowedExtension = ['jpeg', 'jpg', 'png', 'bmp', 'webp'];
    var isValidFile = true;

    for (var index in allowedExtension) {
      if (fileExtension.includes(allowedExtension[index])) {
        isValidFile = false;
        break;
      }
    }

    return isValidFile
  }

  handleImageChange = (event) => {
    if (this.validateImageFile(event.target.files[0].type)) {
      this.props.showNotification('Add the valid image file', 'error');
      return
    }

    this.setState({
      imageUri: event?.target?.files[0],
      fileName: event?.target?.files[0]?.name,
      fileType: event?.target?.files[0]?.type,
      file: event?.target?.files,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        filePath: reader?.result,
      });
    };
    reader.readAsDataURL(event?.target?.files[0]);
    event.target.value = '';
  };

  handleDeleteImage = () => {
    if (this.state.profileImagePath) {
      this.deleteProfileImageApiCall(this.props.renderComp);
    } else if (this.state.imageUri) {
      this.deleteProfileImageApiCall(this.props.renderComp);
    }
  };
  async componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  getOtp(OTP: string) {
    this.setState({ OTP });
  }
  manageTimer = () => {
    if (this.state.counter > 0) {
      this.setState(({ counter }) => ({
        counter: counter - 1
      }))
    }
    if (this.state.counter === 0) {
      clearInterval(this.timer)
    }
  }
  formatNumber = (number) => {
    return `${number.substring(0, 2)} ${number.substring(2, number.length)}`
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    if (this.state.restartTimer) {
      this.setState({
        counter: 120
      });
      this.timer = setInterval(this.manageTimer, 1000);
      this.setState({
        restartTimer: false
      });
    }
    const { classes } = this.props;
    const { mode } = this.props;
    let image = <img src={profileIcon} style={{ cursor: "pointer" }} className={classes.profileImage} />;
    let isImageUpdated = image;
    if (this.state.profileImagePath) {
      image = <img
        src={this.state.profileImagePath}
        className={classes.profileImage}
      />
    } else if (this.state.filePath) {
      image = <img src={this.state.filePath} className={classes.profileImage} />
    }
    isImageUpdated = isImageUpdated === image;

    return (
      //Merge Engine DefaultContainer
      <Grid container direction="column" className={classes.parentCont}>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            {mode === "create" ? (
              <>
                <Typography variant="body1" className={classes.sofiaPro} style={{ fontSize: '16px' }}>Before We Continue</Typography>
                <Typography variant="body2" className={classes.sofiaPro} style={{ fontSize: '16px' }}>
                  Add Business Detail(1/2)
                </Typography>
                <Typography variant="subtitle1" className={classes.sofiaPro} style={{ fontSize: '16px' }}>Basic Information</Typography>
              </>
            ) : (
              <Typography variant="subtitle1" className={classes.sofiaPro} style={{ fontSize: 22 }}>Edit Business Details</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {mode === "create" &&
              <img src={userLogo} style={{ marginLeft: "7%" }} />
            }
          </Grid>
        </Grid>
        {mode === "edit" &&
          <Grid
            xs={12}
            sm={6}
            item
            container
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            className={classes.textMargin}
          >
            <div className={classes.tabImgContentLeft}>
              <Grid style={{ margin: "auto 15px" }}>
                <div className={classes.inputContainer}>
                  {/* <input
                  type="file"
                  accept="image/*"
                  onChange={this.handleImageChange}
                  className={classes.cameraIcon}
                />
                <img src={cameraIcon}
                     onChange={this.handleImageChange}
                /> */}
                  <label className="btn-upload">
                    <input type='file' accept="image/*" placeholder="Upload Image" onChange={this.handleImageChange} />
                    <img src={cameraIcon} onChange={this.handleImageChange} />
                  </label>

                </div>
              </Grid>
              <Grid style={{ margin: "auto 15px" }}>
                {
                  image
                }
              </Grid>
              <Grid style={{ margin: "auto 15px" }}>
                <div>
                  <img
                    src={deleteIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => !isImageUpdated && this._onProfileImageDelete()}
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        }
        <Grid item xs={12} container>
          <Grid item xs={12} sm={6}>
            <div className={classes.tabContentLeft}>
              <Typography className={classes.inputLabel}>SHOP NAME <span className={classes.mandatoryMark}>*</span></Typography>
              <InputBase
                placeholder="Enter Shop Name"
                type="text"
                className={classes.inputTextBox}
                name="shopName"
                onChange={this.handleInputChange}
                value={this.state.shopName}
              />
              <Typography className={classes.inputLabel}>
                OWNER'S NAME <span className={classes.mandatoryMark}>*</span>
              </Typography>
              <InputBase
                placeholder="Enter Owner Name"
                type="text"
                className={classes.inputTextBox}
                name="ownersName"
                onChange={this.handleInputChange}
                value={this.state.ownersName}
              />
              <Typography className={classes.inputLabel}>
                SHOP ADDRESS <span className={classes.mandatoryMark}>*</span>
              </Typography>
              <InputBase
                type="text"
                placeholder="Enter Shop Address"
                name="shopAddress"
                className={classes.inputTextBox}
                onChange={(e) => this.handleInputChange(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={locationIcon} alt="" style={{ cursor: 'pointer' }} onClick={() => this.getOneTimeLocation()} />
                  </InputAdornment>
                }
                value={this.state.shopAddress}
              />
              <Typography className={classes.inputLabel}>GST Number</Typography>
              <InputBase
                type="text"
                placeholder="Enter GST Number"
                name="gstNumber"
                className={classes.inputTextBox}
                onChange={this.handleInputChange}
                value={this.state.gstNumber}
              />
              <Typography className={classes.inputLabel}>EMAIL <span className={classes.mandatoryMark}>*</span></Typography>
              <InputBase
                type="text"
                placeholder={this.state.email}
                name="email"
                className={classes.inputTextBox}
                onChange={this.handleInputChange}
                value={this.state.email}
                disabled={mode === "create" ? false : this.state.emailInputDisable}
              />
              {mode !== "create" ?
                <div style={{ textAlign: "right", marginTop: "10px", color: "rgb(50, 121, 95)" }}><span style={{ cursor: "pointer" }} onClick={() => this.handleEmailVerification()}>Verify and change</span></div>
                :
                null
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: 30 }}>
            <div className={classes.tabContentLeft}>
              <Typography className={classes.basicInfo}>
                {configJSON.labelPrintInformation}
              </Typography>
              <Typography className={classes.inputLabel}>
                {configJSON.labelPrintTypeAvailabilty}
              </Typography>
              <div className={classes.printTypeCont}>
                <span
                  onClick={() => {
                    this.handleColorType("Color");
                  }}
                  className={classes.spanPrintTypeImg}
                  style={{
                    marginTop: 15,
                    color:
                      this.state.selectedPrintColor === "colored" && !this.state.bothPrintAvailable
                        ? "#32795f"
                        : "#1e1f20",
                    // border : this.state.selectedPrintColor === "colored" && !this.state.bothPrintAvailable
                    // ? '2px solid #32795f'
                    // : "none"
                  }}
                >
                  <img
                    src={colorPrintIcon}
                    alt=""
                    style={{
                      padding: "1px",
                      border: this.state.selectedPrintColor === "colored" && !this.state.bothPrintAvailable
                        ? '2px solid #32795f'
                        : '2px solid transparent',
                      borderRadius: "50%"
                    }}
                    className={classes.colorCopy}
                  />
                  Colored
                </span>
                <span
                  onClick={() => {
                    this.handleColorType("greyscale");
                  }}
                  className={classes.spanPrintTypeImg}
                  style={{
                    marginTop: 15,
                    color:
                      this.state.selectedPrintColor === "greyscale" && !this.state.bothPrintAvailable
                        ? "#32795f"
                        : "#1e1f20",
                    // border : this.state.selectedPrintColor === "greyscale" && !this.state.bothPrintAvailable
                    // ? '2px solid #32795f'
                    // : "none"
                  }}
                >
                  <img
                    src={greyscaleIcon}
                    alt=""
                    style={{
                      padding: "1px",
                      border: this.state.selectedPrintColor === "greyscale" && !this.state.bothPrintAvailable
                        ? '2px solid #32795f'
                        : '2px solid transparent',
                      borderRadius: "50%"
                    }}
                    className={classes.colorCopy}
                  />
                  Greyscale
                </span>
                <Button
                  className={classes.timeSelectBtn}
                  onClick={() => {
                    this.handleColorType("Both");
                  }}
                  style={{
                    marginTop: 15,
                    backgroundColor:
                      this.state.bothPrintAvailable
                        ? "#32795f"
                        : APP_COLORS.app_theme_yellow_color,
                  }}
                >
                  <img
                    src={bothIcon}
                    style={{ marginRight: 7 }}
                    alt=""
                  />
                  {configJSON.labelBoth}
                </Button>
              </div>
              <Typography
                className={classes.inputLabel}
                style={{ marginTop: 30 }}
              >
                {configJSON.labelPagesAvailable}
              </Typography>
              <div className={classes.printTypeCont}>
                {configJSON.paperSizes.map((item) => (
                  <Button
                    key={item}
                    className={classes.pageSelectBtn}
                    onClick={() => {
                      this.handleLatterType(item);
                    }}
                    style={{
                      backgroundColor: APP_COLORS.app_theme_yellow_color,
                      backgroundColor:
                        this.state.selectedPageSize.indexOf(item) > -1
                          ? "#32795f"
                          : APP_COLORS.app_theme_yellow_color,
                    }}
                  >
                    <img src={fileIcon} style={{ marginRight: 7 }} alt="" />
                    {item}
                  </Button>
                ))}
              </div>
              <Typography
                className={classes.inputLabel}
                style={{ marginTop: 30 }}
              >
                {configJSON.labelPageTypeAvailable}
              </Typography>
              <div className={classes.printTypeCont}>
                {this.state.paperTypes.map((item) => (
                  <Button
                    key={item}
                    className={classes.pageSelectBtn}
                    onClick={() => {
                      this.setSelectedPaperType(item);
                    }}
                    style={{
                      backgroundColor: APP_COLORS.app_theme_yellow_color,
                      backgroundColor:
                        this.state.selectedPaperType.includes(item)
                          ? "#32795f"
                          : APP_COLORS.app_theme_yellow_color,
                    }}
                  >
                    <img src={fileIcon} style={{ marginRight: 7 }} alt="" />
                    {item}
                  </Button>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.tabContentLeft}>
              <Button
                className={classes.saveChangesBtn}
                onClick={this._saveAndContinueTapped}
                style={{
                  backgroundColor: APP_COLORS.app_theme_green_color,
                  fontSize: '14px'
                }}
              >
                {configJSON.btnTextSaveChanges}
              </Button>
            </div>
          </Grid>
        </Grid>
        <div style={{ boxShadow: "0px 0px 30px 0px rgb(0,0,0,0.4)", textAlign: "center", display: this.state.showEmailOtpModal ? "block" : "none", padding: "30px", borderRadius: "20px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", backgroundColor: "#f7f7f7" }}>
          <Grid item xs={12} className={classes.rightBackground}>
            <Grid item xs={12} className={classes.parentContainer}>
              <Typography className={classes.checkYourSMSBoxTxt}>
                {`We have sent an OTP on your email ${this.state.email}`}
              </Typography>
              <Typography className={classes.otpTxt}>
                OTP
              </Typography>

              <OTPInputView OTPLength={4} value={this.state.OTP} className="" otpType={"number"}
                onOTPChange={(otp) =>
                  this.getOtp(otp)
                }
                secure={false} />
              <Typography className={classes.didntGetTheOTP}>
                {this.labelDidntGotOtp}
                {
                  this.state.counter == 0 ? "" : (
                    <span style={{ marginLeft: 7, textDecoration: "underline" }}>{`Resend In 0${Math.floor(this.state.counter / 60)}:${(this.state.counter % 60).toString().length > 1 ? this.state.counter % 60 : "0" + this.state.counter % 60}`}</span>
                  )
                }
                {
                  this.state.counter == 0 ? (
                    <span style={{ marginLeft: 7, textDecoration: "underline" }} onClick={() => {
                      this.sendEmailOtp()
                      this.setState({
                        restartTimer: true
                      });
                    }}>RESEND OTP</span>
                  ) : ""
                }
              </Typography>
              <Grid>
                <Typography className={classes.errorLabel}>
                  {this.state.otpError}
                </Typography>
              </Grid>

              <Button
                className={classes.saveButton}
                onClick={() => {
                  if ((this.props?.location?.state?.calledFrom === "deleteSpAccount" || this.props?.location?.state?.calledFrom === "updateUpiId" || this.props?.location?.state?.calledFrom === "updateAccount")) {
                    this.checkBothVerify()
                  } else {
                    this.checkBothVerify()
                  }

                }}
              >
                <Typography className={classes.saveButtonTxt}>{
                  (this.props?.location?.state?.calledFrom === "deleteSpAccount" || this.props?.location?.state?.calledFrom === "updateUpiId" || this.props?.location?.state?.calledFrom === "updateAccount") ?
                    "Verify And Proceed"
                    :
                    "Submit"
                }</Typography>
              </Button>
            </Grid>
          </Grid>
          <img onClick={() => this.setState({ showEmailOtpModal: false, OTP: "" })} src={closeIcon} style={{ cursor: "pointer", width: "25px", position: "absolute", top: "20px", right: "30px" }} alt="Close" />
        </div>
      </Grid>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "95%",
      margin: "auto",
    },
    parentContainer: {
      padding: "30px"
    },
    otpTxt: {
      margin: "35px 0px 15px 0px",
      fontFamily: "SofiaPro",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20"
    },
    errorLabel: {
      color: "red",
      marginTop: 15,
      fontFamily: "SofiaPro"
    },
    saveButton: {
      margin: "56px 2px 0 0",
      padding: "18px 143px 20px",
      borderRadius: "12px",
      backgroundColor: "#32795f",
      "&:hover": {
        backgroundColor: "#32795f"
      }
    },
    saveButtonTxt: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff"
    },
    didntGetTheOTP: {
      fontFamily: 'SofiaPro',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      marginTop: 25,
      letterSpacing: '-0.35px',
      color: '#565656',
      cursor: "pointer"
    },
    checkYourSMSBoxTxt: {
      fontFamily: "SofiaPro",
      fontSize: "16px",
      fonWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#2f2f30"
    },
    rightBackground: {
      borderRadius: "32px",
      backgroundColor: "#f7f7f7"
    },
    rightSideCont: {
      // height:'100%',
      background: "#f7f7f7",
      // display:'flex',
      // alignItems:'center',
      // justifyContent:'center'
    },
    totalCont: {
      width: "95%",
      margin: "auto",
    },
    tabNamesCont: {
      // background:'#ff0000',
      // height:'100%'
      paddingTop: 20,
    },
    tabContentCont: {
      background: "white",
      borderRadius: "12px 0 0 12px",
      paddingTop: 20,
      paddingBottom: 30,
      // height:200
    },
    tabContentLeft: {
      width: "85%",
      margin: "auto",
    },
    tabImgContentLeft: {
      width: "85%",
      margin: "10px auto",
      display: "flex",
      alignItems: "center",
    },
    headingCont: {
      display: "flex",
      alignItems: "center",
      marginBottom: 30,
      justifyContent: "space-between",
    },
    printTypeCont: {
      display: "flex",
      width: "100%",
      cursor: 'pointer',
      alignItems: "center",
      // justifyContent:'space-between',
      flexWrap: "wrap",
    },
    tabNameSelected: {
      background: "#32795f",
      borderRadius: 12,
      padding: "10px 10px",
      display: "flex",
      marginTop: 15,
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tabName: {
      background: "white",
      borderRadius: 12,
      padding: "10px 10px",
      display: "flex",
      marginTop: 15,
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "space-between",
    },

    //Image Styling
    headingIcon: {
      width: 50,
      height: 50,
      objectFit: "contain",
    },
    tabIcon: {
      width: 45,
      height: 45,
      objectFit: "contain",
    },
    tabNextIcon: {},

    //Text Styling
    screenHeading: {
      fontSize: 22,
      // fontWeight:'bold',
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    basicInfo: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginTop: 7,
    },
    inputLabel: {
      fontSize: 14,
      marginTop: 15,
      letterSpacing: 1,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    sofiaPro: {
      fontFamily: APP_FONTS.SofiaProRegular,
      marginLeft: 20,
      marginTop: 20
    },
    spanPrintTypeImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    tabNameTextSelected: {
      fontSize: 16,
      width: "80%",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "white",
      marginLeft: 15,
    },
    tabNameText: {
      fontSize: 16,
      width: "80%",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginLeft: 15,
    },
    tabBottomText: {
      fontSize: 16,
      width: "80%",
      cursor: "pointer",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      margin: "10px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    //Input Styling
    inputTextBox: {
      padding: "5px 10px",
      width: "100%",
      marginTop: 7,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },

    //Button styling
    timeSelectBtn: {
      color: "white",
      textTransform: "none",
      borderRadius: 4,
      marginRight: "10px",
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: '10px'
    },
    pageSelectBtn: {
      color: "white",
      textTransform: "none",
      borderRadius: 4,
      marginRight: "10px",
      marginTop: 15,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: '10px'
    },
    saveChangesBtn: {
      color: "white",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "100%",
      height: 50,
      marginRight: "10px",
      marginTop: 15,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    cameraIcon: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    inputContainer: {
      position: "relative",
    },
    profileImage: {
      height: "90px",
      width: "90px",
      objectFit: "contain"
    },
    mandatoryMark: {
      color: 'red'
    }
  });
// Customizable Area End

const EditMerchantBusinessDetailsWithConfirmBox = withConfirmBox(
  EditMerchantBusinessDetails
);
const EditMerchantBusinessDetailsWithLoader = withLoader(
  EditMerchantBusinessDetailsWithConfirmBox
);
const EditMerchantBusinessDetailsWithToast = withToast(
  EditMerchantBusinessDetailsWithLoader
);
export default withRouter(
  withStyles(styles, { withTheme: true })(EditMerchantBusinessDetailsWithToast)
);
