//@ts-nocheck
import React from "react";
// Customizable Area Start

// Customizable Area End

import UserOrderDetailsController, {
  Props
} from "./OrderDetailsController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { textFileIcon } from './assets'
import {
  docExcel,
  docImage,
  docWord,
  docPdf,
  docPowerPoint,
} from "../../UserDashBoard/src/assets";
import moment from "moment";
import InputBase from '@material-ui/core/InputBase';

// import Moment from 'moment';
class OrderDetails extends UserOrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  _findNearByBttonTapped = async () => {
    let data: any = {};
    data.show_pay_button = false;
    await StorageProvider.remove("showPay");
    await StorageProvider.set("showPay", JSON.stringify(data));
    this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectShop");
  };
  // Customizable Area End

  async componentDidMount() {
    //Customizable Area Start
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        authToken: loginData.token,
        isTwoFactorEnabled: loginData.is_two_step_verification
      });
    }
    let orderIdDetails = await StorageProvider.get("orderIdDetails");
    if (orderIdDetails) {
      orderIdDetails = JSON.parse(orderIdDetails);
      this.setState({
        orderId: orderIdDetails.id,
        from: orderIdDetails.from,
        phoneNumber: orderIdDetails.attributes.customer_details.phone_number,
        orderNumber: orderIdDetails.attributes.order_number
      }, () => {
        this.getOrderDetailsApiCall();
      });
      await this.getExpiryTime()
      const MINUTE_MS = 1000;  
      const interval = setInterval(() => {
        if (this.state.isOrderDetailsResponse && (this.state.resData.status === "Pending" || this.state.resData.status === "Paymentcompleted" || this.state.resData.status === "Printing" )) {          
          this.getOrderDetailsApiCall();
        }
      }, MINUTE_MS);
      this.setState({ intervalId: interval });
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getExpiryDateOfDoc = (createdAt: any) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    let countDownTime = new Date(createdAt);
    countDownTime = countDownTime.setHours(countDownTime.getHours() + this.state.expiryTime);
    let now = new Date().getTime();
    let distance = countDownTime - now;

    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    if (hours > 0) {
      hours = hours.toString().length > 1 ? hours : "0" + hours;
      minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
      const time = 'Expiry In ' + hours + ":" + minutes + " hours";
      return time;
    } else if (hours === 0) {
      hours = hours.toString().length > 1 ? hours : "0" + hours;
      minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
      const time = 'Expiry In ' + hours + ":" + minutes + " minutes";
      return time;
    }
  };
  
  fileType(fileType: String) {
    const ImageallowedExtensions = /(jpg|jpeg|png|gif|svg)$/i;
    const pptAllowedExtension = /(ppt|pptx)$/i;
    const docAllowedExtension = /(doc|docx)$/i;
    const pdfAllowedExtension = /(pdf)$/i;
    const excelAllowedExtension = /(xlsx|xls|csv)$/i;
    
    switch (true) {
      case ImageallowedExtensions.exec(fileType):
        return docImage;
      case pptAllowedExtension.exec(fileType):
        return docPowerPoint;
      case docAllowedExtension.exec(fileType):
        return docWord;
      case pdfAllowedExtension.exec(fileType):
        return docPdf;
      case excelAllowedExtension.exec(fileType):
        return docExcel;
      default:
        return docExcel;
    }

  }
  completeOrder = (event) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === "Yes") {
        this.updateOrderStatus(true);
      }
    };
    this.props.openDialog(
      "Finish Order",
      "All Set? Are you sure want to finish this order?",
      onClose
    );
  }

  openRefundModelDialog = () => {
    this.setState({ openRefundModel: true });
  }

  closeRefundModelDialog = () => {
    this.setState({ openRefundModel: false });
  }

  handleRaiseConcern = (event) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const raiseConcernText = this.state.reasonForConcern;
    if (this.state.reasonForConcern.length > 5) {
      const onClose = (msg) => {
        if (msg === "Yes") {
          this.handleRaiseConcernSubmit(raiseConcernText);
        }
      };
      this.props.openDialog(
        "Raise Concern",
        "Are you sure want to Raise Concern for this Order?",
        onClose
      );
    } else {
      this.setState({ refundError: "Please enter valid reason" })
    }
  }

  getFileTypeImage = (file: any) => {
    let fileImage;

    switch (file) {
      case 'application/msword':
      case 'application/vnd.oasis.opendocument.text':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        fileImage = require('../assets/docword.png')
        break;

      case 'image/gif':
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
        fileImage = require('../assets/image_placeholder.jpg')
        break;

      case 'application/pdf':
        fileImage = require('../assets/pdf_placeholder.png')
        break;

      case 'text/plain':
        fileImage = textFileIcon
        break;

      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.oasis.opendocument.presentation':
        fileImage = require('../assets/ppt.png')
        break;

      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.oasis.opendocument.spreadsheet':
        fileImage = require('../assets/docexcel.png')
        break;

      default:
        fileImage = require('../assets/pdf_placeholder.png')
        break;
    }

    return fileImage;

  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    let orderStatus = ""
    if (this.state?.resData?.status != undefined) {
      switch (this.state.resData.status) {
        case 'Pending':
          orderStatus = 'Pending';
          break;
        case 'Completed':
          orderStatus = 'Completed';
          break;
        case 'Printing':
          orderStatus = 'Printing';
          break;
        case 'Printingcompleted':
          orderStatus = 'Printing Completed';
          break;
        case 'Refunded':
          orderStatus = 'Refunded';
          break;
        case 'Paymentcompleted':
          orderStatus = 'Payment Completed';
          break;
        case 'Autocancel':
          orderStatus = 'Auto Canceled';
          break;
        case 'Cancelled':
          orderStatus = 'Cancelled';
          break;
        case 'Concernraised':
          orderStatus = 'Concern Raised';
          break;
        default:
          break;
      }
    }

    return (
      <div>
        <Grid container className={classes.rightContent}>
          <Grid item xs={12}>
            <div className={classes.headingLeft}>
              <img src={require('../assets/backIcon.png')} onClick={() => {
                this.state.from === 'AllOrder' ?
                  this.props.history.push('/OrderManagement/OrderHistory') :
                  this.props.history.push('/OrderManagement/QRCode')
              }
              }
                alt="" className={classes.backIcon} />
              <Typography className={classes.pageHeader}>
                Order Detail
              </Typography>
            </div>
          </Grid>

          <Grid container className={classes.qrContainer}>
            <Grid item xs={12} sm={6} className={classes.orderDetailsCont}>
              <Typography className={classes.selectFilesText}>
                Order #{this?.state?.resData?.order_number}
              </Typography>
              <Typography className={classes.orderMerchantTxt}>
                {(("with " + this.state.resData?.merchant_details?.shop_name && this.state.resData?.merchant_details?.shop_name))}
              </Typography>
              <Typography className={classes.orderDetailsText}>
                {(moment(this.state.resData.created_at).format('DD/MM/yyyy hh:mm A'))}
              </Typography>
              <Typography className={classes.orderDetailsText}>
                {orderStatus}
              </Typography>
              <Typography className={classes.orderDetailsText}>
                {this.state.resData.status === 'Pending' && this.getExpiryDateOfDoc(this.state.resData.created_at)}
              </Typography>
              {/* } */}
              <Typography className={classes.orderDetailsText}>
                {this?.state?.resData?.total_amount > 0 && `Total Paid: Rs.${parseFloat(this?.state?.resData?.total_amount.toString()).toFixed(2)}`}
              </Typography>
              {orderStatus !== 'Autocancel' && orderStatus !== 'Completed' && <Typography className={classes.orderDetailsText}>
                {this?.state?.resData.total_docs ? `${this?.state?.resData.total_docs} Documents` : this?.state?.resData.total_docs === 1 ? '1 Document' : null}
              </Typography>
              }
            </Grid>
            <Grid item xs={12} sm={6} className={classes.contentLeft}>
              {(orderStatus === 'Pending') ?
                <>
                  <img
                    src={'data:image/png;base64,' + this.state?.resData?.qrcode_b64}
                    alt="QR Code"
                    className={classes.barCodeImg}
                  />
                  <Typography className={classes.scanCode}>
                    Scan this QR code at any printer shop from list or shop of your
                    choice to get your printout
                  </Typography>
                  { this.state.isTwoFactorEnabled && <Typography className={classes.scanCode}>
                      Make sure you have your mobile with you as you have opted for 
                      Two factor authentication for printing the documents and an OTP 
                      will be sent to your registered phone number at the time of 
                      scanning by Printing shops
                    </Typography> }
                </>
                : ""}
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.docsCont}>
            {
              this.state.resData.status === 'Autocancel'
              && <Grid item xs={12}>
                <Typography className={classes.scanCode}>
                  Order is autocancel so all the documents has been removed
                </Typography>
              </Grid>

            }
            {
              this.state.resData.status === 'Printingcompleted'
              && <Grid item xs={12}>
                <Typography className={classes.scanCode}>
                  Printing has been completed,
                  <br />
                  Tap on finish order to complete the order
                </Typography>
              </Grid>

            }
            {
              this.state.resData.status !== 'Printingcompleted' && this.state.resData.status !== 'Autocancel' && this.state.resData.status !== 'Pending'
              && this.state.resData.status !== 'Paymentcompleted'
              && <Grid item xs={12}>
                <Typography className={classes.scanCode}>
                  {`No files are visible because order is ${orderStatus}`}
                </Typography>
              </Grid>

            }

            {(orderStatus === 'Pending for Payment' || orderStatus === 'Payment Completed') && this.state.dataSource.map((file) => {
              return (
                <Grid key={file.id} item xs={12} sm={6}>
                  <Grid item xs={12} className={file?.colour ? classes.eachDocs : classes.eachDocsSettingNotApply}>
                    <img src={this.getFileTypeImage(file?.content_type)} className={classes.docImg} />
                    <Typography className={classes.docTitle}>
                      {file?.file_name ? file?.file_name : ""}
                    </Typography>
                    <Typography className={classes.docContent}>
                      All Pages - {file?.colour && file?.colour}
                    </Typography>
                    <Typography className={classes.docContent}>
                      {file?.layout && file?.layout} - {file?.page_size && file?.page_size}
                    </Typography>
                    <Typography className={classes.docContent}>
                          {file.total_pages && file.total_pages} {file?.total_pages > 1 ? " Pages" : " Page"}
                        </Typography>
                    <Typography className={classes.docContent}>
                      Copies - {file?.number_of_copies && file?.number_of_copies}
                    </Typography>
                    <Typography className={classes.docContent}>
                      {file.paper_type && file.paper_type}
                    </Typography>
                  </Grid>
                </Grid>)
            })
            }
          </Grid>
          <Grid item xs={11} className={classes.btnCont} style={{ fontSize: '14px' }}>
            {this.state.resData.status == undefined ? null :
              ((this.state.resData.status == 'Pending')) ? <Button
                className={classes.findPrintersBtn}
                style={{ backgroundColor: "#32795f" }}
                onClick={() => this._findNearByBttonTapped()}
              >
                FIND PRINTERS NEARBY
              </Button> : null}
          </Grid>
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={this.state.openAllPrintModel}
            onClose={() => {
              this.props.history.push('/OrderManagement/OrderHistory/History');
            }}
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
            className={classes.modalParent}
          >
            <div className={classes.modalContent}>
              <div className={classes.modalHeadingCont}>
                <Typography className={classes.modalHeading}>
                  Print Order #{this?.state?.resData?.order_number}
                </Typography>
              </div>
              <div className={classes.modalContentCont}>
                <img
                  src={
                    !false
                      ? require("../assets/print.svg")
                      : require("../assets/printDocument.png")
                  }
                  alt=""
                  className={classes.modalImg}
                />
                <Typography className={classes.modalContentText}>
                  {true
                    ? "All Set"
                    : "Printing Documents"}
                </Typography>
                <Typography className={classes.modalContentText}>
                  {`Printed ${this.state.dataSource.length} Docs`}
                </Typography>
                {true ? (
                  <div>
                    <Button
                      style={{ backgroundColor: "#32795f" }}
                      className={classes.closeModalBtn}
                      onClick={this.completeOrder}
                    >
                      <span style={{ fontSize: '14px' }}>Finish Order</span>
                    </Button>
                    <Button
                      className={classes.redundModalBtn}
                      onClick={this.openRefundModelDialog}
                    >
                      <span style={{ fontSize: '14px' }}>RAISE YOUR CONCERN</span>
                    </Button>
                  </div>
                ) : (
                  <Button
                    style={{ backgroundColor: "#32795f" }}
                    className={classes.closeModalBtn}
                    onClick={this.finishPrinting}
                  >
                    <span style={{ fontSize: '14px' }}>Finish Printing</span>
                  </Button>
                )}
              </div>
            </div>
          </Modal>
        </Grid>
        <Dialog
          open={this.state.openRefundModel}
          onClose={this.closeRefundModelDialog}
          aria-labelledby="alert-dialog-title"
          className="raise-concern-dialog"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" ><span style={{ fontSize: '14px', fontWeight: 'bold' }}>Raise Your Concern</span></DialogTitle>
          <span style={{ fontSize: '14px', marginLeft: '22px', fontWeight: 'bold' }}>Order Number: {this.state.orderNumber}</span>
          <span style={{ fontSize: '14px', marginLeft: '22px', fontWeight: 'bold' }}>Phone Number: {this.state.phoneNumber}</span>
          <DialogContent>

            <InputBase
              name="email"
              // onChange={this.handleChangeInput}
              value={this.state.resData?.customer_details?.email}
              placeholder={this.state.resData?.customer_details?.email}
              style={{ border: '1px solid lightgray' }}
              className="textFieldInput-raise-concern"
            />
            <br /><br />
            <InputBase
              multiline
              name="message"
              className="textFieldInput-raise-concern"
              placeholder="Type your concern here ..."
              rows={10}
              value={this.state.reasonForConcern}
              onChange={(e) => {
                this.onChangeRefundReason(e)
                this.setState({ refundError: false })
              }}
            />
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.refundError}</p>
            <Button
              style={{ backgroundColor: "#32795f", marginLeft: '30px' }}
              className={classes.closeModalBtn}
              onClick={this.handleRaiseConcern}
            >
              <span style={{ fontSize: '14px' }}>Submit</span>
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
    },
    leftSideCont: {
      backgroundColor: APP_COLORS.app_theme_yellow_color,
      // height:'100%',
    },
    rightSideCont: {
      // height:'70%',
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headingLeft: {
      width: "95%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      marginTop: '2%'
    },
    backIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor: "pointer"
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    qrContainer: {
      width: "90%",
      [theme.breakpoints.down("xs")]: {
        margin: "10px auto",
      },
      [theme.breakpoints.between("sm", "md")]: {
        margin: "50px auto",
      },
      "@media (min-width: 1280px)": {
        margin: "50px auto",
      },
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    selectFilesText: {
      fontSize: 22,
      margin: "0 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    pageHeader: {
      fontSize: 22,
      marginLeft: "5px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    orderDetailsCont: {
      marginTop: 15,
      width: "100%",
    },
    orderDetailsText: {
      fontSize: 16,
      margin: "5px 0 5px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    orderMerchantTxt: {
      fontSize: 16,
      margin: "5px 0 5px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
    },
    scanCode: {
      fontSize: 14,
      width: "80%",
      margin: "20px auto 0 auto",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
    },
    orderDetailsName: {
      fontSize: 20,
      margin: "8px 0 8px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: 14
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docsCont: {
      width: "95%",
      margin: "0 auto 20px",
    },
    eachDocs: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      // height:250,
      // width:'90%',
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docNav: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    docNavImg: {
      height: 16,
      width: 16,
      marginRight: 5,
      objectFit: "contain",
    },
    docTitle: {
      fontWeight: "bold",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
      wordBreak: "break-all",
      margin: "10px 0",
      wordBreak: 'break-all',
    },
    docImg: {
      height: 50,
      width: 65,
      margin: "10px auto",
      objectFit: "contain",
    },
    barCodeImg: {
      width: 240,
      height: 240,
      objectFit: "contain",
      margin: "20px auto 0 auto",
    },
    docContent: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
    },
    btnCont: {
      margin: "auto",

      borderRadius: 12,
      [theme.breakpoints.down("xs")]: {
        textAlign: 'center',
        marginTop: "0px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: "20px",
      },
      "@media (min-width: 1280px)": {
        marginTop: "20px",
      }
    },
    qrCont: {
      margin: "auto",
      marginTop: "20px",
      paddingLeft: 20,
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    modalImg: {
      height: 80,
      width: 80,
      objectFit: "contain",
      marginTop: 50
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      marginTop: "10px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    redundModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      marginTop: "10px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      color: "#32795f"
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    modalContentText: {
      fontSize: 14,
      margin: "10px auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    modalContentCont: {
      display: "flex",
      height: "85%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    modalHeadingCont: {
      height: "15%",
      minWidth: 220,
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      height: "100%",
      width: "90%",
      maxWidth: '400px',
      minWidth: 220,
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
  });
// Customizable Area End
const OrderDetailsWithConfirmBox = withConfirmBox(OrderDetails);
const OrderDetailsWithLoader = withLoader(OrderDetailsWithConfirmBox)
const OrderDetailsWithToast = withToast(OrderDetailsWithLoader)

export default withRouter(withStyles(styles, { withTheme: true })(OrderDetailsWithToast));
