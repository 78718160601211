// @ts-nocheck
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { RouteProps } from "react-router";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouteProps & {
  id: string;
  classes: any;
  openDialog : any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  username : String,
  profileImagePath : String
  // Customizable Area End
}
interface SS {
  id: any;
}

class ShopDashboardController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      username : '',
      profileImagePath : ''
    };
    // Customizable Area End
  }
  
}
export default (ShopDashboardController);
