// @ts-nocheck
import React, { Component } from "react";
import ReactDOM from "react-dom";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";

export function withToast(OriginalComponent) {
  
  return class extends Component {
    state = {
      open: false,
      message: "",
    };
    showNotification = (message, type) => {
        let color;
        switch (type) {
          case "success":
            color = "#357a38";
            break;
          case "warn":
            color = "#ffa000";
            break;
          case "error":
            color = "#d32f2f";
            break;
           default:
            color = "#313131";
            break;
        }
        this.setState({
          open: !this.state.open,
          color: color,
          message,
        });
      }
      handleClose = (event, reason) => {
         this.setState({open: false},()=>{
          ReactDOM.unmountComponentAtNode(document.getElementById('notification'))
         })
      }
      componentWillUnmount(){
        ReactDOM.unmountComponentAtNode(document.getElementById('notification'))
      }
      componentDidUpdate(){
        this.state.open && ReactDOM.render(<Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            style={{ backgroundColor: this.state.color }}
            message={<span id="toast">{this.state.message}</span>}
          />
        </Snackbar>,document.getElementById('notification'))
      }
     render() {
      return (
        <>
          <OriginalComponent {...this.props} showNotification={this.showNotification}/>
        </>
      );
    }
  };
}
