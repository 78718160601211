//@ts-nocheck
import React from 'react';
import { StyleSheet, View, TextInput, Platform } from 'react-native';
import { APP_FONTS } from './AppFonts';
import { APP_COLORS } from './AppColorConstants';
import Scale from './Scale';
interface IState {
    otp: [string];
    select: any;
    getOtp: (otp: string) => void;
    inputBoxlength?: number;
    isClearCalled?: boolean;
    isSecureText?: boolean;
}

class OTPInputView extends React.Component<IState> {
    constructor(props: IState) {
        super(props);
        this.state = {
            otp: [],
            select: {start: 0, end: 1}
        };
    }

_setSel() { this.setState({ select: {start: 0, end: 1} }) };
otpTextInput: Array<TextInput> = [];

componentDidMount() {
    //  this.otpTextInput[0].focus();
    // if (this.props.isClearCalled) {
    //     this.props.getOtp("");
    //     this.setState({
    //         otp: []
    //     });
    // }
}

renderInputs() {
    if (this.props.isClearCalled) {
        this.props.getOtp("");
        this.setState({
            otp: []
        });
    }
    const inputs = Array(this.props.inputBoxlength ?? 4).fill(0);
    const txt = inputs.map((i, j) => (
        <View key={j} style={styles.inputView}>
            <TextInput
                style={styles.input}
                keyboardType="number-pad"
                maxLength={2} 
                secureTextEntry={this.props.isSecureText ? true : false}
                onChangeText={(v) => this.focusNext(j, v)}
                onKeyPress={(e) => this.focusPrevious(e.nativeEvent.key, j)}
                ref={(ref: TextInput) => (this.otpTextInput[j] = ref)}
                value={this.state.otp[j]}
            />
        </View>
    ));
    return txt;
}

focusPrevious(key: string, index: number) {
    console.log(key, "key")
    if ((key === 'Backspace' || key === 8) && index !== 0)
        this.otpTextInput[index - 1].focus();
}

// focusNext(index: number, value: string) {
//     if (index < this.otpTextInput.length - 1 && value) {
//         this.otpTextInput[index + 1].focus();
//     }
//     if (index === this.otpTextInput.length - 1) {
//         this.otpTextInput[index].blur();
//     }
//     const otp = this.state.otp;
//     otp[index] = value.charAt(value.length - 1);
//     this.setState({ otp });
//     this.props.getOtp(otp.join(''));
// }

focusNext(index: number, value: string) {
    value = value.replace(/[^0-9]/g, "");
    if (index < this.otpTextInput.length - 1 && value) {
        this.otpTextInput[index + 1].focus();
    }
    if (index === this.otpTextInput.length - 1) {
        this.otpTextInput[index].blur();
    }
    const otp = this.state.otp;
    let prevValue = otp[index]
    let res = value.replace(prevValue, "")
    otp[index] = res.charAt(res.length - 1);
    this.setState({ otp });
    this.props.getOtp(otp.join(''));
}
render() {
    return <View style={styles.container}>{this.renderInputs()}</View>;
}
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    inputView: {
        width: Scale(10.8),
        height: Scale(11.3),
        borderWidth: 1.5,
        borderRadius: 4,
        marginEnd: Scale(2),
        borderColor: '#8f92a1',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: Scale(3.5),
        height: '100%',
        width: '100%',
        fontFamily: APP_FONTS.SofiaProRegular
    },
});

export default OTPInputView;