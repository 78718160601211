// @ts-nocheck
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
// Customizable Area End
import Header from "./Header.Web";
import Footer from "./Footer.Web";

class AboutUs extends BlockComponent {
  constructor(props: Props) {
    super(props);
  }

  
  componentDidMount() {
    window.scrollTo(0, 0);
}

  render() {
    const { classes } = this.props;
    return (
      <Grid
      container
      direction="row"
      justify="center"
      className={classes.parentCont}
    >
      <Header />
      <Grid  item xs={12} className={"navBarTopMargin"}/>
      <Grid item xs={12} className={classes.privacyContent}>
      <div className="container about-content-size">
      <div className="row">
      <div className="col-lg-8">
      <div className="about-desc"> 
        <p>PrintSEC is a young company with very rich experience and expertise in the field of digital transformation and global reach through our alliances. We as an organization have taken a two-fold approach in achieving our business objective.</p>
        <ul>
          <li>
          Developed Printsec application : A secure printing service that makes printing convenient and secure for the end users that allows users to print their documents from anywhere and any device to the printer of their choice around the globe securely or have the document delivered at the desired door step.
          </li>
          <li>Provide consulting services to IT/Non-IT industries in resolving business challenges through digital transformation or to an individual to convert their Idea into a working business solution.</li>
        </ul>
        <p>We are a virtual first, employee first organization allowing our employees to enjoy their personal and professional life the way they want.  At the same time, we are continuously hunting for the best</p>
        </div>
        </div>
        <div className="col-lg-4">
        <div className="about-logo"></div>
        </div>
        </div>
        </div>
      </Grid>
      <Footer />
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  privacyContent: {
    padding:"20px 20px 20px 20px",
  },
  inputDiv:{
    margin:'10px auto'
  },
});

export default withRouter(withStyles(styles, { withTheme: true })(AboutUs));