// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  userLogo,
  currentOrdersSelected,
  currentOrdersUnselected,
  settingsSelected,
  qrIcon,
  settingsIcon,
  supportIcon,
  qrSelectedIcon,
  supportSelectedIcon,
  logoutIcon,
  menuIcon,
  closeIcon,
  homeSelectedIcon,
  homeUnSelectedIcon
} from "./assets";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import Switch from "react-router-dom/Switch";
import Link from "react-router-dom/Link";
import Redirect from "react-router/Redirect";
import withRouter from "react-router/withRouter";
import UserSettings from "../../user-profile-basic/src/UserSettings.Web";
import UploadDashBoard from "../src/UploadDashBoard.Web";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import OrderManagement from "../../OrderManagement/src/OrderManagement.Web";
import './css/SideBar.css'

class UserSidebarNavigation extends React.Component {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name,
          profileImagUri: loginData.profile_image_path,
          user_type: loginData.user_type
        });
      }
    });
  }
  navigationLink = [
    {
      name: "New Print",
      path: "/UserSidebarNavigation/UploadDashBoard",
      currentPath: "UploadDashBoard",
      selectedPath: homeSelectedIcon,
      unSelectedPath: homeUnSelectedIcon,
    },
    {
      name: "Order History",
      path: "/OrderManagement/OrderHistory",
      currentPath: "OrderHistory",
      selectedPath: currentOrdersSelected,
      unSelectedPath: currentOrdersUnselected,
    },
    {
      name: "QR Codes",
      path: "/OrderManagement/QRCode",
      currentPath: "QRCode",
      selectedPath: qrSelectedIcon,
      unSelectedPath: qrIcon,
    },
    {
      name: "Support",
      path: "/UserSupport/Support",
      currentPath: "Support",
      selectedPath: supportSelectedIcon,
      unSelectedPath: supportIcon,
    },
    {
      name: "Settings",
      path: "/UserSettings/Settings",
      currentPath: "Settings",
      selectedPath: settingsSelected,
      unSelectedPath: settingsIcon,
    },
  ];

  // Customizable Area End
  isActiveLink = (pathName) => {
    let { location } = this.props;
    const path = location.pathname.toString();
    if (path.includes(pathName)) {
      return true;
    }
    return false;
  };

  handleMobileMenu = () => {
    const navBar = document.getElementById('Navbar')
    navBar.classList.toggle("header_open_cont")
  }

  logoutConfirmationDialog = () => {
    const { openDialog } = this.props;
    const onClose = (msg: any) => {
      if (msg === 'Yes') {
        this.clearStorageAndredirectToLogin()
      }
    }
    this.props.openDialog('Logout Confirmation', 'Are you sure want to logout?', onClose);
  }


  clearStorageAndredirectToLogin = async () => {
    await StorageProvider.remove("loginData");
    await StorageProvider.remove("signUpData");
    await StorageProvider.remove("selectedFiles");
    await StorageProvider.remove("showPay");
    await StorageProvider.remove("orderId");
    await StorageProvider.remove("orderIdDetails");
    await StorageProvider.remove("user_type")
    await StorageProvider.set("user_type", this.state.user_type)
    if (await StorageProvider.get("login_type") === "guest_account") {
      await StorageProvider.remove("user_type")
      this.props.history.push("/")
    } else {
      this.props.history.push("/PhoneLoginWeb")
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.appContainer}`}>
        <div id={"Navbar"} className={`${classes.leftBackground} dashBoard_sidebar header_main_cont`}>
          <Grid
            container
            justify="center"
            alignItems={"center"}
          >
            <Grid item xs={12} className={`logoCont`}>
              <img src={userLogo} className={`companyLogo`} />
              <img src={closeIcon} className={`menuIcon`} onClick={this.handleMobileMenu} />
            </Grid>
            <List disablePadding className={"navLink_cont"}>
              {this.navigationLink.map((navLinkItem) => {
                return (
                  <Link
                    key={navLinkItem.name}
                    to={navLinkItem.path}
                    onClick={this.handleMobileMenu}
                    style={{ textDecoration: "none" }}
                  >
                    <Grid item xs={12}>
                      <ListItem to={navLinkItem.path} disableGutters>
                        <ListItemIcon>
                          <img
                            src={
                              this.isActiveLink(navLinkItem.currentPath)
                                ? navLinkItem.selectedPath
                                : navLinkItem.unSelectedPath
                            }
                            className={classes.printIcon}
                          />
                        </ListItemIcon>
                        <p className={`navBarListTextItem`}
                          style={{ fontFamily: APP_FONTS.SofiaProRegular }}
                        >{navLinkItem.name}</p>
                      </ListItem>
                    </Grid>
                  </Link>
                );
              })}
            </List>
            <Grid
              xs={12}
              item
              className={"logOutCont"}
              style={{ marginTop: "10%" }}
            >
              <img src={logoutIcon}
                onClick={() => {
                  this.handleMobileMenu()
                  this.logoutConfirmationDialog()
                }
                }
              />
            </Grid>
          </Grid>
        </div>
        <div className={`dashBoard_content`} >
          <Grid item xs={12} className={`logoNavCont`}>
            <img src={menuIcon} className={`menuIcon`} onClick={this.handleMobileMenu} />
          </Grid>
          <Switch>
            <PrivateRoute role="Customer" path={`/UserSidebarNavigation/UploadDashBoard`}>
              <UploadDashBoard />
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/OrderManagement/OrderHistory`}>
              <OrderManagement />
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/OrderManagement/QRCode`}>
              <OrderManagement status={'Pending'} />
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/UserSupport/Support`}>
              <UploadDashBoard />
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/UserSettings/Settings`}>
              <UserSettings />
            </PrivateRoute>

            <Redirect
              exact
              from={"/UserSupport"}
              to={`/UserSupport/Support`}
            />
            <Redirect
              exact
              from={"/OrderManagement"}
              to={`/OrderManagement/OrderHistory`}
            />
            <Redirect
              exact
              from={"/UserSidebarNavigation"}
              to={`/UserSidebarNavigation/UploadDashBoard`}
            />
            <Redirect
              exact
              from={"/UserSettings"}
              to={`/UserSettings/Settings`}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    appContainer: {
      minHeight: "100vh",
      width: '100%',
      position: "relative",
      overflowX: "hidden"
    },
    leftBackground: {
      paddingTop: "2%",
      // background:"rgba(0,0,0,0.1)",
    },
    rightBackground: {
      borderRadius: "32px",
      backgroundColor: "#fff",
    },
    printIcon: {
      width: "50px",
    },
    logoutIcon: {
      width: "48px",
      height: "48px",
      margin: "143px 11px 0 7px",
      paddingLeft: "10px",
      objectFit: "contain",
    },
    navBarListText: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      color: "#1e1f20",
      marginLeft: 7
    },
    labelSetting: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    settingAvatar: {
      width: "18px",
      height: "18px",
      borderRadius: "15.2px",
      float: "right",
      marginTop: "20px",
      padding: "15px",
    },
    parentContainer: {
      padding: "30px",
    },
    editProfileColorButton: {
      width: "236px",
      marginLeft: "25px",
      padding: "15px",
      borderRadius: "12px",
      backgroundColor: "#32795f",
    },
    editProfileButton: {
      width: "236px",
      marginLeft: "25px",
      padding: "15px",
      borderRadius: "12px",
      marginTop: "10px",
      backgroundColor: "#fff",
    },
    editProfileButtonIcon: {
      width: "18px",
      height: "18px",
    },
    editProfileColorButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#ffffff",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#8f92a1",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileArrowIcon: {
      width: "18px",
      height: "18px",
    },
    active: {
      color: "red",
    },
  });

export default withRouter(
  withStyles(styles, { withTheme: true })(withConfirmBox((UserSidebarNavigation)))
);

