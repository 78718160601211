//@ts-nocheck
import React from "react";
// Customizable Area Start
import MerchantinvoicesController, {
  Props,
} from "./MerchantInvoicesController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import StorageProvider from "../../../framework/src/StorageProvider";
import withRouter from "react-router/withRouter";
import { DataGrid } from "@mui/x-data-grid";
import Select from 'react-select';
import './index.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { Button, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { appIcon } from "./assets";
import './index.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import MerchantInvoicesDownloadWeb from "./MerchantInvoicesDownload.web";
import MerchantInvoicesViewWeb from './MerchantInvoicesView.web'



class MerchantInvoices extends MerchantinvoicesController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

 async componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          token: loginData.token,
        });
      }
    });
    await this.invoices();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
  
  onDateInputFocus = () => {
    this.setState((prevState) => ({
      showCalendar: !this.state.showCalendar,
      dateRange: {
        ...prevState.dateRange,
        startDate: prevState.dateRange.startDate ?? new Date(),
        endDate: prevState.dateRange.endDate ?? new Date(),
      },
    }));
  };

  handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    const { startDate, endDate } = selection;
    this.setState({ dateRange: selection, });

    if (endDate && !isNaN(endDate.getTime()) && startDate?.getTime() !== endDate?.getTime()) {
      // this.setState({ showCalendar: false, });
    }
  };
  datePickerRef = null;
  inputRef = null;

  handleClickOutside = (e) => {
    if (
      this.datePickerRef &&
      !this.datePickerRef.contains(e.target) &&
      e.target !== this.inputRef
    ) {
      // Clicked outside of the date picker (excluding the input field), so close it
      this.setState({ showCalendar: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { showCalendar, dateRange , open } = this.state;
    const columns = [
      {
        field: "id",
        headerName: "S.No",
        width: 150
      },
      {
        field: "billingStartDate",
        headerName: "Billing Start Date",
        width: 150,
        valueGetter: (params) => {
          const startDate = params.row.attributes.start_date;
          return format(new Date(startDate), "dd-MM-yyyy");
        }
      },
      {
        field: "billingEndDate",
        headerName: "Billing End Date",
        width: 150,
        valueGetter: (params) => {
          const endDate = params.row.attributes.end_date;
          return format(new Date(endDate), "dd-MM-yyyy");
        }
      },
      {
        field: "bwCopies",
        headerName: "No of b/w copies",
        width: 150,
        valueGetter: (params) => params.row.attributes.no_of_greyscale_copies
      },
      {
        field: "colorCopies",
        headerName: "No of color copies",
        width: 150,
        valueGetter: (params) => params.row.attributes.no_of_coloured_copies
      },
      {
        field: "amount",
        headerName: "Amount",
        width: 150,
        valueGetter: (params) => params.row.attributes.payable_amount

      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        valueGetter: (params) => params.row.attributes.status,
        renderCell: (params) => (
          <ChildComponent classes={this.props.classes} value={params.value} />
        ),
      },
      {
        field: "",
        headerName: "Billing Date",
        width: 150,
        renderCell: (params) => (
          <span> {format(new Date(params.row.attributes.end_date), "dd-MM-yyyy")}</span>
        ),
      },
      {
        field: "payment_date",
        headerName: "Payment Date",
        width: 150,
        valueGetter: (params) => {
          const paymentDate = params.row.attributes.payment_date;
          if (paymentDate) {
            return format(new Date(paymentDate), "dd-MM-yyyy");
          }
          return;
        }
      },
      {
        field: "View",
        headerName: "Action",
        width: 175,
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <div className="innerBtn">
              <Button
                className={classes.viewPdfBtn}
                onClick={() => {
                  this.setState({
                    invoicePdfUrl: params.row?.attributes?.invoice_pdf_url
                  },
                    this.handleViewOpen()
                  )
                }}
              >
                View
              </Button>
              <Button
             className={
              params.row?.attributes.status === "unpaid"
                ? classes.findPrintersBtn
                : params.row?.attributes.status === "paid"
                ? classes.viewPdfBtn
                : params.row?.attributes.status === "failed"
                ? classes.failedBtn
                : ""
            }
                onClick={() => {
                  this.setState({
                    invoicePdfUrl: params.row?.attributes?.invoice_pdf_url,
                    monthlyInvoiceId: params.id,
                    rowData:params.row?.attributes
                  },
                    this.handleClickOpen()
                  )
                }}
              >
                {params.row?.attributes.status === "unpaid" ? "Pay Now" :
                  params.row?.attributes.status === "paid" ? "Paid" : "Retry"
                }
                
              </Button>
            </div>
          )
        },
      }
    ];

    const ChildComponent = (props) => {
      const { classes, value } = props;
      return <span className={classes[value]}> {value} </span>;
    };

    const options = [
      { value: 'Paid', label: 'Paid' },
      { value: 'Pending', label: 'Pending' },
      { value: 'Failed', label: 'Failed' },
    ];
    return (
      <>
        <Grid container className={classes.rightContent}>
          <Grid item xs={11} className={classes.headingCont}>
            <div className={classes.mainContainerHead}>
              <div className={classes.headingLeft}>
                <Typography className={classes.labelText}>Invoices</Typography>
                <img
                  src={appIcon}
                  alt=""
                  className={classes.backIcon}
                  onClick={this._handleAllShopsModal}
                />
              </div>
            </div>
            <Typography className={classes.labelContent}>
              <span style={{ fontSize: "14px" }}>
                This is invoice section here you can pay your bills
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="formSelect">
          <Grid item xs={3} className="period-grid">
            <input
             ref={(ref) => (this.inputRef = ref)}
              className="period-input"
              onClick={this.onDateInputFocus}
              value={
                dateRange?.startDate && dateRange?.endDate
                  ? `${format(new Date(dateRange.startDate), "dd-MM-yyyy")} To ${format(
                    new Date(dateRange.endDate),
                    "dd-MM-yyyy"
                  )}`
                  : ""
              }
              placeholder="Select Date"
              readOnly
            />
            {showCalendar && (
             <>
             <div className="rdrCalendarMainWrapper">
               <div ref={(ref) => (this.datePickerRef = ref)}>
                 <DateRange
                   className="date-range-wrapper"
                   onChange={this.handleDateRangeChange}
                   ranges={[dateRange]}
                   direction="horizontal"
                   months={2}
                 />
               </div>
               <div className="rdrCalendarBtns">
                 <button
                   className="custom-button"
                   onClick={() => {
                     this.setState({
                       showCalendar: false,
                     })
                   }}
                 >
                   Apply
                 </button>
                 <button
                   className="custom-button"
                   onClick={() => {
                     this.setState({
                       showCalendar: true,
                       dateRange: {
                         startDate: null,
                         endDate: null,
                         key: "selection",
                       },
                     })
                   }}
                 >
                   Cancel
                 </button>
               </div>
             </div>
           </>
            )}
          </Grid>
          <Grid item xs={3}>
            <Select
              options={options}
              value={this.state.selectedOption}
              onChange={this.handleChange}
              style={{ width: '200px' }}
              isClearable={true}
            />
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Button className="viewBtn"
                onClick={() => { }}
              >
                View
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <div className={classes.columnHeaderWrapper}>
            <DataGrid
              rows={this.state.rows}
              style={{ fontSize: "14px", fontWeight: "300" }}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          </div>
        </Grid>
        <MerchantInvoicesDownloadWeb
          createTransaction ={this.createTransaction}
          handleClose={this.handleClose}
          openRazorPay={this.openRazorPay}
          rowData={this.state.rowData}
          {...this.state}
        />
         <MerchantInvoicesViewWeb
          invoicePdfUrl = {this.state.invoicePdfUrl}
          view={this.state.view}
          handleCloseView={this.handleCloseView}
        />
      </>
    );
  }
}
// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    findPrintersBtn: {
      margin: "5px 0px",
      backgroundColor: '#ffa726',
      color: 'white',
      fontSize: "14px",
      maxWidth: 300,
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: '#ffa726',
      }
    },
    viewPdfBtn: {
      margin: "5px 0px",
      backgroundColor: '#32795f',
      color: 'white',
      fontSize: "14px",
      maxWidth: 300,
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: '#32795f',
      }
    },
    failedBtn: {
      margin: "5px 0px",
      backgroundColor: "rgb(255 ,0,0)",
      color: 'white',
      fontSize: "14px",
      maxWidth: 300,
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: '#32795f',
      }
    },
    headingCont: {
      width: "100%",
      margin: "0 auto",
    },
    labelText: {
      fontSize: 22,
      margin: "20px 0 10px 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    labelContent: {
      fontSize: "12px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    backIcon: {
      width: 40,
      height: 40,
      marginLeft: 15,
      cursor: "pointer",
      objectFit: "contain",
    },
    billingtableCellhead: {
      fontSize: "18px",
      fontWeight: "700",
    },
    billingtableCell: {
      fontSize: "16px",
      fontWeight: "400",
    },
    paid: {
      backgroundColor: "#32795f",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      color:"white",
      textTransform: "capitalize"
    },
    pending: {
      backgroundColor: "rgb(255 ,193,7)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },    
    unpaid: {
      backgroundColor: "rgb(255 ,193,7)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    columnHeaderWrapper: {
      margin: '0 auto',
      width: '92%',
      height: '80vh',
    },
    Failed: {
      backgroundColor: "rgb(255 ,0,0)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      color: "white",
      minWidth: "70px",
      textAlign: "center",
      textTransform: "capitalize"
    },
  });
// Customizable Area End
const MerchantInvoicesWithConfirmBox = withConfirmBox(MerchantInvoices);
const MerchantInvoicesWithLoader = withLoader(MerchantInvoicesWithConfirmBox);
const MerchantInvoicesWithToast = withToast(MerchantInvoicesWithLoader);
export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantInvoicesWithToast)
);
