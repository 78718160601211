// @ts-nocheck
import React from "react";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import OTPInputView from "../../../components/src/OtpInput.web"

import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import ChangePasscodeController, {
  Props,
  configJSON
} from "./ChangePasscodeController.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
// Customizable Area End
class ChangePasscodeBlock extends ChangePasscodeController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getCurrentPasscode(currentPasscode: string) {
    this.setState({ CurrnetPasscodeError: false })
    this.setState({ currentPasscode });
  }

  getNewPasscode(newPasscode: string) {
    this.setState({ newPasscodeError: false })
    this.setState({ newPasscode });
  }

  getConfirmedNewPasscode(confirmednNewPasscode: string) {
    this.setState({ confirmedPasscodeError: false })
    this.setState({ matchPasscodeError: false })
    this.setState({ confirmednNewPasscode });
  }
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          id: loginData.id,
          authToken: loginData.token,
        });
      }
    });
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Grid container item direction="column" className={classes.container}>
        <Grid item xs={6} className={classes.parentContainer}>
          <Typography className={classes.sofiaFont}>
            <span style={{ fontSize: '22px' }}>{configJSON.labelUpdateLoginPasscode}</span>
          </Typography>
          <Typography className={classes.yourOld6DigitPas}>
            {configJSON.labelYourOldPasscode}
          </Typography>
          <OTPInputView OTPLength={6} value={this.state.currentPasscode} className="change-passcode" otpType={"number"}
            onOTPChange={(otp) => {
              this.setState({ currentPasscode: otp });
            }}
            secure={false} />

          <Typography className={classes.errorLabel}>
            {this.state.CurrnetPasscodeError}
          </Typography>
          <Grid>
            <Typography className={classes.yourOld6DigitPas}>
              {configJSON.labelYourNewPasscode}
            </Typography>
            <OTPInputView inputBoxlength={6} isSecureType={false} getOtp={(otp: string) => this.getNewPasscode(otp)} />

            <OTPInputView style={{ width: '140% !important' }} OTPLength={6} value={this.state.newPasscode} className="change-passcode" otpType={"number"}
              onOTPChange={(otp) =>
                this.getNewPasscode(otp)
              }
              secure={false} />

            <Typography className={classes.errorLabel}>
              {this.state.newPasscodeError}
            </Typography>
            <Typography className={classes.yourOld6DigitPas}>
              {configJSON.labelConfirmYourPasscode}
            </Typography>
            <OTPInputView inputBoxlength={6} isSecureType={false} getOtp={(otp: string) => this.getConfirmedNewPasscode(otp)} />

            <OTPInputView OTPLength={6} value={this.state.confirmednNewPasscode} className="change-passcode" otpType={"number"}
              onOTPChange={(otp) => this.getConfirmedNewPasscode(otp)}
              secure={false} />
            <Typography className={classes.errorLabel}>
              {this.state.confirmedPasscodeError}
              {this.state.matchPasscodeError}
            </Typography>
          </Grid>
          <Button
            className={classes.passcodeSaveButton}
            onClick={() => this.validateChangePasscodeCall()}
          >
            <Typography className={classes.passcodeSaveButtonTxt}>Save</Typography>
          </Button>
          <Typography className={classes.successMessage}>
            {this.state.successMessage}
          </Typography>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  container: {
    padding: '7%'
  },
  rightBackground: {
    borderRadius: "32px",
    backgroundColor: "#fff"
  },
  yourOld6DigitPas: {
    fontFamily: "SofiaPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "1px",
    color: "#1e1f20",
    marginTop: "50px"
  },
  passcodeSaveButton: {
    width: "319px",
    height: "58px",
    margin: "124px 0 0",
    padding: "18px 143px 20px",
    borderRadius: "12px",
    backgroundColor: "#32795f",
    "&:hover": {
      backgroundColor: "#32795f"
    }
  },
  sofiaFont: {
    fontFamily: APP_FONTS.SofiaProRegular
  },
  passcodeSaveButtonTxt: {
    fontFamily: "SofiaPro",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  successMessage: {
    color: "#32795f"
  },
  errorLabel: {
    color: "red",
    fontFamily: "SofiaPro"
  }
});
// Customizable Area End

const ChangePasscodeBlockWithLoader = withLoader(ChangePasscodeBlock)
const ChangePasscodeBlockWithToast = withToast(ChangePasscodeBlockWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(ChangePasscodeBlockWithToast));
