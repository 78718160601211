// @ts-nocheck
import React, { Component } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export function withLoaderPayment(OriginalComponent) {
  return class extends Component {
    state = {
      isLoading: false,
    };
    showLoaderPayment = () => {
      this.setState({
        isLoading: true,
      });
    };
    hideLoaderPayment = () => {
      this.setState({ isLoading: false });
    };

    render() {
      return (
        <>
          {this.state.isLoading && (
            <Backdrop style={{ zIndex: 99999 }} open={this.state.isLoading}>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "40%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                  <CircularProgress size="5rem" style={{ color: "#fdd001" }} />
              </div>
              <div style={{fontWeight: "bold", fontSize: 30, textAlign: "center", marginTop: 220}}>
                Payment in progress please do not refresh page
              </div>
              </Backdrop>
          )}
          <OriginalComponent
            {...this.props}
            showLoaderPayment={this.showLoaderPayment}
            hideLoaderPayment={this.hideLoaderPayment}
          />
        </>
      );
    }
  };
}
