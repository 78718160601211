// @ts-nocheck
import React from "react";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
// Customizable Area End

import FooterController, {
  Props
} from "./FooterController.Web";
import './Header.css';
import './LandingPage.css';

import {
  footerImg,
  gtxImg,
  envlpImg,
  twitterImg,
  gtImg,
  pImg,
  glxImg
} from './assets'

class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
  }

   async componentDidMount() {
     this.getFooterContent()
   }


  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid>
      <div className="container-fluid ft">
      <div className="row">
        <div className="footer-content">
          <div className="col-lg-4">
            <div className="logo-footer">
              <img src={footerImg} />
              <p>PrintSEC has immense potential to digitalize your business and move it forward through a digital transformation.</p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-menu">
              <h3>QUICK LINKS</h3>
              <ul className="list">
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Services")}>Services</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/About-us")} >About us</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Contact-us")}>Contact us</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-menu">
              <h3>GET IN TOUCH
              </h3>
              <p className="salesContent">Sales Specialists at PrintSEC will help you start your digital journey. Reach out to us through:</p>
              <ul className="list contx">
              <a href="mailto:connect@printsec.app"><li><span><i className="fa fa-envelope" aria-hidden="true"></i></span>connect@printsec.app</li></a>
              <a href="tel:+91-9319480924"><li><span><i className="fa fa-phone" aria-hidden="true"></i></span>+91-9319480924</li></a>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-menu btz">
              <h3>DOWNLOAD THE APP</h3>
              <ul className="list">
                <li><a href="https://apps.apple.com/in/app/id1545304010" target="_blank"><img src={gtxImg} /></a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.SecurePrinting" target="_blank"><img src={glxImg} /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row social-icon">
        <div className="social">
          <img src={envlpImg} />
          <a href="https://twitter.com/PrintsecD" target="_blank"><img src={twitterImg} /></a>
          <a href="https://www.linkedin.com/in/printsec-digital-32b63021b/" target="_blank"><img src={gtImg} /></a>
          <a href="https://www.instagram.com/printsecdigital/" target="_blank"><img src={pImg} /></a>
        </div>  
      </div>
    </div>
    <div className="container-fluid bt">
      <div className="row">
        <div className="col-lg-5">
          <div className="bottom-ft">
            <p>© 2021 PrintSEC Digital</p>
          </div>
        </div>
        <div className="col-lg-6">
        <div className="bottom-ft">
        <ul className="lst">
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Enduser-licence")} >End user license agreement</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Privacy-policy")}>Privacy policy</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Terms-and-conditions")}>Term and Conditions</li>
        </ul>
        </div>
        </div>
      </div>
    </div>
    </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  linkPointer: {
    cursor: "pointer"
  }
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(Footer));
