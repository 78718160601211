import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area Start

// Customizable Area End

export type Props = RouterProps & {
  id: string;
  classes: any;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  email: string;
  authToken: string;
  message: string;
  loading: boolean;
  orderId: any;
  id: string;
  lastOrder: any;
  orderUserName: any;
  merchantId: any;
  allOrdersList: any;
  isPendingOrder: boolean;
  pendingAttachement: any;
  otpModel: boolean;
  OTP: string;
  verifyOtpError: string;
  validateApiError: string;
  errorMessage: void;
  fullPhoneNumber: string;
  countryCode: string;
  starMobileNumber: string;
  totalPages: object
  minutes: any;
  seconds: any,
  timerRunning: boolean,
  payment_type:string;
  is_secured_delivery:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MerchantDashboradHomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGenerateQrCodeCallId: String = "";
  apiGetOrderHistoryCallId: String = "";
  apiUpdateOrderPrintingCompletedCallId: String = "";
  apiVerifyOTPLCallId: String = "";
  apiGetOTPLCallId: string = "";
  apiMerchantPrintingDetailsCallId: String = "";
  apiGetMFADetailsCallId: string = "";
  apiUpdateOrderMerchantCallId:String ="";
  get_merchant_details:string=""
  timer: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.AlertMessage)];

    this.state = {
      email: "",
      authToken: "",
      message: "",
      loading: false,
      orderId: "",
      id: "",
      lastOrder: [],
      orderUserName: "",
      merchantId: null,
      allOrdersList: [],
      isPendingOrder: false,
      pendingAttachement: '',
      otpModel: false,
      OTP: "",
      verifyOtpError: "",
      validateApiError: "",
      errorMessage: undefined,
      fullPhoneNumber: "",
      countryCode: "",
      starMobileNumber: "",
      totalPages: {},
      minutes: 2,
      seconds: 0,
      timerRunning: false,
      is_secured_delivery:false,
      payment_type: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, "error");
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiMerchantPrintingDetailsCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({ totalPages: responseJson.data.attributes });
        }
      }
      if (apiRequestCallId === this.apiGenerateQrCodeCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({ orderId: responseJson.data.id });
          this.setState({ lastOrder: [responseJson.data.attributes] });
          this.setState({
            orderUserName: responseJson.data.attributes.customer_details.name,
          });
        } else {
          this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors);
          if (responseJson.errors) {
            const accountBlockValidate = responseJson.errors[0].message;
            if (accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
              // this.props.showNotification("Account not found", "error");
              setTimeout(() => {
                this.props.history.push('/PhoneLoginWeb');
              }, 1000);
            }
          }
        }
      }
       else if (apiRequestCallId === this.apiGetOrderHistoryCallId) {
        if (responseJson.data) {
          responseJson?.data?.map((ele: any) => {
            if (ele?.attributes?.order_attachments.attachment_details.length && (
              (ele?.attributes?.order_attachments.attachment_details?.filter((el: any) => el.is_printed).length === ele.attributes.order_attachments.attachment_details.length) && (ele.attributes.status === 'Printing'))) {
              this.setState({ isPendingOrder: true, pendingAttachement: ele })
            }
          })
        }
        this.setState({ allOrdersList: responseJson.data, loading: false });
      } else if (apiRequestCallId == this.apiUpdateOrderPrintingCompletedCallId) {
        window.location.reload();
      }

      else if (apiRequestCallId === this.apiGetMFADetailsCallId) {
        if (responseJson && responseJson.data) {
          const { is_secured_delivery ,payment_type } = this.state
          const { account_type } = responseJson.data?.attributes?.customer_details;
          if (is_secured_delivery && payment_type === "Postpaid" && account_type !== "GuestAccount") {
            this.setState({
              otpModel: true,
              fullPhoneNumber: responseJson.data.attributes.customer_details?.full_phone_number,
              countryCode: responseJson.data.attributes.customer_details?.country_code,
              starMobileNumber : "*".repeat(responseJson.data.attributes.customer_details?.phone_number.toString().length - 2),
              loading: false,
            },
              () => {
                this.resendOTP();
              });          
            }
          else if (responseJson && responseJson.data && payment_type === "Postpaid" && account_type === "GuestAccount") {
              this.showAlert("Response Alert!", "Since this service provider has enabled secure printing, which sends an otp to the registered mobile number of a user, hence guest users are not allowed to take printout. Either you signup/login with your phone number or ask service provider to disabled the settings");
            }
          else if (responseJson && responseJson.data && !is_secured_delivery) {
            this.updateOrderMerchant();
          }
          else if (responseJson && responseJson.message) {
            this.showAlert("Response Alert!", responseJson.message);
          } 
         }
        else {
          //Check Error Response
          this.props.showNotification(responseJson.errors, 'error');
          // this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          const accountBlockValidate = responseJson.errors[0].message;
          console.log(responseJson.errors[0], "accountBlockValidate")
          if (accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
            // this.props.showNotification("Account not found", "error");
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          }
        }
        this.props.hideLoader();
      }
      else if (apiRequestCallId === this.apiVerifyOTPLCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.props.showNotification("OTP verified Successfully", "success");
          setTimeout(()=>{
            this.updateOrderMerchant();
          },1000)
        }
        else if (responseJson && responseJson.message) {
          this.showAlert("Response Alert!", responseJson.message);
          this.sendAPIFailMessage();
          this.setState({ errorMessage: responseJson.errors[0] })
          this.parseApiErrorResponse(responseJson);
        }
        else {
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }
      else if (apiRequestCallId === this.apiUpdateOrderMerchantCallId) {
        this.setState({ loading: false ,otpModel:false});
        if (responseJson && responseJson.data) {
          await StorageProvider.set("scannedOrderDetail", JSON.stringify(this.state.orderId))
          this.props.history.push('/MerchantSidebarNavigation/DashBoard/OrderDetail')
        } else if (responseJson && responseJson.errors) {
          this.showAlert("Response Alert!", responseJson.errors);
          this.props.showNotification(responseJson.errors, 'error');
        }
        else {
          //Check Error Response
          this.props.showNotification(responseJson.errors, 'error');
          // this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          const accountBlockValidate = responseJson.errors[0].message;
          console.log(responseJson.errors[0], "accountBlockValidate")
          if ( accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
            // this.props.showNotification("Account not found", "error");
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          }
        }
      } 
       else if (this.apiGetOTPLCallId === apiRequestCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.props.showNotification("OTP Send Successfully", "success");
        } else {
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }
      else if (this.get_merchant_details === apiRequestCallId) {
        console.clear()
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({
            is_secured_delivery:responseJson.data[0].attributes?.is_secured_delivery,
            payment_type:responseJson.data[0].attributes?.payment_type
          })
        } else {
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }

    }

    // Customizable Area End
  }

  // Customizable Area Start

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid4DigitPOTP(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 4
    );
  }

  validateOTPNumber(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "OTP " + configJSON.errorBlankField;
    } else if (!this.isValid4DigitPOTP(otp)) {
      error = configJSON.errorOTPNotValid;
    }
    return error;
  }

  validateOTPAndVerifyMobileCall() {
    let OTP: any = this.state.OTP;
    const validateOtpError = this.validateOTPNumber(OTP);

    if (validateOtpError) {
      this.setState({ verifyOtpError: validateOtpError });
      return;
    }

    this.setState({ loading: true });
    this.props.showLoader();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      // data: data
      full_phone_number: this.state.fullPhoneNumber,
      token: this.state.authToken,
      pin: this.state.OTP,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return;
  }

  async resendOTP() {
    this.setState({ loading: true });
    this.props.showLoader();
    this.setState({ minutes: 2, seconds: 0 });
    this.startTimer();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const data = {
      attributes: {
        full_phone_number: this.state.fullPhoneNumber,
        is_2f_auth: true,
        account_id: this.state.allOrdersList[0].attributes.merchant_details.account_id
      }
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOTPAPiEndPoint

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  }

  getMerchantPrintingDetails() {
    this.getMerchantDetails();
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMerchantPrintingDetailsCallId = requestMessage.messageId;

    let merchantAccountEndPointWithID = `account_block/accounts/merchants/${this.state.id}/get_merchant_printing_wallet`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      merchantAccountEndPointWithID
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMerchantDetails(){
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.get_merchant_details = requestMessage.messageId;

    let getMerchantDetailsEndPoint = `${configJSON.getMerchantPrintPrice}/?id=${this.state.id}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),getMerchantDetailsEndPoint      
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLastOrder() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGenerateQrCodeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.lastOrderEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllOrdersList() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrderHistoryCallId = requestMessage.messageId;

    let getOrderHistoryAPIEndPoint = configJSON.getCurrentOrdersAPIEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getOrderHistoryAPIEndPoint)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  startTimer = () => {
    this.setState({ timerRunning: true }); 
    this.timer = setInterval(() => {
      const { minutes, seconds } = this.state;

      if (minutes === 0 && seconds === 0) {
        this.stopTimer();
      } else {
        if (seconds > 0) {
          this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
        } else {
          this.setState((prevState) => ({ minutes: prevState.minutes - 1, seconds: 59 }));
        }
      }
    }, 1000);
  };

  stopTimer = () => {
    clearInterval(this.timer);
    this.setState({ timerRunning: false });
  };

  getOrdersDetails() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMFADetailsCallId = requestMessage.messageId;
    let getMFADetailsAPiEndPoint = configJSON.getMFADetailsAPiEndPoint + this.state.orderId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getMFADetailsAPiEndPoint)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateOrderMerchant() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderMerchantCallId = requestMessage.messageId;

    let updateOrderAPIEndPoint = ("" + configJSON.getOrderDetailsAPIEndPoint)
      .split("orderId")
      .join("" + this.state.orderId);

    updateOrderAPIEndPoint =
      updateOrderAPIEndPoint + "?data[merchant_id]=" + this.state.merchantId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateOrderAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 

  async printingFinshedOrderUpdate() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderPrintingCompletedCallId = requestMessage.messageId;

    let updateOrderStatusAPIEndPoint = (
      "" + configJSON.getOrderDetailsAPIEndPoint).split("orderId").join("" + this.state.pendingAttachement.id);

    updateOrderStatusAPIEndPoint =
      updateOrderStatusAPIEndPoint + "?data[status]=Printingcompleted";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateOrderStatusAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  // Customizable Area End
}