import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from '../../../framework/src/StorageProvider';
import { RouterProps } from "react-router";
let config = require('../../../framework/src/config');
// Customizable Area End


export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  classes: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: number;
  id: string;
  uploading: boolean,
  authToken: string;
  selectedFiles: File[];
  // uploadedFileList : any[];
  // Customizable Area End
}

interface SS {
  id: any;
}
class NewPrintController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPrintingOrderAPiCallId: String = "";
  apiGetAttachmentCallId: String = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      loading: 0,
      uploading: false,
      id: "",
      authToken: "",
      selectedFiles: [],
      // uploadedFileList : []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getPrintingOrderAPiCallId) {
        if (responseJson && responseJson.data) {
          await StorageProvider.set('orderIdDetails', (JSON.stringify(responseJson.data[0])));
          this.props.history.push("/OrderManagement/OrderHistory/OrderDetail", {
            orderId: responseJson.data[0].id,
          });
        } else {
          this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          const accountBlockValidate = responseJson.errors[0].message;
          if (validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
            // this.props.showNotification("Account not found", "error");
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          }
        }
      }
      if (apiRequestCallId === this.apiGetAttachmentCallId) {
        if (responseJson && responseJson.data) {
          if (responseJson.data.length > 0) {
            // this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen");
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async uploadFile() {
    let formData = new FormData();
    formData.append("token", this.state.authToken);
    formData.append("data[account_id]", this.state.id);
    if (this.state.selectedFiles.length > 0) {
      for (let i = 0; i < this.state.selectedFiles.length; i++) {
        const fileType = this.state.selectedFiles[i].type;
        if (fileType !== 'application/msword' && fileType !== 'application/vnd.oasis.opendocument.text' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
          fileType !== 'image/gif' && fileType !== 'image/png' && fileType !== 'image/jpg' && fileType !== 'image/jpeg' && fileType !== 'application/pdf'
          && fileType !== 'text/plain' && fileType !== 'application/vnd.ms-powerpoint' && fileType !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
          && fileType !== 'application/vnd.oasis.opendocument.presentation' && fileType !== 'application/vnd.ms-excel' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          && fileType !== 'application/vnd.oasis.opendocument.spreadsheet') {
          const { showNotification } = this.props;
          showNotification("Please upload valid file", "error");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          return false;
        }
        formData.append(
          `data[attachments][${i}][attachment]`,
          this.state.selectedFiles[i]
        );
      }
    }
    const baseURL = config.baseURL;
    let request = new XMLHttpRequest();
    request.open("POST", baseURL + "/" + configJSON.uploadFilesEndPoint);
    request.setRequestHeader("accept", configJSON.contentType);
    if (await StorageProvider.get('login_type') === 'guest_account') {
      request.setRequestHeader("uuid", this.state.authToken);
    }
    request.addEventListener(
      "load",
      async (e) => {
        this.props.hideLoader();
        /* throttledProcess is a function that does your processing spaced by the given interval millisecond */
        const throttledProcess = (items: any, interval: any) => {
          if (items.length == 0) { // stop when there's no more items to process
            setTimeout(() => {
              this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen");
            }, 1000);
            return
          }
          this.props.showNotification(items[0], 'error')
          setTimeout(() => throttledProcess(items.slice(1), interval), // wrap in an arrow function to defer evaluation
            interval)
        }
        if (request.status < 400) {
          this.props.showNotification("File successfully uploaded", "success")
          let message = JSON.parse(request.response)
          if (message?.meta?.errors?.length > 0) {
            setTimeout(() => {
              throttledProcess(message?.meta?.errors, 3000)
            }, 3000)
          } else {
            setTimeout(() => {
              this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen");
            }, 1000);
          }
        } else {
          let errorMessage = JSON.parse(request.response)
          if (errorMessage?.errors.length > 0) {
            throttledProcess(errorMessage?.errors, 3000)
          }
        }
        this.setState({ loading: 100 });
        this.setState({ uploading: false });
        let data = JSON.parse(request.response)?.data || [];
        await StorageProvider.remove('selectedFiles')
        await StorageProvider.set('selectedFiles', JSON.stringify(data));
      }
    );
    request.upload.addEventListener(
      "loadstart",
      () => {
        this.props.showLoader();
        this.setState({ loading: 0 });
        this.setState({ uploading: true });
      }
    );
    request.upload.addEventListener(
      "progress",
      (e) => {
        if (e.lengthComputable) {
          var percentage = Math.round((e.loaded / e.total) * 100);
          this.setState({ loading: percentage });
          this.setState({ uploading: true });
        }
      }
    );
    request.send(formData);
  }

  // get printing order data
  getPrintingCompletedOrdersApiCall = async (token: string) => {
    //this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrintingOrderAPiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrintingOrderAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return;
  };

  async getAttachmentsAPICall() {

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    // this.setState({ loading: 1 });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAttachmentCallId = requestMessage.messageId;

    let finalUrl = ("" + configJSON.getUploadFilesEndPoint)
      .split("account_id")
      .join(this.state.id);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
export default (NewPrintController);
