// @ts-nocheck
import React from "react";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import PrivacyPolicyController, {
  Props
} from "./PrivacyPolicyController.Web";
// Customizable Area End
import Header from "./Header.Web";
import Footer from "./Footer.Web";

class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPrivacyPolicyContent();
}

  render() {
    const { classes } = this.props;
    return (
      <Grid
      container
      direction="row"
      justify="center"
      className={classes.parentCont}
    >
      <Header />
      <Grid item xs={12}/>
      <div className="privacy">
      <Grid item xs={12} className={classes.privacyContent}>
        <div className="privacyHead">
        <div className={classes.parentPrivacy}>
        <p className={classes.privacyHeading}>Privacy Policy</p>
        <p className={classes.dateContent}>Effective Date:  28-Aug-2021</p>
        </div>
        </div>
        <div>
        <div className={classes.privacyContentData} dangerouslySetInnerHTML={{ __html: this.state.privacyContent }} />
        </div>

      </Grid>
      </div>
      <Footer />
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  privacyContent: {
    width: "1024px",
    display: "inline-block",
    marginLeft: "auto",
    marginRight: "auto",
    flexBasis: "inherit"
    // padding:"20px 20px 20px 20px",
  },
  returnContent: {
    textAlign:"center",
    textDecoration: "underline"
  },
  privacyHeading: {
     fontSize: "20px",
     fontWeight: "bold"
  },
  dateContent: {
    textAlign: "right"
  },
  parentPrivacy:{
    display: "flex",
    justifyContent: "space-between",
    fontFamily: 'Segoe UI'
    // marginTop: "120px" 
  },
  privacyContentData: {
    fontFamily: 'Segoe UI'
}
});

export default withRouter(withStyles(styles, { withTheme: true })(PrivacyPolicy));
