// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
import {
  ToastAndroid,
  Platform
} from "react-native";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouteProps } from "react-router";

export type Props = StyledProps & RouteProps & {
  id: string;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  currentCountryCode: any;
  loading: boolean;

  username: string;
  email: string;
  address: string;
  lat: string;
  lng: string;
  id: string;
  authToken: string;
  filePath: any,
  fileName: string;
  fileType: any;
  imageUri: any;
  userType: string;
  profileImagePath: string,
  isDeleted: boolean,
  emailInputDisable: boolean;
  isOtpSentEmail: any;
  showEmailOtpModal: boolean;
  OTP: string;
  counter: number;
  otpError: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EditUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  emailReg: RegExp;
  apiUpdateProfileCallId: String = "";
  apiDeleteProfileImageCallId: String = "";
  apiGetAttachmentCallId: string = "";
  apiEmailOtpCallId: String = "";
  apiVerifyEmailOTPCallId: String = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "🇮🇳 +91",
      enableField: false,
      loading: false,
      username: "",
      email: "",
      address: "",
      lat: null,
      lng: null,
      id: "",
      authToken: "",
      filePath: null,
      fileName: "",
      fileType: "",
      imageUri: "",
      userType: "",
      profileImagePath: "",
      isDeleted: false,
      emailInputDisable: true,
      isOtpSentEmail: false,
      showEmailOtpModal: false,
      OTP: "",
      counter: 120,
      otpError: ''

    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.phoneReg = new RegExp(/^[0-9]+$/);
    this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiUpdateProfileCallId) {
        this.setState({ loading: false, emailInputDisable: true });
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {

          this.setState({
            imageUri: null,
            fileName: null,
            fileType: null,
            file: null,
            profileImagePath: responseJson.data.attributes.profile_image_path ? responseJson.data.attributes.profile_image_path : "",
            filePath: null,
            isDeleted: false
          }, async () => {

            this.notifyMessage("Profile Updated Successfully");

            let data: any = {}
            data.token = this.state.authToken;
            data.phone = responseJson.data.attributes.full_phone_number;
            data.email = responseJson.data.attributes.email;
            data.name = responseJson.data.attributes.name;
            data.id = this.state.id;
            data.address = this.state.address;
            data.user_type = this.state.userType;
            data.profile_image_path = responseJson.data.attributes.profile_image_path ? responseJson.data.attributes.profile_image_path : "";
            data.is_two_step_verification = responseJson.data.attributes.is_two_step_verification;

            // if(data.profile_image_path){
            const requestMessage = new Message(
              getName(MessageEnum.UserProfilePictureUpdateMessage)
            );
            requestMessage.addData(
              getName(MessageEnum.UserProfilePictureDataMessage),
              data.profile_image_path
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            // }
            await StorageProvider.remove('loginData')
            await StorageProvider.set('loginData', JSON.stringify(data));

            this.props.showNotification("Profile Updated Successfully", "success");

          })
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          const accountBlockValidate = responseJson.errors[0].message;
          if (validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
            // this.props.showNotification("Account not found", "error");
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          }
          this.sendAPIFailMessage();
        }

      } else if (apiRequestCallId === this.apiDeleteProfileImageCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
          //TODO: Need to uncomment this to go with the login flow
          let data: any = {}
          data.token = this.state.authToken;
          data.phone = responseJson.data.attributes.full_phone_number;
          data.email = this.state.email;
          data.name = this.state.username;
          data.id = this.state.id;
          data.address = this.state.address;
          data.profile_image_path = "";
          data.user_type = this.state.userType;
          data.is_two_step_verification = responseJson.data.attributes.is_two_step_verification;

          await StorageProvider.remove('loginData')
          await StorageProvider.set('loginData', JSON.stringify(data));

          this.props.showNotification(responseJson.meta.message, "success");
          //   Alert.alert();

          this.setState({
            profileImagePath: null,
            filePath: null,
            fileName: null,
            fileType: null,
            imageUri: null,
            file: null,
            isDeleted: true
          },
            () => {
              const requestMessage = new Message(
                getName(MessageEnum.UserProfilePictureUpdateMessage)
              );
              requestMessage.addData(
                getName(MessageEnum.UserProfilePictureDataMessage),
                data.profile_image_path
              );
              runEngine.sendMessage(requestMessage.id, requestMessage);
            });
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          // const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          // const accountBlockValidate = responseJson.errors[0].message;
          // if (validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
          //   // this.props.showNotification("Account not found", "error");
          //   setTimeout(() => {
          //     this.props.history.push('/PhoneLoginWeb');
          //   }, 1000);
          // }
          // this.sendAPIFailMessage();
        }

      } else if (apiRequestCallId === this.apiEmailOtpCallId) {
        if (responseJson) {
          this.props.hideLoader();
          this.checkOtpSend("email")
          this.redirectCheck()

        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiVerifyEmailOTPCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.message) {
          // this.changePhoneApiCall();
          this.setState({
            showEmailOtpModal: false,
            emailInputDisable: false,
            OTP: ""
          })
        } else {
          this.props.showNotification(responseJson?.errors && responseJson?.errors[0]?.pin || 'Invalid OTP for Phone Number', "error")
          // this.parseApiErrorResponse(responseJson);
          return
          // this.sendAPIFailMessage();
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async checkOtpSend(type: any) {
    if (type === 'email') {
      this.setState({ isOtpSentEmail: true })
    }
  }

  async redirectCheck() {
    if (this.state.isOtpSentEmail === true) {
      this.setState({ showEmailOtpModal: true })
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };



  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  deleteProfileImageApiCall() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser
    };

    const data = {
      user_type: 'Customer',
      account_id: this.state.id
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProfileImageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (configJSON.deleteProfileAPIEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
  updateUserProfileCall() {

    let name: any = this.state.username;
    let error: any = "";
    error = this.validateName(name);

    if (error) {
      this.props.showNotification(error, "error");
      return;
    }

    let emailID: any = this.state.email;


    error = this.validateEmail(emailID);
    if (error) {
      this.props.showNotification(error, "error");
      return;
    }


    let address: any = this.state.address;

    error = this.validateAddress(address);
    if (error) {
      this.props.showNotification(error, "error");
      return;
    }
    this.props.showLoader();
    let formData = new FormData();
    formData.append('token', this.state.authToken);
    formData.append('data[name]', this.state.username);
    formData.append('data[new_email]', this.state.email);
    formData.append('data[address]', this.state.address);
    formData.append('data[latitude]', this.state.lat);
    formData.append('data[longitude]', this.state.lng);

    if (typeof this.state.fileName != 'undefined' && this.state.fileName) {
      formData.append('data[image]', this.state.imageUri);
    }

    this.setState({ loading: true });

    const header = {
      "accept": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (configJSON.updateProfileAPIEndPoint + this.state.id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  checkBothVerify() {
    this.validateEmailOtpCall()
  }

  validateOTPNumber(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "OTP " + configJSON.errorBlankField;
    } else if (!this.isValid4DigitPOTP(otp)) {
      error = configJSON.errorOTPNotValid;
    }
    return error;

  }
  isValid4DigitPOTP(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 4
    );
  }

  validateName(name: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(name)) {
      error = "Name " + configJSON.errorBlankField;
    } else if (!this.isValidName(name)) {
      error = configJSON.errorNameNotValid;
    }
    return error;
  }

  isValidName(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 1
    );
  }

  validateAddress(name: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(name)) {
      error = "Address " + configJSON.errorBlankField;
    } else if (!this.isValidName(name)) {
      error = configJSON.errorNameNotValid;
    }
    return error;
  }

  validateEmail(email: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(email)) {
      error = "Email " + configJSON.errorBlankField;
    } else if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }
    return error;
  }
  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }


  isValidAddress(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length < 10
    );
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }


  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  notifyMessage(msg: string) {
    if (Platform.OS === 'android') {
      ToastAndroid.show(msg, ToastAndroid.SHORT)
    }
  }

  async sendEmailOtp() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    this.attrs = {
      email: this.state.email

    }
    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailOtpCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (configJSON.emailOtpEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async validateEmailOtpCall() {

    let OTP: any = this.state.OTP;
    const validateOtpError = this.validateOTPNumber(OTP);
    if (validateOtpError) {
      this.setState({ otpError: validateOtpError });
      return;
    }

    this.setState({ loading: true });
    this.props.showLoader();
    const header = {
      "Content-Type": configJSON.contentType
    };

    const httpBody = {
      token: this.state.authToken,
      pin: this.state.OTP,
      email: this.state.email,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyEmailOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOtpSettingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
