//@ts-nocheck
import React, { useEffect, useRef } from "react";
const inputDefaultStyles = {
  width: 30,
  height: 30,
  textAlign: "center",
  margin: 3,
  color: "black",
  border: "1px solid rgb(143 146 161/.4)",
  boxSizing: "border-box",
  borderRadius: 0,
};
const UPPER_A_KEYCODE = 65;
const LOWER_Z_KEYCODE = 122;
// const UPPER_Z_KEYCODE = 90;
const ZERO_KEYCODE = 48;
const NINE_KEYCODE = 57
const InputBox = ({
  focus,
  autoFocus,
  disabled,
  value,
  onInputFocus,
  index,
  secure,
  inputStyles={color: "black"},
  otpType,
  ...rest
}) => {
  const input = useRef(null);
  const componentMounted = useRef(false);
  useEffect(() => {
    // When component mounts
    if (autoFocus && focus) {
      input.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // When component focus updates
    if (componentMounted.current && focus) {
      input.current.focus();
    }
    componentMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);
  const handelInputFocus = (event) => onInputFocus(index, event);
  let inputType = "number";
  let inputMode;
  if (secure) {
    inputType = "password";
    inputMode ="numeric"
  } else if (otpType === "number") {
    inputType = "number";
    inputMode ="numeric"
  }
  return (
    <input
      style={{ ...inputDefaultStyles, ...inputStyles }}
      type={inputType}
      maxLength="1"
      ref={input}
      disabled={disabled}
      onFocus={handelInputFocus}
      value={value || ""}
      inputMode={inputMode}
      {...rest}
    />
  );
};
const Input = React.memo(InputBox);
class OtpInput extends React.PureComponent {
  state = {
    activeInput: 0,
  };
  getOtpValue = () =>
    this.props.value ? this.props.value.toString().split("") : [];
  // Helper to return OTP from input
  handleOtpChange = (otp) => {
    let otpValue = otp.join("");
    this.props.onOTPChange(otpValue);
  };
  // Focus on input by index
  focusInput = (input) => {
    const nextActiveInput = Math.max(
      Math.min(this.props.OTPLength - 1, input),
      0
    );
    this.setState({ activeInput: nextActiveInput });
  };
  /**
   * @typedef {"next" | "prev"} FocusDirections
   * @param {FocusDirections} direction
   */
  focusInputByDirection = (direction = "next") => {
    this.focusInput(direction === "next" ? this.state.activeInput + 1 : this.state.activeInput - 1);
  };
  // Change OTP value at focused input
  changeActiveInputValue = ([nextValue]) => {
    const otp = this.getOtpValue();
    otp[this.state.activeInput] = nextValue;
    this.handleOtpChange(otp);
  };
  // Handle pasted OTP
  handleOnPaste = (e, data) => {
    e.preventDefault();
    const otp = this.getOtpValue();
    // Get pastedData in an array of max size (num of inputs - current position)
    const clipboardData = e.clipboardData
      .getData("text/plain")
      .slice(0, this.props.OTPLength - this.state.activeInput)
      .split("");
    // Paste data from focused input onwards
    // eslint-disable-next-line no-plusplus
    for (let pos = 0; pos < this.props.OTPLength; ++pos) {
      if (pos >= activeInput && clipboardData.length > 0) {
        otp[pos] = clipboardData.shift();
      }
    }
    // Pass copied value through onChange rules
    let filteredOtpValue = [otp.length];
    let validCharIndex = 0;
    for (let charIndex = 0; charIndex < otp.length; ++charIndex) {
      if (this.isValidateChar(otp[charIndex])) {
        filteredOtpValue[validCharIndex] = otp[charIndex];
        validCharIndex++;
      }
    }
    this.handleOtpChange(filteredOtpValue);
  };
  isValidateChar = (char) => {
    switch (this.props.otpType) {
      case "number":
        return !(
          char.charCodeAt(0) > NINE_KEYCODE || char.charCodeAt(0) < ZERO_KEYCODE
        );
      case "alpha":
        return !(
          char.charCodeAt(0) > LOWER_Z_KEYCODE ||
          char.charCodeAt(0) < UPPER_A_KEYCODE
        );
      case "alphanumeric":
        return !(
          char.charCodeAt(0) > LOWER_Z_KEYCODE ||
          char.charCodeAt(0) < ZERO_KEYCODE
        );
      default:
        return true;
    }
  };
  handleOnChange = (e) => {
    if (this.isValidateChar(e.target.value)) {
      this.changeActiveInputValue(e.target.value);
      this.focusInputByDirection("next");
    }
  };
  // Handle cases of backspace, delete, left arrow, right arrow
  handleOnKeyDown = (e) => {
    switch (e.key) {
      case "Backspace":
        e.preventDefault();
        this.changeActiveInputValue("");
        if(e.target.id  == 5){
          if(e.target.value){
            return
          }else{        
            this.focusInputByDirection("prev");
          }
        }
        this.focusInputByDirection("prev");
        break;
      case "Delete":
        e.preventDefault();
        this.changeActiveInputValue("");
        break;
      case "ArrowLeft":
        e.preventDefault();
        this.focusInputByDirection("prev");
        break;
      case "ArrowRight":
        e.preventDefault();
        this.focusInputByDirection("next");
        break;
      default:
        break;
    }
  };
  handelOnInput = (e) => {
    if (e.target.value.length > 1) {
      e.preventDefault();
      this.focusInputByDirection("next");
    }
  };
  onInputFocus = (index, event) => {
    this.setState({ activeInput: index });
    event.target.select();
  };
  // Needs to be memorized
  renderInputs = () => {
    const otp = this.getOtpValue();
    const inputs = [];
    for (let index = 0; index < this.props.OTPLength; index++) {
      inputs.push(
        <Input
        //   className={this.props.inputClassName}
        //   inputStyles={this.props.inputStyles}
          key={index}
          
          focus={this.state.activeInput === index}
          value={otp[index]}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeyDown}
          onInput={this.handelOnInput}
          onPaste={this.handleOnPaste}
          onInputFocus={this.onInputFocus}
          index={index}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          secure={this.props.secure}
          otpType={this.props.otpType}
          id={index}
        />
      );
    }
    return inputs;
  };
  render() {
    return (
      <div
        className={`${this.props.className}`}
        data-testid="otp-input-root"
      >
        {this.renderInputs()}
      </div>
    );
  }
}
// OtpInput.propTypes = {
//   className: PropTypes.string,
//   inputClassName: PropTypes.string,
//   OTPLength: PropTypes.number,
//   onChange: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   autoFocus: PropTypes.bool,
//   secure: PropTypes.bool,
//   otpType: PropTypes.oneOf(["number", "alpha", "alphanumeric", "any"]),
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   inputStyles: PropTypes.object,
//   style: PropTypes.object
// };
// OtpInput.defaultProps = {
//   className: "",
//   inputClassName: "",
//   OTPLength: 4,
//   onOTPChange: () => {},
//   disabled: false,
//   secure: false,
//   autoFocus: false,
//   value: "",
//   otpType: "any",
//   inputStyles: {},
//   style: {}
// };
export default OtpInput;