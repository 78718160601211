// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End
import { RouterProps } from "react-router";
export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  openDialog: any;
  classes: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  currentCountryCode: any;
  loading: boolean;
  username: string;
  profileImagUri: string;
  authToken: String;
  id: String;
  basePrintPrices: any;
  selectedPaperTypeForPrice: any
  selectedPaperTypes: any
  priceModal: any
  isOtpSentMobile: any
  phone: any,
  email: any,
  account_type:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  apiGetOTPLCallId: String = "";
  apiGetBasePriceApiCallID: String = "";
  apiMobileOtpCallId: String = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.UserProfilePictureUpdateMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "🇮🇳 +91",
      enableField: false,
      loading: false,
      username: "",
      profileImagUri: "",
      basePrintPrices: [],
      selectedPaperTypeForPrice: "",
      selectedPaperTypes: [],
      priceModal: false,
      isOtpSentMobile: false,
      phone: null,
      email: null,
      account_type: null
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.phoneReg = new RegExp(/^[0-9]+$/);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.UserProfilePictureUpdateMessage) === message.id) {
      var profile_image_path = message.getData(
        getName(MessageEnum.UserProfilePictureDataMessage)
      );
      this.setState({ profileImagUri: profile_image_path });
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode: selectedCode,
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiDeleteProfileCallId) {
        if (responseJson && responseJson.Message) {
          this.props.hideLoader();
          this.props.showNotification(responseJson.Message, "success");
          const keys = await StorageProvider.getKeys();
          await StorageProvider.removeMultiple(keys)
          await StorageProvider.set("user_type", this.state.user_type);
          setTimeout(() => {
            this.props.history.push("/PhoneLoginWeb");
          }, 1000);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.apiGetBasePriceApiCallID) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          await this.setState({ basePrintPrices: responseJson?.data });
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.apiMobileOtpCallId) {
        if (responseJson) {
          this.props.hideLoader();
          this.checkOtpSend("mobile")
          this.redirectCheck()
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          this.props.hideLoader();

        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );

      this.props.showNotification(AlertBodyMessage, "error");
    }
  }

  async checkOtpSend(type: any) {
    if (type === 'mobile') {
      this.setState({ isOtpSentMobile: true })
    }
  }
  async redirectCheck() {
    if (this.state.isOtpSentMobile === true) {
      const options = {
        pathname: 'ChangePhoneOTPVerify',
        state: { calledFrom: "deleteUserAccount", email: this.state.email, phone: this.state.phone }
      }
      this.props.history.push(options);
    }
  }

  async accountDeleteApiCall() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProfileCallId = requestMessage.messageId;

    let deleteAccountEndPoint = ('' + configJSON.deleteAccountEndPoint + '' + this.state.id)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (deleteAccountEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getBasePrices() {

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.setState({ loading: true });
    this.props.showLoader();


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBasePriceApiCallID = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBasePrintPrices)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  async sendMobileOtp() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.attrs = {
      full_phone_number: this.state.phone,
      account_deletion:true,
    }
    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMobileOtpCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (configJSON.mobileOtpEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  // Customizable Area End
}
