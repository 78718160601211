Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentType = "application/json";
exports.getApiMethodType = "GET";
exports.getNearByServiceProviderAPiEndPoint = "account_block/accounts/merchants/get_nearby_merchant?lat=currLat&lng=currLng&radius=currRadius";
exports.placeOrderAPiEndPoint = "bx_block_ordermanagement/orders";
exports.postAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";
exports.btnExampleTitle = "CLICK ME";
exports.getBasePrintPrices = "/account_block/accounts/merchants/get_all_printing_prices"
exports.getMerchantPrintPrice = "/account_block/accounts/merchants/merchant_get"


//labels
exports.navBarName = "David Wong";
exports.findShopsHeading = "Find Nearby Shops";
exports.continueBtn = "CONTINUE TO PAY";
exports.modalHeading = "All Shops";
exports.modal2Heading = "Filter Shops";
exports.printerName = "Printer One";
exports.printerAddress = "E-3130 Sudama Nagar, Indore";
exports.printerDistance = "2.2 miles";
exports.Navigate = "Navigate";
exports.modalBtnText = "APPLY";
exports.searchRadius = "Search Radius";
exports.printDetailsOfPrintSec = "Price Details of PrintSEC"
exports.bothSides = "Both Sides are calculated as two pages";
exports.proceedToPrint = "Proceed to Print"


// Customizable Area End