// @ts-nocheck
import React from "react";
import withRouter from "react-router/withRouter";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  userLogo,
  storeLogo,
  colorLogo,
  group,
  group2,
  group3,
  group4,
  group5,
  group6,
  group8,
  backArrowIcon
} from "./assets";
import OTPInputView from "../../../components/src/OtpInput.web";
import StorageProvider from '../../../framework/src/StorageProvider';
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import PhoneLoginControllerWeb, {
  Props,
  configJSON
} from "./PhoneLoginController.Web";
import "./css/style.css";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { APP_FONTS } from "../../../components/src/AppFonts";
// Customizable Area Start
// Customizable Area End
class PhoneLoginWeb extends PhoneLoginControllerWeb {
  // Customizable Area Start
  otpReference = React.createRef(null);
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollRef = React.createRef();
    this.state = {
      otp: "",
      currentCountryCode: "91",
      newValue: "",
      value: "Customer",
      alertMessage: "",
      alertOpen: false,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangePasscode = (otp: any) => {
    this.setState({ otp });
  }
  scrollToTop = () => {
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }
  getOtp(otp: string) {
    this.setState({
      otp: otp,
      isClearCalled: false,
      otpError: false
    });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    StorageProvider.set("user_type", newValue)
  };
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("user_type").then((user_type) => {
      if (user_type) {
        this.setState({
          value: user_type
        });
      }
    });
    // Customizable Area End
  }

  // Customizable Area Start
  handleAlert = txt => {
    this.setState({ alertMessage: txt }, () => {
      this.setState({ alertOpen: true })
    })
  }

  closeAlert = () => {
    this.setState({ alertOpen: false })
  }
  reset = () => {
    // this.resendOTP();
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={`container-box`}
        ref={this.scrollRef}
      >
        <Grid container className="bg-auth login-adjest">
          <Grid item xs={12} sm={6} className="left-side pb-230">
            <span className="login-back" onClick={() => this.props.history.push("/")}>
              <span><img src={backArrowIcon} /></span>
              <Typography className={`main-head mt-25 ${classes.sofiaFont}`}>
                {configJSON.labelLogin}
              </Typography>
            </span>
            <Typography className={`label-name ${classes.sofiaFont}`} style={{ marginTop: 20 }}>
              {configJSON.labelLoginAs}
            </Typography>
            <Button onClick={(event) => {
              this.handleChange(event, "Customer");
            }}
              className={
                value === "Customer"
                  ? `auth-btn light ${classes.sofiaFont}`
                  : `auth-btn  ${classes.sofiaFont}`
              }
              variant="contained"
            >
              {configJSON.labelIamUser}
              <img src={userLogo} />
            </Button>
            <Button onClick={(event) => {
              this.handleChange(event, "Merchant");
            }}
              className={
                value === "Merchant"
                  ? `auth-btn light ${classes.sofiaFont}`
                  : `auth-btn  ${classes.sofiaFont}`
              }
              variant="contained"
            >
              {configJSON.labelIamShoOwner}
              <img src={storeLogo} />
            </Button>
            <Grid>
              <div className="enter-log">
                <Typography className={`enter-your-phone mt-25 ${classes.sofiaFont}`}>
                  {configJSON.labelEnterYourPhone}
                </Typography>
              </div>
              <Grid className="phoneNumberCont">
                <Typography className={`label-name ${classes.sofiaFont}`}>
                  {configJSON.labelPhoneNumber}
                </Typography>
                <div className="login_number_cont logn">
                  <CountryCodeSelector
                    isAllFlagAvailable={true}
                    allowPropChange={true}
                    id={"CountryCodeSelector"} //Merge Engine::From BDS
                    placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
                    disable={false}  //Merge Engine::From BDS
                    value={this.state.currentCountryCode} //Merge Engine::From BDS
                  />
                  <div className="log-num">
                    <TextField
                      type="number"
                      variant="outlined"
                      style={{ width: '250px' }}
                      // className="inputNumberBox"
                      maxLength={10}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                      }}
                      value={this.state.phoneNumber}
                      onChange={(e) => {
                        this.onChangePhoneNumber(e)
                        this.setState({ phoneNumberError: false })
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <p className={`error-label ${classes.sofiaFont}`}>{this.state.phoneNumberError}</p>

              <Typography className={`label-name mt-25 ${classes.sofiaFont}`}>
                {configJSON.labelEnterPasscode}
              </Typography>
              <Grid className="otp-input-box">
                <OTPInputView OTPLength={6} value={this.state.otp} className="pass-code-field" otpType={"number"}
                  onOTPChange={(otp) => {
                    this.setState({ otp: otp });
                  }}
                  secure={true} />
              </Grid>
              <p className={`error-label  ${classes.sofiaFont}`}>
                {this.state.otpError}
                {this.state.errorMessage && this.state.errorMessage.failed_login}
              </p>

              <p className={`have-acc mt-0  ${classes.sofiaFont}`}
                onClick={() => {
                  this.scrollToTop()
                  this.props.history.push("ForgotPasscode")
                }}
              >
                {configJSON.labelForgotPassword}
              </p>

              <Grid className="btn-box">
                <Button variant="contained" style={{ height: '42px' }} className={`site-lg-btn  ${classes.sofiaFont}`}
                  onClick={() => this.loginAPICall()}
                >
                  {configJSON.labelLogin}
                </Button>

                <Button variant="contained" className={`site-wht-btn ${classes.sofiaFont}`}
                  onClick={() => {
                    this.scrollToTop()
                    this.props.history.push("/SignUp")
                  }}
                >
                  {configJSON.labelbtnCreateAccount}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className="right-side">
            <img src={colorLogo} onClick={() => this.props.history.push("/")} className="site-logo" />
            <Grid className="right-icons mt-70 log-ico">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
                <img src={group3} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
                <img src={group2} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
                <img src={group6} className={"right-alert-img"} />
              </div>
            </Grid>
            <Grid className="right-icons log-ico">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
                <img src={group4} className={"right-alert-img "} />
              </div>
              <div className={"icon_cont "}>
                <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
                <img src={group5} className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
                <img src={group} className={"right-alert-img"} />
              </div>
            </Grid>
            <img src={group8} className="group-logo bt-80" />
          </Grid>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

const PhoneLoginWebWithConfirmBox = withConfirmBox(PhoneLoginWeb);
const PhoneLoginWebWithLoader = withLoader(PhoneLoginWebWithConfirmBox)
const PhoneLoginWebWithToast = withToast(PhoneLoginWebWithLoader)
// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  sofiaFont: {
    fontFamily: APP_FONTS.SofiaProRegular
  }
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(PhoneLoginWebWithToast));
