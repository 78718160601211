// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider";
export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  openDialog: any;
  classes: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  username: string;
  profileImagUri: string;
  authToken: String;
  id: String;
  phone: any;
  email: any;
  isOtpSentMobile: any;
  isOtpSentEmail: any;
  selectedPath: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MerchantSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailOtpCallId: String = "";
  apiMobileOtpCallId: String = "";
  c;
  apiDeleteProfileCallId: String = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      username: "",
      profileImagUri: "",
      authToken: "",
      id: "",
      phone: null,
      email: null,
      isOtpSentMobile: false,
      isOtpSentEmail: false,
      selectedPath: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiDeleteProfileCallId) {
        if (responseJson && responseJson.Message) {
          this.props.hideLoader();
          this.props.showNotification(responseJson.Message, "success");
          const keys = await StorageProvider.getKeys();
          await StorageProvider.removeMultiple(keys);
          await StorageProvider.set("user_type", this.state.user_type);
          setTimeout(() => {
            this.props.history.push("/PhoneLoginWeb");
          }, 1000);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.apiEmailOtpCallId) {
        if (responseJson) {
          this.props.hideLoader();
          this.checkOtpSend("email");
          this.redirectCheck();
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.apiMobileOtpCallId) {
        if (responseJson) {
          this.props.hideLoader();
          this.checkOtpSend("mobile");
          this.redirectCheck();
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );

      this.props.showNotification(AlertBodyMessage, "error");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async checkOtpSend(type: any) {
    if (type === "mobile") {
      this.setState({ isOtpSentMobile: true });
    }
    if (type === "email") {
      this.setState({ isOtpSentEmail: true });
    }
  }

  async redirectCheck() {
    if (this.state.isOtpSentMobile === true) {
      const options = {
        pathname: "ChangePhoneOTPVerify",
        state: {
          calledFrom: "deleteSpAccount",
          email: this.state.email,
          phone: this.state.phone
        }
      };
      this.props.history.push(options);
    }
  }

  async accountDeleteApiCall() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProfileCallId = requestMessage.messageId;

    let deleteAccountEndPoint =
      "" + configJSON.deleteAccountEndPoint + "" + this.state.id;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      deleteAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async sendMobileOtp() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };

    this.attrs = {
      full_phone_number: this.state.phone,
      account_deletion:true,
    };
    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMobileOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mobileOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async sendEmailOtp() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };

    this.attrs = {
      email: this.state.email
    };
    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  // Customizable Area End
}
