//@ts-nocheck
import React from "react";
// Customizable Area Start
import MerchantInvoicesController, {
  Props,
} from "./MerchantInvoicesController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import { Button, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { appIcon ,closesvg } from "./assets";
import './index.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Dialog from '@material-ui/core/Dialog';
import moment from"moment";

class MerchantInvoicesDwonload extends MerchantInvoicesController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const { open, handleClose ,invoicePdfUrl ,createTransaction ,rowData } = this.props;
    const {
      no_of_greyscale_copies,
      no_of_coloured_copies,
      status, 
      payable_amount,
      invoice_no,
       start_date,
       end_date
    } = rowData;
    return (
      <>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="invoiceDialog"
          >
            <div className="invoiceSection" ref={(ref) => {
              this.componentRef = ref;
            }}>
              <div className="invoiceBg">
                <div className="closeIcon" >
                  <img src={closesvg} alt="" onClick={handleClose} />
                </div>
                <Grid className="invoiceTopArea">
                  <Box className="invoiceLogoArea">
                    <img
                      src={appIcon}
                      alt=""
                      className=""
                      onClick={this._handleAllShopsModal}
                    />
                    <Typography variant="h4">PRINTSEC PRIVATE LIMITED</Typography>
                  </Box>
                  <Box className="invoicedatedArea">
                    <div className="invoiceDetails">
                      <Typography variant="subtitle1">Billing Date</Typography>
                      <Typography variant="h6" >{moment(end_date).format('DD-MM-YYYY')}</Typography>
                    </div>
                    <div className="invoiceDateDetails">
                      <div>
                        <Typography variant="subtitle1">Billing Start Date</Typography>
                        <Typography variant="h6">{moment(start_date).format('DD-MM-YYYY')}</Typography>
                      </div>
                      <div>
                        <Typography variant="subtitle1">Billing End Date</Typography>
                        <Typography variant="h6">{moment(end_date).format('DD-MM-YYYY')}</Typography>
                      </div>
                    </div>
                    <div className="invoiceDetails">
                      <div>
                        <Typography variant="subtitle1">Invoice Id</Typography>
                        <Typography variant="h6">{invoice_no}</Typography>
                      </div>
                      <div>
                        <Typography variant="subtitle1">Status</Typography>
                        <Typography variant="h6"><span className={classes[status]}>{status}</span></Typography>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid className="invoiceHeaderBottomArea">
                </Grid>
              </div>
              <div className="invoiceInTable">
                <TableContainer>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Total No of GreyScale Copies</TableCell>
                        <TableCell align="center">Total No of Coloured Copies</TableCell>
                        <TableCell align="center">Bill Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{no_of_greyscale_copies}</TableCell>
                        <TableCell align="center">{no_of_coloured_copies}</TableCell>
                        <TableCell align="center">{payable_amount}</TableCell>
                      </TableRow>
                      <TableRow>

                        <TableCell align="right" colSpan={2}>CGST</TableCell>
                        <TableCell align="center">50</TableCell>

                      </TableRow>
                      <TableRow>

                        <TableCell align="right" colSpan={2}>SGST</TableCell>
                        <TableCell align="center">100</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell align="right" colSpan={2}>Total Amount</TableCell>
                        <TableCell align="center">{payable_amount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell style={{ padding: '20px 0' }} colSpan={2} align="right" >
                          { status !== "paid" &&
                          <Button
                            className={classes.findPrintersBtn + ' print-hidden'}
                            onClick={() => { createTransaction() }}
                          >
                            Pay Now
                           </Button>
                          }
                          <Button
                            className={classes.downloadBtn + ' print-hidden'}
                            onClick={() => {window.open(invoicePdfUrl, '_blank')}}
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Dialog>
        </div>
      </>
    );
  }
}
// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    findPrintersBtn: {
      color: "white",
      margin: "5px 5px",
      fontSize: "14px",
      maxWidth: "300px",
      textTransform: "capitalize",
      backgroundColor: '#ffa726',
      '&:hover': {
        backgroundColor: '#ffa726',
      },
    },
    downloadBtn: {
      color: "white",
      margin: "5px 5px",
      fontSize: "14px",
      maxWidth: "300px",
      textTransform: "capitalize",
      backgroundColor: '#32795f',
      '&:hover': {
        backgroundColor: "#32795f",
      },
    },
    headingCont: {
      width: "100%",
      margin: "0 auto",
    },
    labelText: {
      fontSize: 22,
      margin: "20px 0 10px 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    labelContent: {
      fontSize: "12px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    backIcon: {
      width: 40,
      height: 40,
      marginLeft: 15,
      cursor: "pointer",
      objectFit: "contain",
    },
    billingtableCellhead: {
      fontSize: "18px",
      fontWeight: "700",
    },
    billingtableCell: {
      fontSize: "16px",
      fontWeight: "400",
    },
    paid: {
      backgroundColor: "rgb(25,135,84)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    unpaid: {
      backgroundColor: "rgb(255 ,193,7)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    pending: {
      backgroundColor: "rgb(255 ,193,7)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      minWidth: "72px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    columnHeaderWrapper: {
      margin: '0 auto',
      width: '92%',
      height: '80vh',
    },
    failed: {
      backgroundColor: "rgb(255 ,0,0)",
      padding: "6px",
      borderRadius: "4px",
      lineHeight: "26px",
      color: "white",
      minWidth: "70px",
      textAlign: "center",
    },
  });
// Customizable Area End
const MerchantInvoicesWithConfirmBox = withConfirmBox(MerchantInvoicesDwonload);
const MerchantInvoicesWithLoader = withLoader(MerchantInvoicesWithConfirmBox);
const MerchantInvoicesWithToast = withToast(MerchantInvoicesWithLoader);
// export default withRouter(MerchantInvoicesWithToast);
export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantInvoicesWithToast)
);
