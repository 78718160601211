//@ts-nocheck
import React from "react";
// Customizable Area Start
// Customizable Area End
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import moment from "moment";
import MerchantWalletController, {
  Props,
  configJSON
} from "./MerchantWalletController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import {
  detailsIcon,
  historyIcon
} from "./assets";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";


class MerchantWallet extends MerchantWalletController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          id: loginData.id,
          authToken: loginData.token,
        });
      }
    });
    await this.getMyEarnings();
    await this.getTotalEarnings();
    this.getAdminCommisionList();
    // Customizable Area End
  }

  // Customizable Area Start
  filterOrdersTapped = () => {
    this.setState({
      showFilter: !this.state.showFilter,
      showDatePicker: false,
    });
  };

  closeModal = () => {
    this.setState({
      showFilter: !this.state.showFilter,
      showDatePicker: false,
      isVisibleDropDown: false,
    });
  };

  _onRangeTagTapped = (val: string) => {
    this.setState({
      selectedRangeTag: val,
    }, () => {
      if (val === "today") {
        let startDate = moment()
          .utc()
          .format("yyyy-MM-DD");
        let endDate = startDate;
        this.setState({
          startDate: startDate,
          endDate: endDate,
        });
      } else if (val === "this_week") {
        let startDate = moment()
          .startOf("week")
          .format("yyyy-MM-DD");
        let endDate = moment()
          .endOf("week")
          .format("yyyy-MM-DD");
        this.setState({
          startDate: startDate,
          endDate: endDate,
        });
      } else if (val === "this_month") {
        let startDate = moment()
          .startOf("month")
          .format("yyyy-MM-DD");
        let endDate = moment()
          .endOf("month")
          .format("yyyy-MM-DD");
        this.setState({
          startDate: startDate,
          endDate: endDate,
        });
      }
    });
  };

  _handleAllShopsModal = () => {
    this.setState({ allShopsModal: !this.state.allShopsModal });
  };

  _filterAppliedClicked = () => {
    if (this.state.endDate < this.state.startDate) {
      this.props.showNotification("Select valid date range", "error");
      return;
    }
    if ((this.state.selectedOrderStatus && this.state.selectedOrderStatus !== 'none') || (this.state.startDate.length > 0 &&
      this.state.endDate.length > 0)) {
      this.setState({ allShopsModal: false }, () => {
        this.getTotalEarnings();
      })
    } else {
      this.props.showNotification("Select any filter to apply", "error");
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.rightContent}>
          <Grid item xs={11} className={classes.headingCont}>
            <div className={classes.mainContainerHead}>
              <div className={classes.headingLeft}>
                <Typography className={classes.labelText}>Wallet</Typography>
                <img
                  src={detailsIcon}
                  alt=""
                  className={classes.backIcon}
                  onClick={this._handleAllShopsModal}
                />
              </div>
              <div className={classes.totalEarningContent} style={{ width: '150px' }}>
                <p className={classes.totalEarningHeading}>
                  Today’s Earnings
                </p>
                <Typography className={classes.totalEarningSubHEading}>
                  {`Rs. ${parseFloat(this.state.todaysEarning).toFixed(2)}`}
                </Typography>
              </div>
            </div>
            <Typography className={classes.labelContent}>
              <span style={{ fontSize: '14px' }}>This is the earned amount. This will take some time to reflect in your account as per the process</span>
            </Typography>
            <Grid container className={classes.completeOrdersCont}>
              {this.state.dataSource.length > 0 && this.state.dataSource.map((order) => {
                return (
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.earningAmount}>{`${parseFloat(order.attributes.earning.amount).toFixed(2)} Rs /-`}</Typography>
                          <Typography className={classes.dateTime}>{order?.attributes?.earning.date_time}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                );
              })}
            </Grid>
          </Grid>
        </Grid>

        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.allShopsModal}
          // open={true}
          onClose={this._handleAllShopsModal}
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                {configJSON.filterHeading}
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
              <Typography className={classes.modalContentText}>
                {configJSON.byStatus}
              </Typography>
              <div className={classes.inputDateCont}>
                <Typography className={classes.modalFromText}>
                  {configJSON.fromText}
                </Typography>
                <input
                  id="date"
                  type="date"
                  max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                  inputRef={this.fromDateRef}
                  className={classes.fromDate}
                  value={this.state.startDate}
                  onChange={(e) => this.setState({ startDate: e.target.value })}

                />
              </div>
              <div className={classes.inputDateCont}>
                <Typography className={classes.modalFromText}>
                  {configJSON.toText}
                </Typography>
                <input
                  id="date"
                  type="date"
                  max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                  className={classes.fromDate}
                  value={this.state.endDate}
                  onChange={(e) => this.setState({ endDate: e.target.value })}

                />
              </div>
              <div className={classes.timeSelectCont}>
                <Button
                  className={classes.timeSelectBtn}
                  style={{ backgroundColor: "#888888", opacity: this.state.selectedRangeTag === 'today' ? 1 : 0.5 }}
                  onClick={() => this._onRangeTagTapped("today")}
                >
                  <img src={historyIcon} style={{ marginRight: 7 }} alt="" />
                  <span style={{ fontSize: '12px' }}>{configJSON.today}</span>
                </Button>
                <Button
                  className={classes.timeSelectBtn}
                  style={{ backgroundColor: "#888888", opacity: this.state.selectedRangeTag === 'this_week' ? 1 : 0.5 }}
                  onClick={() => this._onRangeTagTapped("this_week")}
                >
                  <img src={historyIcon} style={{ marginRight: 7 }} alt="" />
                  <span style={{ fontSize: '12px' }}>{configJSON.thisWeek}</span>
                </Button>
                <Button
                  className={classes.timeSelectBtn}
                  style={{ backgroundColor: "#888888", opacity: this.state.selectedRangeTag === 'this_month' ? 1 : 0.5 }}
                  onClick={() => this._onRangeTagTapped("this_month")}
                >
                  <img src={historyIcon} style={{ marginRight: 7 }} alt="" />
                  <span style={{ fontSize: '12px' }}>{configJSON.thisMonth}</span>
                </Button>
              </div>
              <Button
                className={classes.closeModalBtn}
                style={{ backgroundColor: "#32795f", marginTop: 20 }}
                onClick={() =>
                  this._filterAppliedClicked()
                }
              >
                <span style={{ fontSize: '14px' }}>{configJSON.applyBtnText}</span>
              </Button>
            </div>
          </div>
        </Modal>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          classes={{
            paper: classes.priceModalContainer
          }}
        >
          <DialogContent>
            <div className="cross-icon" onClick={() => this.handleClose()}>
              X
            </div>
            <DialogContentText id="alert-dialog-description">
              <div className={classes.adminPriceHeading}>
                Please find the price list according to the paper size charged by printsec from the customer:
              </div>
              <div style={{ paddingLeft: '15px', color: 'black' }}>
                {this.state.adminPriceList?.data?.map((printing_info) => {
                  return (
                    <div key={printing_info.id} className={classes.adminPriceList} style={{ fontSize: '16px' }}>
                      {printing_info?.page_size} - {printing_info?.paper_type} - Single Side/Rs.{printing_info?.single_side?.colored}(Colored),{printing_info?.single_side?.greyscale}(Greyscale) /page -  Double Side/Rs.{printing_info?.double_side?.colored}(Colored),{printing_info?.double_side?.greyscale}(Greyscale) Page
                    </div>
                  );
                })}
              </div>
              <div className={classes.adminPriceHeading}>
                <p>PrintSEC takes INR {this.state.adminPriceList?.admin_commission_for_greyscale}.00/Page for Mono or Grayscale and INR {this.state.adminPriceList?.admin_commission_for_colored}.00/Page for Colored irrespective of page size as platform fee excluding GST" </p>
                <p>Note: GST collection and compliance is taken care of by PrintSEC without any additional cost to service providers.</p>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  rightContent: {
    position: "relative",
    minHeight: '80%',
    width: '100%',
    background: `#ffffff`,
    borderRadius: 12,
    marginBottom: 40
  },
  headingCont: {
    width: '100%',
    margin: "50px auto 0"
  },
  labelText: {
    fontSize: 22,
    margin: "20px 0 10px 0",
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    color: '#1e1f20'
  },
  labelContent: {
    fontSize: "12px",
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    color: '#1e1f20'
  },
  totalEarningContent: {
    // float: "right",
    fontFamily: APP_FONTS.SofiaProRegular,
    backgroundColor: "#fdd001",
    borderRadius: "12px",
    minWidth: "117px",
    padding: "10px"
  },
  totalEarningHeading: {
    fontFamily: APP_FONTS.SofiaProRegular,
    fontSize: "12px",
    fontWeight: "500",
    color: "#1e1f20",
    margin: "0px !important"
  },
  totalEarningSubHEading: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#1e1f20"
  },
  headingLeft: {
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  backIcon: {
    width: 40,
    height: 40,
    marginLeft: 15,
    cursor: "pointer",
    objectFit: "contain",
  },
  /*Modal Styling */
  modalParent: {
    width: "100%",
    position: "relative",
  },
  modalContent: {
    position: "absolute",
    right: 0,
    borderRadius: "32px 0 0 32px",
    width: "90%",
    maxWidth:'400px',
    minWidth: 270,
    height: "100vh",
    backgroundColor: APP_COLORS.app_theme_yellow_color,
  },
  modalContentCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  continueModalContentCont: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center'
  },
  btnModalCont: {
    width: "100%",
    display: "flex",
    alignItems: 'center',
    flexDirection: "column",
    justifyContent: 'center'
  },
  modalHeadingCont: {
    display: "flex",
    margin: "20px 0",
    alignItems: "flex-end",
    justifyContent: "flex-start",
  },
  modalHeading: {
    fontSize: 22,
    marginLeft: 20,
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: "left",
    color: "#1e1f20",
  },
  printerName: {
    fontSize: 16,
    fontFamily: APP_FONTS.SofiaProRegular,
    color: "#1e1f20",
    marginTop: 10,
    marginBottom: 10,
  },
  printerAddress: {
    fontSize: 15,
    fontFamily: APP_FONTS.SofiaProRegular,
    color: "#8f92a1",
    marginBottom: 10,
  },
  modalContentText: {
    fontSize: 13,
    letterSpacing: 1,
    margin: "20px 0 10px 20px",
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: "left",
    textTransform: "uppercase",
    color: "#1e1f20",
  },
  modalFromText: {
    fontSize: 15,
    width: "20%",
    margin: "0 0 10px 20px",
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: "left",
    color: "#1e1f20",
  },
  closeModalBtn: {
    border: "2px solid #32795f",
    color: "white",
    borderRadius: 12,
    width: "90%",
    margin: " auto",
    fontFamily: APP_FONTS.SofiaProRegular,
    height: 55,
    maxWidth: 300,
  },
  timeSelectBtn: {
    color: "white",
    textTransform: "none",
    borderRadius: 4,
    marginRight: "10px",
    marginTop: 10,
    padding: "4px 10px",
    fontFamily: APP_FONTS.SofiaProRegular,
  },
  spanModalImg: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 20,
    marginRight: 20,
    fontSize: 16,
    fontFamily: APP_FONTS.SofiaProRegular,
    color: "#1e1f20",
  },
  colorModalDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  pageInputModal: {
    width: 48,
    height: 48,
    paddingLeft: 15,
    textAlign: "center",
    fontSize: "1.2rem",
    color: "#1e1f20",
    borderRadius: 4,
    marginLeft: 20,
    fontFamily: APP_FONTS.SofiaProRegular,
    border: "1px solid rgba(143, 146, 161, 0.4)",
  },
  selectInputModal: {
    width: "90%",
    // height:48,
    padding: "5px 10px",
    marginLeft: 20,
    fontFamily: APP_FONTS.SofiaProRegular,
    border: "1px solid rgba(143, 146, 161, 0.4)",
    color: "#1e1f20",
    borderRadius: 4,
    backgroundColor: "transparent",
  },
  inputDateCont: {
    width: "90%",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  timeSelectCont: {
    width: "90%",
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    marginLeft: "20px",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  fromDate: {
    padding: "5px 10px",
    marginLeft: 20,
    width: "80%",
    fontFamily: APP_FONTS.SofiaProRegular,
    border: "1px solid rgba(143, 146, 161, 0.4)",
    color: "#1e1f20",
    borderRadius: 4,
    backgroundColor: "transparent",
  },
  dateTime: {
    float: "right",
    fontFamily: APP_FONTS.SofiaProRegular,
    color: "#000000",
    fontSize: 14,
  },
  earningAmount: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: APP_FONTS.SofiaProRegular,
    color: "#000000"
  },
  mainContainerHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  adminCommisionInfo: {
    height: "14px",
    width: "14px",
    marginTop: "-14px",
    float: "right",
    cursor: "pointer"
  },
  priceModalContainer: {
    borderRadius: 15,
    maxWidth: "670px !important"
  },
  adminPriceList: {
    lineHeight: 2.67,
    fontFamily: APP_FONTS.SofiaProRegular,
    fontSize: "12px",
    fontWeight: "bold"
  },
  adminPriceHeading: {
    color: "#1e1f20",
    fontFamily: APP_FONTS.SofiaProRegular,
    marginTop: "10px",
  }
});
// Customizable Area End

const MerchantWalletConfirmBox = withConfirmBox(MerchantWallet);
const MerchantWalletWithLoader = withLoader(MerchantWalletConfirmBox)
const MerchantWalletLoaderToast = withToast(MerchantWalletWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(MerchantWalletLoaderToast));