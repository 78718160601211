//@ts-nocheck
import React from "react";
// Customizable Area Start
import MerchantInvoicesController, {
  Props,
} from "./MerchantInvoicesController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { appIcon } from "./assets";
import './index.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

class FailedPaymentPage extends MerchantInvoicesController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;

    return (
      <>
            <div className="successPageSection">
              <div className="invoiceBg">
                <Grid container className="invoiceTopArea">
                  <Grid item xs={12}>
                  <Box className="invoiceLogoArea">
                    <img
                      src={appIcon}
                      alt=""
                      className=""
                      onClick={this._handleAllShopsModal}
                    />
                    <Typography variant="h4" align="center">PRINTSEC PRIVATE LIMITED</Typography>
                  </Box>
                  <Box className={classes.Invoicedetail}>
                  <Typography variant="p" align="center" className={classes.invoiceID}>Your invoice id <span className={classes.invoiceIDspan}>ASDF123</span> of billing cycle </Typography>
                  <Typography variant="p" className={classes.invoiceID}> <span className={classes.invoiceIDspan}>01/05/23</span> to <span  className={`${classes.invoiceIDspan}`}>31/05/23</span> is <span className={classes.invoiceFailed}> Failed </span></Typography>
                  <Typography variant="p" align="center" className={classes.invoiceID}>If you want to pay again click on Retry </Typography>
                  <Typography  align="center"><Button className={classes.Paid}> Retry </Button></Typography>
                  </Box>
                </Grid>
                </Grid>
                <Grid className="invoiceHeaderBottomArea">
                </Grid>
              </div>
            </div>
      </>
    );
  }
}
// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
   invoiceID: {
    width: '100%',
    textAlign: "center",
    display: 'block',
    fontSize: '21px',
    lineHeight: 2
   
   },
   invoiceIDspan: {
    color: '#32795f',
    fontWeight: '600',
    fontSize: '24px'
  },  
  Invoicedetail: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  invoiceFailed:{
    color: 'red',
    fontWeight: 'bold',
  },
  Paid: {
    backgroundColor: "#32795f",
    padding: "6px",
    borderRadius: "4px",
    lineHeight: "26px",
    minWidth: "72px",
    textAlign: "center",
    color:"white"
  },
  });
// Customizable Area End
const MerchantInvoicesPaymentFailedPage = withConfirmBox(FailedPaymentPage);
const MerchantInvoicesFailedWithLoader = withLoader(MerchantInvoicesPaymentFailedPage);
const MerchantInvoicesFailedWithToast = withToast(MerchantInvoicesFailedWithLoader);
export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantInvoicesFailedWithToast)
);