// @ts-nocheck
import React, { createRef } from "react";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// Customizable Area End
import Header from "./Header.Web";
import LandingPageController, {
  Props
} from "./LandingPageController.Web";
import './Header.css';
import './LandingPage.css';
import {
  footerImg,
  gtxImg,
  glxImg,
  envlpImg,
  twitterImg,
  gtImg,
  pImg,
  digitalMapImg,
  clpMapImg,
  clp1MapImg,
  ideaToBuisnessImg,
  ideaToBuisnessSelectedImg,
  ideaTochallengedImg,
  ideaTochallengeSelecteddImg,
  customSolutionImg,
  customSolutionSelectedImg,
  testimonialImg1,
  testimonialImg2
} from './assets'

class ServiceDetails extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollToGetInTouchDiv = createRef();
    this.scrollToHowItWorkDiv = createRef();
    this.scrollToKnowMoreDiv = createRef();

    // Customizable Area End
  }

   // Customizable Area Start
  // Customizable Area End
   async componentDidMount() {
     window.scrollTo(0, 0);

     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.ideaToBuisnessState === "header-ideaToBuisness") {
      this.setState({tabIndex:0}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }
     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.ideaToChallengeState === "header-ideaToChallenge") {
      this.setState({tabIndex:1}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }
     if(this.props.location.state && this.props.location.state !== "undefined" && this.props.location.state.customSolutionState === "header-customSolution") {
      this.setState({tabIndex:2}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
     }
   }

   navigateTo = (pathName) => {
       this.props.history.push(pathName);
   }
   serviceNavigationMenuHandler = (tabIndex) => {
    this.setState({tabIndex}, () => {this.scrollToKnowMoreDiv.current.scrollIntoView({ behavior: 'smooth' });})
  }
  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid
        container
        direction="row"
        justify="center"
        className="det"
      >
       <Header serviceNavigationMenuHandler={(index) => this.serviceNavigationMenuHandler(index)}/>
        <Grid item xs={12} className={"navBarTopMargin"}/>
        <Grid item xs={12}>
        <div className="jumbotron text-center service service-detail-top">
          <div className="col-lg-6">
          <div className="Group_12438">
              <div className="Get_Your_Documents_printed_Sec">
                <span>Digital </span><span className="header-line" styles="color:rgba(255,255,255,1);">Consulting Services</span>
              </div>
              <div className="A_SaaS_based_encrypted_printin">
                {/* <span className="alx"><i className="fa fa-home" aria-hidden="true"></i> / Our Services / Digital Consulting Services</span> */}
              </div>
            </div>
        </div>
        </div>
        </Grid>
        <div className="container vid-section service-vid-align">
      <div className="row">
        <div className="col-lg-6">
          <div className="video-left">
            <h2>Digital <font>Consulting</font></h2>
            <h3> Services</h3>
           <p>Printsec Digital Consulting services (PDCS) is a business unit of PrintSEC that offers a unique set of services to help an organization or individual transform their idea or challenges into a complete working business solution. Right from listening to your ideas/challenges to putting it into a solution blueprint and converting it to an MVP and/or pilot through our agile solution in and development framework allows us to timely deliver the desired solution in reality. Engage with our sales representative who will work with you along with our best-in-class Solution Architects to deliver a solution blueprint of your idea at <b>zero cost.</b></p>
            <p className="get-btn vid-btn">
            <a 
              onClick={()=>
              this.props.history.push({
                pathname: '/',
                state: { serviceDetailState: "service-detail" }
              })
              }
            >Download The App</a>
          </p>
          <span className="lnk" onClick={() => {
              this.scrollToHowItWorkDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>How It Works?</span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="video">
              <img src={digitalMapImg} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="row">
        <div className="printing-services service-detail-heading">
          <h2><font>Digital Consulting</font>Service Offerings</h2>
        </div>
      </div>
      </div>
      <div className="container service-detail-tbs"  ref={this.scrollToKnowMoreDiv}>
      <div className="row">
      <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({tabIndex:index})}>
      <div className="col-lg-4">
      <TabList>
        <Tab> 
          {
          this.state.tabIndex === 0 ?  <img src={ideaToBuisnessSelectedImg} /> :
          <img src={ideaToBuisnessImg} />
          }
          <p>Idea To Business(I2B)</p>
        </Tab>
        <Tab>
          {
            this.state.tabIndex === 1 ? <img src={ideaTochallengeSelecteddImg} /> :
          <img src={ideaTochallengedImg} />
          }
          <p>Idea To Challenge(I2C)</p>
        </Tab>
        <Tab>
          { this.state.tabIndex === 2 ? <img src={customSolutionSelectedImg} /> :
          <img src={customSolutionImg} />
          }
          <p>Custom Solution</p>
        </Tab>
      </TabList>
      </div>
      <div className="col-lg-7">
      <TabPanel>
        <div className="panel-content">
          <h2>
          <b>Idea To Business(I2B)</b>
          </h2>
          <p>I2B services are tailored to transform any business idea into a working business solution that covers each and every element required to convert an idea into a product.</p>
          <div className="gtm-txt gmn"><a onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a></div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="panel-content">
          <h2>
          <b>Idea To Challenge (I2C)</b>
          </h2>
          <p>I2C services are meant for organizations across all industries that are struggling with various business challenges and require digital transformation.</p>
          <div className="gtm-txt gmn"><a onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a></div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="panel-content">
          <h2>
          <b>Custom Solution</b>
          </h2>
          <p>Can be a hybrid service of I2B and I2C or various technologies across Applications, Cloud, Cyber security and Digital workplace, etc.</p>
          <div className="gtm-txt gmn"><a onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a></div>
        </div>
      </TabPanel>
      </div>
    </Tabs>
      </div>
      </div>
    <div className="container service-print-detail" ref={this.scrollToHowItWorkDiv}>
     <div className="row">
      <div className="print-section">
        <h2><font>How It</font> Works</h2>
        <p className="line-px">Clients don’t need to have any prior knowledge or experience in the IT field as we take care of end to end solution, design, implementation, UAT and Pilot by converting your idea into a working business solution.</p>
        <div className="container ndx">
          <div className="row">
            <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 1</h2>
                <p>Connect with our Sales rep through call/chat/email</p>
              </div>
            </div>
             <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 2</h2>
                <p>Sign NDA to protect your Idea.</p>
              </div>
            </div>
             <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 3</h2>
                <p>Have discussion with our solution architect.</p>
              </div>
            </div>
             <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 4</h2>
                <p>Get your proposal.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="img-cntr service-detail-vector">
              <img src={clpMapImg} />
            </div>
            <div className="img-cntr">
              <img src={clp1MapImg} />
            </div>
            <div className="container ndx1">
          <div className="row">
            <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 5</h2>
                <p>Sign contract</p>
              </div>
            </div>
             <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 6</h2>
                <p>Have pilot of agreed solution or MVP</p>
              </div>
            </div>
             <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 7</h2>
                <p>See your dream idea/project in reality</p>
              </div>
            </div>
             <div className="col-lg-3">
              <div className="txt">
                <h2>STEP 8</h2>
                <p>Signup for a post delivery support or manage it on your own.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
     <div className="container service-detail-get">
      <div className="row">
        <div className="get">
          <div className="col-lg-8">
            <div className="gt-txt">
              <h2>Are You An Enterprise? Get Started.</h2>
              <p>PrintSEC application is available for enterprises under our offering private queue and hosted queue.</p>
            </div>
          </div>
          <div className="col-lg-4 orx">
            <div className="gtm-txt">
              <a 
              onClick={()=>
                this.props.history.push({
                  pathname: '/',
                  state: { serviceDetailKnowMoreState: "service-detail-know-more" }
                })
                }
              >Know More</a>
              <span className="orz">Or</span>
              <a className={classes.linkPointer} onClick={() => {
              this.scrollToGetInTouchDiv.current.scrollIntoView({ behavior: 'smooth' });
                }}>Get In Touch</a>
            </div>
          </div>
         </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   </div>
    <div className="container-fluid bx1 service-detail-test">
      <h2>Testimonials </h2>
      <p className="tst-qt">See what our clients speak about their experiences!</p>
      {/* <p className="arrow"><span className="arrow-left"></span> 
      <span className="arrow-right"></span></p> */}
      <div className="row">
        <div styles="width:75%;" className="container txts">
        <div className="col-lg-6">
          <div className="cardx">
          <p className="qte"></p>
            <p>Having a business idea I was quite panicky to kick start at the earliest before it comes to any other mind. As a non IT professional I was completely puzzled how and where to start. Someone referred me to these guys, The Solution Architect assigned in my case was very skilled having working experience of more than 18 yrs. After a few meetings I was pretty convinced and decided to open up here with my brain child. Surprisingly the solution architect and his team were excellent in perceiving my all ideas of business and within a week they came up with a complete package of solution with a timeline. It was so professionally designed that I couldn't find any drawbacks to refuse. My overall experience was total peace of mind. One stop single window solutions.</p>
            <p className="user"><span><img src={testimonialImg1} className="testiImg" /></span><span className="ct">Akhtarul Iman.</span><span className="des1">Founder and CEO.</span><span className="des1">KARLOSi TECHNOLOGIES PVT. LTD</span></p>
          </div>
        </div>
        <div className="col-lg-6">
        <div className="cardx">
          <p className="qte"></p>
          <p>
          During my final year of Master in Public Health I was working on some confidential project that I didn’t want to share with anyone considering the fact that my idea and content may be stolen. Through my close friends I discovered this application called PrintSEC, Which is a secure printing application that allows me to print my confidential document from my mobile device without sharing my files with the Shop owner.
          I would recommend this application to all students and professionals who value their documents.
          </p>
          <p className="user"><span><img src={testimonialImg2} className="testiImg" /></span><span className="ct">Aamir Mohsin</span><span className="des1">Healthcare Data Analyst.</span></p></div>
        </div>
      </div>
      </div>
    </div>
    <div className="container testimonial-align service-detail-qt">
      <div className="row">
        <div className="testimonial-inner-content">
          <h2>Message from <font>CEO</font></h2>
          <p>PrintSEC has a vision to simplify and digitalize everything that has an impact on human life and make technology simple to use for everyone including those who do not understand it.</p>
          <h3>Uzma Nizam</h3>
          <p className="des">CEO & MD</p>
        </div>
      </div>
    </div>
    <Grid>
      <div className="container-fluid ft">
      <div className="row">
        <div className="footer-content">
          <div className="col-lg-4">
            <div className="logo-footer">
              <img src={footerImg} />
              <p>PrintSEC has immense potential to digitalize your business and move it forward through a digital transformation. </p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-menu">
              <h3>QUICK LINKS</h3>
              <ul className="list">
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Services")} >Services</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/About-us")} >About us</li>
                <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Contact-us")}>Contact us</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2" ref={this.scrollToGetInTouchDiv}>
            <div className="footer-menu">
              <h3>GET IN TOUCH
              </h3>
              <p className="salesContent">Sales Specialists at PrintSEC will help you start your digital journey. Reach out to us through:</p>
              <ul className="list contx">
              <a href="mailto:connect@printsec.app"><li><span><i className="fa fa-envelope" aria-hidden="true"></i></span>connect@printsec.app</li></a>
              <a href="tel:+91-9319480924"><li><span><i className="fa fa-phone" aria-hidden="true"></i></span>+91-9319480924</li></a>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-menu btz">
              <h3>DOWNLOAD THE APP</h3>
              <ul className="list">
              <li><a href="https://apps.apple.com/in/app/id1545304010" target="_blank"><img src={gtxImg} /></a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.SecurePrinting" target="_blank"><img src={glxImg} /></a></li>
                {/* <li><img src={wlxImg} /></li>          */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row social-icon">
        <div className="social">
          <a href="mailto:connect@printsec.app"><img src={envlpImg} /></a>
          <a href="https://twitter.com/PrintsecD" target="_blank"><img src={twitterImg} /></a>
          <a href="https://www.linkedin.com/in/printsec-digital-32b63021b/" target="_blank"><img src={gtImg} /></a>
          <a href="https://www.instagram.com/printsecdigital/" target="_blank"><img src={pImg} /></a>
        </div>
      </div>
    </div>
    <div className="container-fluid bt">
      <div className="row">
        <div className="col-lg-5">
          <div className="bottom-ft">
            <p>© 2021 PrintSEC Digital</p>
          </div>
        </div>
        <div className="col-lg-6">
        <div className="bottom-ft">
        <ul className="lst">
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Enduser-licence")} >End user license agreement</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Privacy-policy")}>Privacy policy</li>
        <li className={classes.linkPointer} onClick={()=>this.props.history.push("/Terms-and-conditions")}>Term and Conditions</li>
        </ul>
        </div>
        </div>
      </div>
    </div>
    </Grid>
  </Grid>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  linkPointer: {
    cursor: "pointer"
  },
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(ServiceDetails));