// @ts-nocheck
import React from "react";
// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import withRouter from "react-router/withRouter";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import TermsConditionController, {
  Props
} from "./TermsConditionController.Web";
// Customizable Area End
import Header from "./Header.Web";
import Footer from "./Footer.Web";

class TermsOfService extends TermsConditionController {
  constructor(props: Props) {
    super(props);
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTermsConidtionContent();

  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
      container
      direction="row"
      justify="center"
      className={classes.parentCont}
    >
      <Header />
      <Grid item xs={12} />
      <div className="terms">
      <Grid item xs={12} className={classes.TermsContent}>
      <div className={classes.parentTermsCon}>
        <p className={classes.TermsConHeading}>PrintSEC Terms and Conditions of Use</p>
      </div>
      <div className={classes.tersmCoditionContentData} dangerouslySetInnerHTML={{ __html: this.state.termsConditionContent }} >
        </div>
      </Grid>
      </div>
      <Footer />
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  TermsContent: {
    width: "1024px",
    display: "inline-block",
    marginLeft: "auto",
    marginRight: "auto",
    flexBasis: "inherit"
  },
  TermsConHeading: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: 'Segoe UI'
 },
 parentTermsCon:{
  //  marginTop: "120px" 
 },
 tersmCoditionContentData: {
  fontFamily: 'Segoe UI'
}
});

export default withRouter(withStyles(styles, { withTheme: true })(TermsOfService));
