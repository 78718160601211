// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import StorageProvider from "../../../framework/src/StorageProvider";
import EditPayoutDetailsController, {
  Props,
  configJSON,
} from "./EditPayoutDetailsController.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
// Customizable Area Start
// Customizable Area End
class EditPayoutDetails extends EditPayoutDetailsController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: -1
    }
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  _saveAndContinueTapped = async () => {
    let beneficiaryName: any = this.state.beneficiaryName;
    let error: any = "";
    this.setState({ error: error })
    error = this.validateField(beneficiaryName, "Beneficiary Name ");
    if (error) {
      this.props.showNotification(error, "error");
      this.setState({ error: error })
      return;
    }
    error = this.validateAccountNumbers()
    if (error) {
      this.props.showNotification(error, "error");
      this.setState({ error: error })
      return;
    }

    let accountNumber: any = this.state.accountNumber;
    let dconfirmAccountNumber : any = this.state.confirmAccountNumber;

    if( accountNumber != dconfirmAccountNumber) {
      this.props.showNotification("Account number does not match", "error");
      this.setState({ error: "Account number does not match" })
      return;
    }

    error = this.validateField(accountNumber, "Account Number ");

    if (error) {
      this.props.showNotification(error, "error");
      this.setState({ error: error })
      return;
    }

    let confirmAccountNumber : any = this.state.confirmAccountNumber;

    let bankName: any = this.state.bankName;

    error = this.validateField(bankName, "Bank Name ");

    if (error) {
      this.props.showNotification(error, "error");
      this.setState({ error: error })
      return;
    }
    let ifsc: any = this.state.ifscCode;

    error = this.validateIFSC(ifsc);

    if (error) {
      this.setState({ error: error })
      this.props.showNotification(error, "error");
      return;
    }

    let bankAddress: any = this.state.bankAddress

    error = this.validateField(bankAddress, "Bank Address ");

    if (error) {
      this.setState({ error: error })
      this.props.showNotification(error, "error");
      return;
    }
    await StorageProvider.remove('accountDetailsData');

    this.sendMobileOtp()

    let data: any = {}
    data.account_id = Number(this.state.id),
      data.bank_details = "hdfc",
      data.beneficiary_name = this.state.beneficiaryName,
      data.ifsc_code = this.state.ifscCode,
      data.account_number = this.state.accountNumber,
      data.bank_name = this.state.bankName,
      data.bank_address = this.state.bankAddress,
      data.email = this.state.email,
      await StorageProvider.set('accountDetailsData', JSON.stringify(data));

    // this.updatePayoutDetailsAPICall();
  };

  validateUPIId = () => {
    let upiReg = /^\w.+@\w+$/;
    let result = upiReg.test(this.state.bhimUPI);

    return result
  }

  _saveBhimUPI = async () => {
    if (!this.validateUPIId()) {
      this.props.showNotification('Invalid UPI Id', 'error');
      return
    }
    await StorageProvider.remove('upiId');

    this.sendMobileOtp()
    // this.updateMerchantBusinsessDetailsCall();
    await StorageProvider.set('upiId', this.state.bhimUPI);
  };
  setTab = (num) => {
    this.setState({ tab: num, optionTab: num })
  }
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.name == "bhimUPI" ? event.target.value.replace(" ", "") : event.target.value });
  };
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        id: loginData.id,
        authToken: loginData.token,
        email: loginData.email,
        phone: loginData.phone
      });
    }
    let businessDetailsData = await StorageProvider.get("businessDetailsData");
    if (businessDetailsData) {
      businessDetailsData = JSON.parse(businessDetailsData);
      this.setState({
        merchantId: businessDetailsData.merchantId,
        bhimUPI: businessDetailsData.bhim_upi_number,
        email: businessDetailsData.email
      });
    }
    if (this.props.mode === "edit") {
      const payoutDetails = await StorageProvider.get("payoutDetails");
      if (payoutDetails === 'no') {
        this.setState({ isPayoutDetailAvailable: false })
      } else {
        this.setState({ isPayoutDetailAvailable: true })
      }
      let payoutDetailsData = await StorageProvider.get("payoutDetailsData");
      if (payoutDetailsData) {
        payoutDetailsData = JSON.parse(payoutDetailsData);
        this.setState({
          beneficiaryName: payoutDetailsData.beneficiary_name,
          accountNumber: payoutDetailsData.account_number,
          bankName: payoutDetailsData.bank_name,
          ifscCode: payoutDetailsData.ifsc_code,
          bankAddress: payoutDetailsData.bank_address,
        });
      }
    }
    await StorageProvider.get("businessDetailsData").then((res) => {
      const businessDetailsData = JSON.parse(res);
      if (businessDetailsData) {
        this.setState({ editPayoutDetails: businessDetailsData.editPayoutDetails })
      }
    });
    if (this.props?.location?.state?.calledFrom === "otpVerificationUpi") {
      var upiId = await StorageProvider.get("upiId")
      this.setState({
        bhimUPI: upiId
      })
      this.updateMerchantBusinsessDetailsCall()
    }
    if (this.props?.location?.state?.calledFrom === "otpVerificationAccount") {
      let accountDetailsData = await StorageProvider.get("accountDetailsData");
      if (accountDetailsData) {
        accountDetailsData = JSON.parse(accountDetailsData);
        this.setState({
          accountDetailsData: accountDetailsData,
          id: accountDetailsData.account_id,
          // bank_details: accountDetailsData.bank_details,
          beneficiaryName: accountDetailsData.beneficiary_name,
          ifscCode: accountDetailsData.ifsc_code,
          accountNumber: accountDetailsData.account_number,
          bankName: accountDetailsData.bank_name,
          bankAddress: accountDetailsData.bank_address,
          email: accountDetailsData.email,
        });
      }
      this.updatePayoutDetailsAPICall()
    }
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { mode } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Grid item xs={12} sm={6}>
          <div className={classes.tabContentLeft} style={{ paddingTop: 30 }}>
            <Typography className={classes.screenHeading}>
              {configJSON.labelUpdatePayoutDetails}
            </Typography>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.btnContainer}>
            <Button className={classes.editBtn} style={{ fontSize: '14px', backgroundColor: this.state.tab === 1 ? "#FDD13C" : "#d8d8d8" }} onClick={() => this.setTab(1)}>Edit Upi Details</Button>
          </Grid>
          {/*{*/}
          {/*  this.state.tab === 1 ? (*/}
          <Collapse in={this.state.tab === 1} style={{ width: "100%" }}>
            <Grid item xs={12} sm={6}>
              <div className={classes.tabContentLeft}>
                <Typography variant="body2" className={classes.basicInfo} >Edit UPI Details</Typography>
                <Typography className={classes.inputLabel}>
                  BHIM UPI*
                </Typography>
                <InputBase
                  className={classes.inputTextBox}
                  placeholder={configJSON.enterBhimUpi}
                  name="bhimUPI"
                  value={this.state.bhimUPI}
                  onKeyPress={e => /[^[\w\.\-_]{3,}@[a-zA-Z]{3,}]$/.test(e.key) && e.preventDefault()}
                  onChange={this.handleInputChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <div className={classes.tabContentLeft}>
                <Button
                  onClick={this._saveBhimUPI}
                  className={classes.saveChangesBtn}
                  style={{ backgroundColor: APP_COLORS.app_theme_green_color }}
                >
                  <span style={{ fontSize: '14px' }}> {configJSON.btnTextSaveChanges}</span>
                </Button>
              </div>
            </Grid>
          </Collapse>
          {/*) : ""*/}
          {/*}*/}
          {
            this.state.accountNumber === undefined &&
            <Grid item xs={12} className={classes.btnContainer}>
              <Button className={classes.editBtn} style={{ fontSize: '14px', backgroundColor: this.state.tab === 2 ? "#FDD13C" : "#d8d8d8" }} onClick={() => this.setTab(2)}>Add Bank Details</Button>
            </Grid>
          }
          <Collapse in={this.state.tab === 2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={6}>
              <div className={classes.tabContentLeft}>
                {mode === 'create' ? <>
                  <Typography className={classes.basicInfo} variant="body1">Before We Continue</Typography>
                  <Typography className={classes.basicInfo} variant="body2">Add Bank Detail(2/2)</Typography>
                  <Typography className={classes.basicInfo} variant="subtitle1">Basic Information</Typography></> : <Typography variant="body2" className={classes.basicInfo}>Bank Details</Typography>}
                <Typography className={classes.inputLabel}>
                  {configJSON.labelBeneficiaryName}
                </Typography>
                <InputBase
                  className={classes.inputTextBox}
                  // error={this.state.error ? true : false}
                  // helperText={this.state.error ? this.state.error : null}
                  placeholder={configJSON.enterBeneficiaryName}
                  value={this.state.beneficiaryName}
                  name="beneficiaryName"
                  onChange={this.handleInputChange}
                />
                <Typography className={classes.inputLabel}>
                  {configJSON.labelAccountNumber}
                </Typography>
                <InputBase
                  className={classes.inputTextBox}
                  // error={this.state.error ? true : false}
                  // helperText={this.state.error ? this.state.error : null}
                  placeholder={configJSON.enterAccountNumber}
                  value={this.state.accountNumber}
                  name="accountNumber"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={this.handleInputChange}
                />
                <Typography className={classes.inputLabel}>
                  {configJSON.labelConfrimAccountNumber}
                </Typography>
                <InputBase
                  className={classes.inputTextBox}
                  // error={this.state.error ? true : false}
                  // helperText={this.state.error ? this.state.error : null}
                  placeholder={configJSON.confirmAccountNumber}
                  value={this.state.confirmAccountNumber}
                  name="confirmAccountNumber"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={this.handleInputChange}
                />
                <Typography className={classes.inputLabel}>
                  {configJSON.labelBankName}
                </Typography>
                <InputBase
                  className={classes.inputTextBox}
                  // error={this.state.error ? true : false}
                  // helperText={this.state.error ? this.state.error : null}
                  placeholder={configJSON.enterBankName}
                  name="bankName"
                  value={this.state.bankName}
                  onChange={this.handleInputChange}
                />
                <Typography className={classes.inputLabel}>
                  {configJSON.labelIFSCCode}
                </Typography>
                <InputBase
                  className={classes.inputTextBox}
                  placeholder={configJSON.enterIFSCCode}
                  // error={this.state.error ? true : false}
                  // helperText={this.state.error ? this.state.error : null}
                  name="ifscCode"
                  value={this.state.ifscCode}
                  onChange={this.handleInputChange}
                />
                <Typography className={classes.inputLabel}>
                  Bank Address *
                </Typography>
                <InputBase
                  className={classes.inputTextBox}
                  placeholder={"Enter Bank Address"}
                  // error={this.state.error ? true : false}
                  // helperText={this.state.error ? this.state.error : null}
                  name="bankAddress"
                  value={this.state.bankAddress}
                  onChange={this.handleInputChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <div className={classes.tabContentLeft}>
                <Button
                  onClick={this._saveAndContinueTapped}
                  className={classes.saveChangesBtn}
                  style={{ backgroundColor: APP_COLORS.app_theme_green_color }}
                >
                  {configJSON.btnTextSaveChanges}
                </Button>
              </div>
            </Grid>
          </Collapse>
        </Grid>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    tabContentLeft: {
      width: "85%",
      margin: "auto",
    },

    //Text Styling
    screenHeading: {
      fontSize: 22,
      // fontWeight:'bold',
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    },
    basicInfo: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginTop: 27,
    },
    inputLabel: {
      fontSize: 14,
      marginTop: 15,
      letterSpacing: 1,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },

    //Input Styling
    inputTextBox: {
      padding: "5px 10px",
      width: "100%",
      marginTop: 7,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },

    //Button styling
    saveChangesBtn: {
      color: "white",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "100%",
      height: 50,
      margin: "25px 0 20px 0",
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    editBtn: {
      color: "#1e1f20",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "90%",
      height: 50,
      margin: "10px auto 0",
      // marginRight: "10px",
      // marginTop: 40,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    }
  });
// Customizable Area End

const EditPayoutDetailsWithConfirmBox = withConfirmBox(EditPayoutDetails);
const EditPayoutDetailsWithLoader = withLoader(EditPayoutDetailsWithConfirmBox);
const EditPayoutDetailsWithToast = withToast(EditPayoutDetailsWithLoader);
export default withRouter(
  withStyles(styles, { withTheme: true })(EditPayoutDetailsWithToast)
);
