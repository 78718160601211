//@ts-nocheck
import React from "react";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { Scrollbars } from 'react-custom-scrollbars';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as Map,
  Marker,
  DirectionsRenderer
} from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import NearByShopsController, {
  Props,
  configJSON,
} from "./NearByShopsController.Web";
import './NearByShops.css';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import withRouter from "react-router/withRouter";
import { APP_FONTS } from "../../../components/src/AppFonts";
import {
  backIcon,
  detailsIcon,
  mapsIcon,
  markerIcon,
  userIcon,
  colorPallet,
  greyscalePallet,
  info,
} from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const radiusValues = [
  { label: "5 Km", value: "5" },
  { label: "10 Km", value: "10" },
  { label: "15 Km", value: "15" },
  { label: "20 Km", value: "20" },
];
const containerStyle = {
  width: "100%",
  height: "100%",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const defaultMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false
};


const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <Map
    defaultZoom={props.zoom}
    defaultCenter={props.center}
    defaultOptions={defaultMapOptions}
  >
    {props.markerLabelLat ? <MarkerWithLabel
      position={{
        lat: props.markerLabelLat,
        lng: props.markerLabelLng,
      }}
      labelAnchor={new google.maps.Point(50, 5)}
      labelStyle={{ backgroundColor: "white", fontSize: "12px", padding: "6px", zIndex: '1000' }}
      icon={{
        url: markerIcon,
        scaledSize: new google.maps.Size(30, 40)
      }}
    >
      {
        <div style={{ zIndex: 2000, padding: '4px' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '4px', fontSize: '14px' }}>{props.markerLabelName}</div>
        </div>
      }
    </MarkerWithLabel> : null}
    {props.dataSource.length > 0 ?
      props.dataSource.map((store) => {
        return (
          <MarkerWithLabel
            onClick={() => { props.runFun(parseFloat(store?.attributes?.lat), parseFloat(store?.attributes?.lng), store?.attributes?.shop_name) }}
            key={store.id}
            position={{
              lat: parseFloat(store?.attributes?.lat),
              lng: parseFloat(store?.attributes?.lng),
            }}
            labelAnchor={new google.maps.Point(50, 5)}
            labelStyle={{ backgroundColor: "transparent", fontSize: "12px", padding: "6px" }}
            icon={{
              url: markerIcon,
              scaledSize: new google.maps.Size(30, 40)
            }}
          >
            {<>
            </>}
          </MarkerWithLabel>
        );
      })
      : null}
    {props.directions ? <DirectionsRenderer
      directions={props.directions} /> : null
    }
    {(props.lat && props.lng) && <Marker
      position={{
        lat: props.lat,
        lng: props.lng,
      }}
      icon={userIcon}
    />
    }
  </Map>
));

class NearByShopsToPay extends NearByShopsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  _closeModal = () => {
    this.setState({
      showMapFilter: false,
      isVisibleDropDown: false,
    });
  };
  calculateDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    var lat1 = this.toRad(lat1);
    var lat2 = this.toRad(lat2);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }


  _contineToPayTapped = () => {
    const { openDialog } = this.props;
    if (this.props.location?.state?.calledFrom === "orderHistory") {
      this.props.history.push('/UserSidebarNavigation/UploadDashBoard/Payment');
    }
    else {
      const onClose = (msg) => {
        if (msg === 'Yes') {
          this.placeOrderApiCall();
          // this.props.history.push('/UserSidebarNavigation/UploadDashBoard/Payment');
        }
      }
      this.props.openDialog('Continue to pay', 'Are you sure, you want to place the order?', onClose);
    }
  };

  _filterTapped = () => {
    this.setState({
      showMapFilter: !this.state.showMapFilter,
    });
  };

  // Current Location //
  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    function getPreciseLocation() {
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            resolve([position.coords.latitude, position.coords.longitude]);
          },
          (error) => {
            reject("Geolocation is not supported by your browser");
          }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        );
      });
    }

    let userData = await StorageProvider.get("loginData")
    let selectedFiles = await StorageProvider.get("selectedFiles")
    let showOrderButton = await StorageProvider.get("showPay")
    let location = await getPreciseLocation()
    const loginData = userData && JSON.parse(userData);
    this.setState({
      authToken: loginData.token
    })
    this.getBasePrices()
    const selectedAttachment = selectedFiles && JSON.parse(selectedFiles);
    showOrderButton = showOrderButton && JSON.parse(showOrderButton);
    if (selectedAttachment) {
      const attachmentIds = selectedAttachment.map(
        (attachment) => attachment.id
      );
      this.setState({
        attachmentIds: attachmentIds,
        id: loginData.id,
        authToken: loginData.token,
        lat: location[0],
        lng: location[1],
        showPayButton: showOrderButton.show_pay_button
      }, () => {
        this.getNearByServiceProvidrs();
      });
    }
  }
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  applyRadiusFilter = () => {
    this.setState({ radiusKm: this.state.radius, showMapFilter: false }, () => {
      this.getNearByServiceProvidrs();
    })
  }
  _handleBackClicked = () => {
    if (this.props.location?.state?.calledFrom === "orderHistory") {
      this.props.history.push('/OrderManagement/OrderHistory/History')
    }
    else {
      const options = {
        pathname: '/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen',
        state: { calledFrom: "NearByShopsToPay" }
      }
      this.props.history.push(options)
    }
  }

  runFun = (lat, lng, name) => {
    this.setState({
      markerLabelLat: lat,
      markerLabelLng: lng,
      markerLabelName: name
    })
  }

  handleViewMerchantPrices = (data: any) => {
    this.getBasePrices(data)
    this.setState({
      priceModal: true
    })
  }


  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <div className={classes.rightContent}>
          <div className={classes.headingCont}>
            <Grid item xs={12}>
              <div className={classes.headingLeft}>
                <img src={backIcon} onClick={() => {
                  this.state.showPayButton ?
                    this._handleBackClicked() :
                    this.props.history.goBack()
                }
                }
                  alt="" className={classes.backIcon} />
                <Typography className={classes.selectFilesText}>
                  {configJSON.findShopsHeading}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <img
                src={detailsIcon}
                alt=""
                className={classes.filterIcon}
                onClick={this._filterTapped}
              />
            </Grid>
          </div>
          <div className={"map_parent_cont"}>
            {this.state.lat && this.state.lng ? (
              <div className={"map_left_cont"}>
                <div className={"mapContainer"}>
                  {this.state.lat && this.state.lng && (<MapWithAMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBYo5s0uQPFgc8qafyO0Rzejpe78bi4ezw&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    runFun={this.runFun}
                    markerLabelLat={this.state.markerLabelLat}
                    markerLabelLng={this.state.markerLabelLng}
                    markerLabelName={this.state.markerLabelName}
                    center={{
                      lat: this.state.lat,
                      lng: this.state.lng,
                    }}
                    zoom={13}
                    dataSource={this.state.dataSource}
                    directions={this.state.directions}
                    lat={this.state.lat}
                    lng={this.state.lng}
                  >
                  </MapWithAMarker>
                  )
                  }
                </div>
                {
                  this.state.showPayButton && <Button
                    className={classes.findPrintersBtn}
                    onClick={() => { this._contineToPayTapped() }}
                    style={{ backgroundColor: "#32795f", marginTop: 20 }}
                  >
                    <div style={{ fontSize: '14px' }} >{configJSON.proceedToPrint}</div>
                  </Button>
                }
              </div>
            ) : (
              <div className={"map_left_cont"} style={{ fontSize: '12px' }}>Geolocation is not supported by your browser</div>
            )}
            <div className={"map_right_cont"}>
              <Scrollbars>
                <div className={classes.modalHeadingCont}>
                  <Typography className={classes.modalHeading}>
                    {configJSON.modalHeading}
                  </Typography>
                </div>
                <div className={classes.modalContentCont}>
                  {this.state.dataSource.length > 0
                    ? this.state.dataSource.map((marker) => (
                      < div key={marker.id} className={classes.eachShop} >
                        {/* {
                          marker?.attributes?.appreciation_type == "negative" ?
                            <div style={{ margin: "10px auto", display: "flex", alignItems: "center", justifyContent: "space-between", width: "90%", borderRadius: "5px", border: "1px solid #31785f", padding: "5px", backgroundColor: "white", color: "#31785f" }}>
                              <Typography style={{ fontWeight: "bold" }} className={classes.modalContentTextGreen}>
                                Appreciation prices applied are Lower than base price.
                              </Typography>
                            </div>
                            :
                            marker?.attributes?.appreciation_type == "positive" ?
                              <div style={{ margin: "10px auto", display: "flex", alignItems: "center", justifyContent: "space-between", width: "90%", borderRadius: "5px", border: "1px solid red", padding: "5px", backgroundColor: "white", color: "red" }}>
                                <Typography style={{ fontWeight: "bold" }} className={classes.modalContentTextGreen}>
                                  Appreciation prices applied are Higher than base price.
                                </Typography>
                              </div> : null

                        } */}
                        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                          <div style={{
                            overflow: 'hidden',
                            width: "100%"
                          }}>
                            <Typography title={marker?.attributes?.shop_name} className={classes.printerName}>
                              {marker?.attributes?.shop_name}
                            </Typography>
                          </div>
                          <Typography style={{
                            fontWeight: "bold", color: "#31785f", cursor: "pointer",
                            border: 'solid 2px',
                            padding: '8px',
                            borderRadius: '25px',
                            width: 'inherit'
                          }} onClick={() => this.getMerchantDetails(marker.attributes?.account_id)}>
                            <img src={info}
                              className={classes.infoPriceIcon}

                            />
                            View Prices</Typography>
                        </div>
                        <Typography className={classes.printerAddress}>
                          {marker?.attributes?.shop_address}
                        </Typography>
                        <div className={classes.navigateCont} onClick={() => { this.navigateToMerchantLocation({ lat: this.state.lat, lng: this.state.lng }, marker.attributes) }}>
                          <span className={classes.navigateText} >
                            <img
                              src={mapsIcon}
                              alt=""
                              className={classes.navigateImg}
                            />
                            <span style={{ fontSize: '12px' }}>{configJSON.Navigate}</span>
                          </span>
                          <span className={classes.printerAddress}>
                            {this.calculateDistance(this.state.lat, this.state.lng, marker?.attributes?.lat, marker?.attributes?.lng).toFixed(1)} Km
                          </span>
                        </div>
                      </div>
                    ))
                    : <Typography className={classes.modalHeading}>
                      {'No Merchant Available Near you'}
                      {/* <button onClick={() => this.handleViewMerchantPrices('402')}>View Prices</button> */}
                    </Typography>}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div >

        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.showMapFilter}
          onClose={this._filterTapped}
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                {configJSON.modal2Heading}
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
              <div className={classes.modalHeadingCont}>
                <Typography className={classes.modalHeading}>
                  {configJSON.printProperties}
                </Typography>
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.printSlides}
              </Typography>
              <Select value={this.state?.radius} name="radius" onChange={(event) => { this.handleInputChange(event) }}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {radiusValues.map((item) => (
                  <MenuItem key={item.value} value={item.value}><span style={{ fontSize: '12px' }}>{item.label}</span></MenuItem>
                ))}
              </Select>
              <Button
                className={classes.closeModalBtn}
                style={{ backgroundColor: "#32795f", marginTop: 20 }}
                onClick={() => { this.applyRadiusFilter() }}
              >
                <span style={{ fontSize: '14px' }}>Apply</span>
              </Button>
            </div>
          </div>
        </Modal>

        <Dialog
          fullWidth={true}
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" >
            {/* <span style={{ fontFamily: APP_FONTS.SofiaProRegular }}>{this.state.title}</span> */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontFamily: APP_FONTS.SofiaProRegular }}>
              {
                this.state.pageType?.map((page_type, index) => {
                  return (
                    <div key={index} style={{ marginBottom: "20px" }}>
                      <Typography className={classes.modalHeading}>
                        {page_type} Size Page
                      </Typography>
                      {
                        this.state.paperType?.map((paper_type, index) => {
                          return (
                            <div style={{ marginLeft: "20px" }}>
                              <Typography style={{ fontWeight: "bold" }} className={classes.modalContentText}>
                                {paper_type}
                              </Typography>
                              {/* {
                                <div style={{ alignItems: "center", margin: "0px 20px", display: "flex", borderRadius: "10px", padding: "10px", border: "1px solid black" }}>
                                  {
                                    this.state.printColor.includes("colored") ?
                                      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                                        <img src={colorPallet} width="25px" alt="color" />
                                        <Typography className={classes.modalContentText}>
                                          Color: {this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "colored").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "colored")[0].attributes?.base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.colored : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "colored")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.colored}/-
                                        </Typography>
                                      </div> : null
                                  }{
                                    this.state.printColor.includes("greyscale") ?
                                      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                                        <img src={greyscalePallet} width="25px" alt="color" />
                                        <Typography className={classes.modalContentText}>
                                          Greyscale: {this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "greyscale").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0].attributes?.base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.greyscale : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.greyscale}/-
                                        </Typography>
                                      </div> : null
                                  }
                                </div>
                              } */}
                              <tr style={{
                                cursor: 'pointer',
                                margin: '3% auto',
                                display: 'flex',
                                padding: '13px',
                                alignItems: 'center',
                                borderRadius: '12px',
                                justifyContent: 'space-between',
                                boxShadow: '0px 0px 3px 0px',
                                width: '100%',
                                fontSize: '16px'
                              }}>

                                <tr>
                                  <td>
                                    <img
                                      src={colorPallet}
                                      style={{ height: '30px', width: '30px' }}
                                    />
                                    {" "}Colored
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p style={{
                                      margin: 0,
                                      color: '#32795F'
                                    }}>₹{this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "colored").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "colored")[0].attributes?.base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.colored : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "colored")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.colored}/-</p>
                                    <p
                                      style={{
                                        margin: 0,
                                        color: '#32795F'
                                      }}
                                    >Per Page</p>
                                  </td>
                                </tr>
                              </tr>
                              <tr style={{
                                cursor: 'pointer',
                                margin: '3% auto',
                                display: 'flex',
                                padding: '13px',
                                alignItems: 'center',
                                borderRadius: '12px',
                                justifyContent: 'space-between',
                                boxShadow: '0px 0px 3px 0px',
                                width: '100%',
                                fontSize: '16px'
                              }}>
                                <tr>
                                  <td>
                                    <img
                                      src={greyscalePallet}
                                      style={{ height: '30px', width: '30px' }}
                                    />
                                    {" "}Greyscaled
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <td>
                                      <p
                                        style={{
                                          margin: 0,
                                          color: '#32795F'
                                        }}
                                      >₹{this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "greyscale").length > 0 ? this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0].attributes?.base_price ? this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.greyscale : this.state.baseMerchantPrices.filter((paperSize) => paperSize.attributes.page_size == page_type).filter((pageType) => pageType.attributes.paper_type == paper_type).filter((pageClr) => pageClr.attributes.colors == "greyscale")[0]?.attributes?.single_side_price : this.state.basePrices.filter((paperSize) => paperSize.page_size == page_type).filter((pageType) => pageType.paper_type == paper_type)[0]?.single_side?.greyscale}/-</p>
                                      <p
                                        style={{
                                          // fontWeight: 600,
                                          margin: 0,
                                          color: '#32795F'
                                        }}
                                      >Per Page</p>
                                    </td>
                                  </td>
                                </tr>
                              </tr>
                            </div>
                          )
                        })
                      }
                      <Divider style={{ marginTop: "20px" }} />
                    </div>
                  )
                })
              }
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              style={{ fontFamily: APP_FONTS.SofiaProRegular, color: '#32795f' }}
              onClick={() => this.setState({ modalOpen: false })}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
    },
    leftSideCont: {
      backgroundColor: APP_COLORS.app_theme_yellow_color,
      // height:'100%',
    },
    rightSideCont: {
      // height:'70%',
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    headingCont: {
      width: "90%",
      margin: "auto",
      padding: "20px 30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    mapsCont: {
      width: "100%",
      margin: "10px 30px",
      padding: "0px 10px",
      position: "relative",
      minHeight: "80vh",
      display: "flex",
      alignItems: "flex-end",
    },
    mapContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    selectFilesText: {
      fontSize: 22,
      marginLeft: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      position: "absolute",
      bottom: "7%",
      left: "5%",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    backIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor: "pointer"
    },
    filterIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor: "pointer",
      float: "right"
    },

    //Modal Styling
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    LocationListContainer: {
      // position: "absolute",
      // right: 0,
      borderRadius: "32px 0 0 32px",
      // width: "25%",
      // minWidth: 270,
      // height: "100vh",
      padding: "20px 0",
      // overflowY: "scroll",
      backgroundColor: "#32795f",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      width: "90%",
        maxWidth:'400px',
      minWidth: 270,
      height: "100vh",
      padding: "20px 0",
      overflowY: "scroll",
      backgroundColor: "#fdd001",
    },
    modalContentCont: {
      display: "flex",
      // marginLeft:30,
      flexDirection: "column",
      alignItems: "flex-start",
      // justifyContent:'center'
    },
    modalHeadingCont: {
      display: "flex",
      margin: "20px 0",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    modalHeading: {
      fontSize: 16,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    printerName: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      marginTop: 10,
      marginBottom: 10,
      // whiteSpace: 'nowrap',
      overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // width: '90%'
    },
    printerAddress: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginBottom: 10,
    },
    modalContentText: {
      fontSize: 13,
      letterSpacing: 1,
      margin: "20px 0 20px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      textTransform: "uppercase",
      color: "#1e1f20",
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: " auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    spanModalImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    colorModalDiv: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    pageInputModal: {
      width: 48,
      height: 48,
      paddingLeft: 15,
      textAlign: "center",
      fontSize: "1.2rem",
      color: "#1e1f20",
      borderRadius: 4,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
    },
    selectInputModal: {
      width: "90%",
      // height:48,
      padding: "5px 10px",
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    paperCopy: {
      height: 45,
      width: 60,
      marginBottom: 7,
      objectFit: "contain",
    },
    colorCopy: {
      height: 50,
      marginBottom: 7,
      width: 50,
      objectFit: "contain",
    },
    eachShop: {
      width: "80%",
      margin: "15px auto",
      backgroundColor: "white",
      borderRadius: 12,
      padding: 10,
    },
    navigateCont: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    navigateImg: {
      height: 15,
      width: 15,
      marginRight: 10,
      objectFit: "contain",
    },
    navigateText: {
      background: "#fdd001",
      borderRadius: 5,
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      fontWeight: "bold",
      color: "white",
      cursor: "pointer",
    },
    infoPriceIcon: {
      width: '20px',
      height: '18px',
      marginTop: '-3px',
      marginRight: '5px'
    },
  });
// Customizable Area End
const NearByShopsToPayWithConfirmBox = withConfirmBox(NearByShopsToPay);
const NearByShopsToPayWithLoader = withLoader(NearByShopsToPayWithConfirmBox)
const NearByShopsToPayWithToast = withToast(NearByShopsToPayWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(NearByShopsToPayWithToast));
