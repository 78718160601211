export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const userLogo = require("../assets/login_logo_1.png");
export const userImg = require("../assets/profile_dummy.jpg");
export const logoutIcon = require("../assets/logout.png");
export const userIcon = require("../assets/user.png");
export const arrowIcon = require("../assets/arrow-right.png");
export const arrowRightIcon = require("../assets/arrow_right_settings.png");
export const phoneIcon = require("../assets/edit_phone.png");
export const loginPasscodeIcon = require("../assets/login_passcode.png");
export const cameraIcon = require("../assets/camera_icon_edit_profile.png");
export const profileIcon = require("../assets/profile_icon.png");
export const deleteIcon = require("../assets/delete_photo.png");
export const editProfileIcon = require("../assets/edit_profile.png");
export const editPhoneColorIcon = require("../assets/edit_phone_color.png");
export const editProfileColorIcon = require("../assets/edit_profile_color.png");
export const loginPasscodeColorIcon = require("../assets/login_passcode_color.png");
export const mapsIcons = require("../assets/map.svg");
export const payoutDetailsIcon = require("../assets/payout_details.png");
export const currencyIcon = require("../assets/currenyIcon.svg");
export const printerIcon = require("../assets/printerOption.svg");
export const printerColorIcon = require("../assets/printerOptionSelect.svg");
export const currencyColorIcon = require("../assets/currenyColorIcon.svg");
export const colorPrintIcon = require("../assets/oval.svg");
export const greyscaleIcon = require("../assets/oval-copy.svg");
export const bothIcon = require("../assets/both.png");
export const fileIcon = require("../assets/file.png");
export const closeIcon = require("../assets/closeIcon.png");
export const arrowRightBlackIcon = require("../assets/arrow-right-black.svg");
export const arrowDownBlackIcon = require("../assets/arrow-down-black.svg");
export const arrowRightGreenIcon = require("../assets/arrow-right-green.svg");
export const userGreenIcon = require("../assets/userIconGreen.svg");
export const locationIcon = require('../assets/address_from_map.png');
export const arrowRightBlack = require("../assets/arrow-right-black.svg");
export const detailsIcon = require('../assets/details.svg');
export const info = require('../assets/info-icon.svg')
export const historyIcon = require('../assets/history.svg');
export const supportPhoneIcon = require('../assets/support-phone-number.png');
export const adminCommsionIcon = require('../assets/commision-info.png');
export const arrowDown = require('../assets/arrow-down.png');
export const arrowUp = require('../assets/arrow-up.png');
export const A1 = require('../assets/A1.png');
export const A2 = require('../assets/A2.png');
export const A3 = require('../assets/A3.png');
export const A4 = require('../assets/A4.png');
export const Letter = require('../assets/Letter.png');
export const colorPallet = require('../assets/colorPallet.png');
export const greyscalePallet = require('../assets/greyscalePallet.png');
export const printerDocIcon = require('../assets/printer.png')
export const lock = require('../assets/lock.png')
