// @ts-nocheck
import React from "react";
// Customizable Area Start
// Customizable Area End
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import TermsAndConditionController, {
  Props
} from "./TermsAndConditionController.Web";
import {createStyles, Theme,withStyles} from "@material-ui/core/styles";
import withRouter from "react-router/withRouter";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import Grid from "@material-ui/core/Grid";

class TermsAndCondition extends TermsAndConditionController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          authToken: loginData.token
        });
      }
    });
    let { location } = this.props;
    const path = location.pathname.toString();
    if (path.includes("MerchantSettings")) {
      await this.getPrivacyPolicyApiCall("merchant");
    }
    else {
      await this.getPrivacyPolicyApiCall("customer");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const _this = this;
    return (
      //Merge Engine DefaultContainer
      <Grid container style={{width:"100%",fontFamilty:APP_FONTS.SofiaProRegular,padding:"15px 0 0 20px"}}>
        {/*{renderHTML(this.state.webContent)}*/} 
        <div className={classes.parentTermsCon}>
        <h3 className={classes.TermsConHeading}>PrintSEC Terms and Conditions of Use</h3>
      </div>

        <div className={`${classes.paraFont} html-content-privacypolicy`} dangerouslySetInnerHTML={{ __html: this.state.webContent }}/>
      </Grid>
      //Merge Engine End DefaultContainer

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    paraFont:{
      fontSize:16,
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign:"center",
      marginTop:20,
      marginLeft:20,
      color:"#1e1f20"
    }
  })
// Customizable Area End
export default withRouter(
  withStyles(styles, { withTheme: true })(withConfirmBox(TermsAndCondition))
);
